import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useParams } from 'react-router-dom'

import InfoPiece from 'js/components/InfoPiece'
import Loader from 'js/components/Loader'

import { MAX_REQUEST_SIZE } from 'js/services/api'
import { listDeviceEntries } from 'js/services/deviceEntry'
import { listDeviceSummaries } from 'js/services/deviceSummary'
//prettier-ignore
import { Control, Device, DeviceEntry, DeviceSummary, Lot, LotDeviceValues, LotGroup } from 'js/models'
import { formatNumber, removeById } from 'js/utils/helpers'
import { ControlRunUrlParams } from 'js/utils/paths'

import DeviceEntryTable from './DeviceEntryTable'
import SummaryData from './SummaryData'
import { listLotDeviceValues } from 'js/services/lotDeviceValues'

interface DVTProps {
  lotGroup: LotGroup
  device: Device
  controls: Control[]
}

export default function DetailViewTabs(props: DVTProps): React.ReactElement {
  const { lotGroup, device, controls } = props
  const { month, year } = useParams<ControlRunUrlParams>()

  const serviceParams = {
    lot__group: lotGroup.id,
    device: device.id,
    month: parseInt(month),
    year: parseInt(year),
    count: MAX_REQUEST_SIZE,
  }

  const [deviceEntries, setDeviceEntries] = useState<DeviceEntry[]>()
  const [deviceSummaries, setDeviceSummaries] = useState<DeviceSummary[]>()
  const [expectedValuesList, setExpectedValuesList] =
    useState<LotDeviceValues[]>()

  useEffect(() => {
    listDeviceEntries({ ...serviceParams, order: 'datetime' }).then(r =>
      setDeviceEntries(r.results)
    )
    listDeviceSummaries(serviceParams).then(r => setDeviceSummaries(r.results))
    listLotDeviceValues({
      lot__in: lotGroup.lots.map(l => l.id).join(','),
      device: device.deviceType,
    }).then(r => setExpectedValuesList(r.results))
  }, [props])

  if (!deviceSummaries || !deviceEntries) return <Loader />

  const getControl = (lot: Lot): Control =>
    controls.find(control => control.id == lot.control) as Control

  const removeEntry = (id: number): void =>
    setDeviceEntries(removeById([...deviceEntries], id))

  const removeSummary = (id: number): void =>
    setDeviceSummaries(removeById([...deviceSummaries], id))

  const renderTab = (lot: Lot): React.ReactElement<Tab> => {
    const control = getControl(lot)
    const filteredEntries = deviceEntries.filter(e => e.lot == lot.id)
    const filteredSummaries = deviceSummaries.find(s => s.lot == lot.id)
    const hasEntryData = filteredEntries && filteredEntries.length > 0
    const hasSummaryData = Boolean(filteredSummaries)
    const expectedValue = expectedValuesList?.find(value => value.lot == lot.id)

    return (
      <Tab
        className='w-100'
        key={'dv-tab-lot-' + lot.id}
        eventKey={lot.id}
        title={`${control.level} - ${control.levelName}`}
      >
        <h3 className='d-none d-print-block'>{`${control.level} - ${control.levelName}`}</h3>
        <InfoPiece label='Lot Number'>{lot.lotNumber}</InfoPiece>
        {hasEntryData && hasSummaryData && (
          <Alert variant='info'>
            This lot has both individual data points and summary data for the
            same month. Individual data with supercede summary data in
            calculating device reports.
          </Alert>
        )}
        <Row>
          {hasEntryData && (
            <Col>
              <h5>Individual Data</h5>
              <DeviceEntryTable
                deviceEntries={filteredEntries}
                remove={removeEntry}
              />
            </Col>
          )}
          {hasSummaryData && (
            <Col>
              <h5>Summary Data</h5>
              <SummaryData
                deviceSummary={filteredSummaries}
                lotGroup={lotGroup}
                remove={removeSummary}
              />
            </Col>
          )}
          <Col>
            <h5>Expected Values Test</h5>
            {expectedValuesList ? (
              <Row>
                <Col>
                  <InfoPiece label='Expected Mean'>
                    {formatNumber(expectedValue?.expectedMean) || '&horbar;'}
                  </InfoPiece>
                  <InfoPiece label='Expected Standard Deviation'>
                    {formatNumber(expectedValue?.expectedStandardDeviation) ||
                      '&horbar;'}
                  </InfoPiece>
                </Col>
                <Col>
                  <InfoPiece label='Expected Low'>
                    {formatNumber(expectedValue?.expectedLow) || '&horbar;'}
                  </InfoPiece>
                  <InfoPiece label='Expected High'>
                    {formatNumber(expectedValue?.expectedHigh) || '&horbar;'}
                  </InfoPiece>
                </Col>
              </Row>
            ) : (
              <Loader />
            )}
          </Col>
        </Row>
      </Tab>
    )
  }

  return (
    <Tabs id='lot-group-levels-nav' className='mt-4'>
      {lotGroup.lots
        .sort((a, b) => getControl(a).level - getControl(b).level)
        .map(lot => renderTab(lot))}
    </Tabs>
  )
}
