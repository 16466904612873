import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import { useHistory } from 'react-router-dom'

import { buttonTextOptions } from '.'
import { Control, Device, Lot, LotGroup } from 'js/models'
import { FormErrorArray } from 'js/services/api'
// prettier-ignore
import { uploadBulkSummary, UploadDeviceSummaryParams } from 'js/services/deviceSummary'
import { WorkingTimeOptions } from 'js/utils/constants'
import { reduceFromErrorArray } from 'js/utils/helpers'
import Paths from 'js/utils/paths'

interface UploadSummaryFormData {
  lot: number
  monthYear: string
  count: number
  average: number
  standardDeviation: number
  month: string
  year: string
  device: number
  temperatureCorrected: boolean
  workingTime: WorkingTimeOptions
}

type DataFields = 'runs' | 'mean' | 'stdDev'
type DataForControl = Record<DataFields, number | null>

const enteredData: Map<number, DataForControl> = new Map()

export default function UploadSummary(props: {
  lotGroup: LotGroup
  controls: Control[]
  selectedDevice: Device
  selectedWorkingTime?: WorkingTimeOptions
  selectedTempCorrection?: boolean | undefined
}): React.ReactElement {
  const {
    lotGroup,
    controls,
    selectedDevice,
    selectedWorkingTime,
    selectedTempCorrection,
  } = props
  const [errorText, setErrorText] = useState('')
  const [month, setMonth] = useState<number | null>(null)
  const [year, setYear] = useState<number | null>(null)
  const [buttonText, setButtonText] = useState(buttonTextOptions.ready)
  const history = useHistory()

  const getLotByControlId = (controlId: number): Lot | null => {
    return lotGroup.lots.find(lot => lot.control === controlId) ?? null
  }

  useEffect(() => {
    enteredData.clear()
    if (month && year) {
      controls.forEach(control => {
        enteredData.set(control.id, { runs: null, mean: null, stdDev: null })
      })
    }
  }, [month, year])

  const upload = (e): void => {
    e.preventDefault()
    setErrorText('')
    setButtonText(buttonTextOptions.uploading)

    const formattedData: UploadDeviceSummaryParams[] = []

    // If a field is blank, submit the null anyway and get form error back from API response
    enteredData.forEach((data, controlId) => {
      if (data.mean || data.runs || data.stdDev)
        formattedData.push({
          month: month as number,
          year: year as number,
          average: data.mean as number,
          count: data.runs as number,
          standardDeviation: data.stdDev as number,
          temperatureCorrected: selectedTempCorrection ?? null,
          workingTime: selectedWorkingTime ?? null,
          lot: (getLotByControlId(controlId) as Lot).id,
          device: selectedDevice.id,
        })
    })

    uploadBulkSummary(formattedData)
      .then(() => {
        setButtonText(buttonTextOptions.success)
        setTimeout(() => {
          history.push(
            Paths.getControlRunDetailLink({
              year: year as number,
              month: month as number,
              deviceId: selectedDevice.id,
              lotGroupId: lotGroup.id,
            })
          )
        }, 1000)
      })
      .catch((e: FormErrorArray<UploadSummaryFormData>) => {
        const error = reduceFromErrorArray(e)
        setButtonText(buttonTextOptions.ready)
        if (error.nonFieldErrors) setErrorText(error.nonFieldErrors)
        else
          setErrorText(
            'Please enter complete information for each level you are submitting data for.'
          )
      })
  }

  const updateData = (
    controlId: number,
    newValue: string,
    field: DataFields
  ): void => {
    const temp = enteredData.get(controlId) as DataForControl
    temp[field] = newValue !== null ? parseFloat(newValue) : null
    enteredData.set(controlId, temp)
  }

  const renderTableRow = (control: Control): React.ReactElement => {
    return (
      <tr key={`${year}-${month}-${control.id}`}>
        <td>
          {control.level} - {control.levelName}
        </td>
        <td>
          <Form.Control
            type='number'
            onChange={(e): void =>
              updateData(control.id, e.target.value, 'runs')
            }
            placeholder='Runs'
            // isInvalid={errors}
          />
        </td>
        <td>
          <Form.Control
            type='number'
            onChange={(e): void =>
              updateData(control.id, e.target.value, 'mean')
            }
            placeholder='Average'
          />
        </td>
        <td>
          <Form.Control
            type='number'
            onChange={(e): void =>
              updateData(control.id, e.target.value, 'stdDev')
            }
            placeholder='Standard Deviation'
          />
        </td>
      </tr>
    )
  }

  return (
    <div className='w-75'>
      <h3>Summary Data</h3>
      <Alert variant='warning'>
        <Alert.Heading>Summary upload not recommended</Alert.Heading>
        <p>
          If you have record of individual measurements, select
          <i>
            <b> File Upload </b>
          </i>
          or
          <i>
            <b> Manual Entry</b>
          </i>
          .
        </p>
      </Alert>
      <Form.Group>
        <Form.Label>Month</Form.Label>
        <Form.Control
          type='month'
          onChange={(e): void => {
            setYear(parseInt(e.target.value.split('-')[0]))
            setMonth(parseInt(e.target.value.split('-')[1]))
          }}
        />
      </Form.Group>

      {year !== null && month !== null && (
        <>
          <Table striped className={'table-header-primary'}>
            <thead>
              <tr>
                <th>Level</th>
                <th>Number of Runs</th>
                <th>Mean Average</th>
                <th>Standard Deviation</th>
              </tr>
            </thead>
            <tbody>{controls.map(c => renderTableRow(c))}</tbody>
          </Table>

          <p className='text-danger'>{errorText}</p>
          <Button
            onClick={upload}
            disabled={buttonText !== buttonTextOptions.ready}
          >
            {buttonText}
          </Button>
        </>
      )}
    </div>
  )
}
