import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useHistory } from 'react-router-dom'

import { Site } from 'js/models'
import { deleteSite } from 'js/services/site'
import Paths from 'js/utils/paths'

export default function DeleteDeviceTypeModal(props: {
  site: Site
  show: boolean
  handleClose: () => void
}): React.ReactElement {
  const { site, show, handleClose } = props
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()

  const onClickDelete = (): void => {
    setErrorMessage('')
    deleteSite(site.id)
      .then(() => {
        handleClose()
        setTimeout(
          () =>
            history.push(
              Paths.getAdminOrganizationDetailLink(site.organization)
            ),
          50
        )
      })
      .catch(() => {
        setErrorMessage(
          'This site cannot be deleted. Usually this is because there is existing submitted data from a device at this site.'
        )
      })
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className='bg-danger'>
        <Modal.Title className='text-white'>Delete Site</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The site {site.name} will be immediately deleted from{' '}
          {site.organizationName}.
        </p>
        {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={(): void => {
            setErrorMessage('')
            handleClose()
          }}
        >
          Cancel
        </Button>
        <Button variant='outline-danger' onClick={onClickDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
