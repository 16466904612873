import React, { useContext } from 'react'

import NoOrganizationWarn from 'js/components/HelpMessages/NoOrganizationWarn'
import PendingOrganization from 'js/components/HelpMessages/PendingOrganization'
import ControlRunsTable from 'js/components/tables/ControlRunsTable'

import { ApprovedStatus } from 'js/utils/constants'
import { SelfUser } from 'js/utils/helpers'
import VQCPermissions from 'js/utils/permissions'

export default function ControlRuns(): React.ReactElement {
  const selfUser = useContext(SelfUser)
  const isElitechAdmin = VQCPermissions.isElitechAdmin(selfUser)
  const orgStatus = selfUser.organizationDetails?.approvedStatus

  if (!selfUser.organization && !isElitechAdmin) return <NoOrganizationWarn />

  if (orgStatus == ApprovedStatus.pending && !isElitechAdmin)
    return <PendingOrganization user={selfUser} status={orgStatus} />

  return (
    <div>
      <h1>Control Runs</h1>
      <ControlRunsTable showOrgFilter />
    </div>
  )
}
