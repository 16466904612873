import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { Link, useParams } from 'react-router-dom'

import FormattedAddress from 'js/components/FormattedAddress'
import DeleteSiteModal from 'js/components/forms/DeleteSiteModal'
import EditSiteModal from 'js/components/forms/EditSiteModal'
import InfoPiece from 'js/components/InfoPiece'
import Loader from 'js/components/Loader'

import { Site } from 'js/models'
import { getSite } from 'js/services/site'
import Paths from 'js/utils/paths'
import VQCPermissions from 'js/utils/permissions'

export default function AdminSiteDetail(): React.ReactElement {
  const { pk } = useParams<{ pk?: string }>()
  const [site, setSite] = useState<Site>()
  const [showSiteEdit, setShowSiteEdit] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const canEdit = VQCPermissions.canEditCustomerData()

  const handleShowSiteEdit = (): void => setShowSiteEdit(true)
  const handleCloseSiteEdit = (): void => setShowSiteEdit(false)

  const handleShowConfirmDelete = (): void => setShowConfirmDelete(true)
  const handleCloseConfirmDelete = (): void => setShowConfirmDelete(false)

  useEffect(() => {
    getSite(parseInt(pk as string)).then(r => setSite(r))
  }, [])

  if (!site) return <Loader />

  return (
    <>
      <h1>{site.name}</h1>
      <h2>Contact</h2>
      <InfoPiece label='Site Name'>{site.name}</InfoPiece>
      <InfoPiece label='Organization'>
        <Link to={Paths.getAdminOrganizationDetailLink(site.organization)}>
          {site.organizationName}
        </Link>
      </InfoPiece>
      <InfoPiece label='Address'>
        <FormattedAddress data={site} />
      </InfoPiece>
      <Button onClick={handleShowSiteEdit}>Edit</Button>

      {canEdit && (
        <>
          <EditSiteModal
            show={showSiteEdit}
            site={site}
            handleClose={handleCloseSiteEdit}
            setSite={setSite}
          />
          <Button
            className='ms-2'
            variant='danger'
            onClick={handleShowConfirmDelete}
          >
            Delete
          </Button>
        </>
      )}

      <DeleteSiteModal
        site={site}
        show={showConfirmDelete}
        handleClose={handleCloseConfirmDelete}
      />
    </>
  )
}
