import { Organization, OrganizationCode, User } from 'js/models'
import { ApprovedStatus } from 'js/utils/constants'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'
import api, { BaseQueryParams, FormPromise, Paginated } from './api'

export interface listOrganizationsParams extends BaseQueryParams {
  approvedStatus?: ApprovedStatus
}

/*=============================================
=                     GET                     =
=============================================*/
export const listOrganizations = (
  params?: listOrganizationsParams
): Promise<Paginated<Organization>> => {
  return api
    .get('/organizations/', { params: makeSnakeCase(params) })
    .then(r => makeCamelCase(r.data))
}

export const getOrganization = (id: number): Promise<Organization> => {
  return api.get(`/organizations/${id}/`).then(r => makeCamelCase(r.data))
}

export const getSelfOrganization = (): Promise<Organization> => {
  return api.get('/auth/user/').then(r => {
    const user: User = r.data
    if (user.organization === null) return Promise.reject(r)
    return api.get(`/organizations/${user.organization}/`).then(r => {
      return makeCamelCase(r.data)
    })
  })
}

export const retrieveOrganization = (id: number): Promise<Organization> => {
  return api
    .get('/organizations/', { params: { id: id } })
    .then(r => makeCamelCase(r.data))
}

export const retrieveByOrganizationCode = (
  code: string
): Promise<Organization> => {
  return api
    .get(`/organizations/by-organization-code/${code}`)
    .then(r => makeCamelCase(r.data))
}

/*=============================================
=                    POST                     =
=============================================*/
export const createOrganization = (
  data: Partial<Organization>
): Promise<Organization> => {
  return api
    .post('/organizations/', makeSnakeCase(data))
    .then(r => makeCamelCase(r.data))
}

export const resetOrganizationCode = (
  id: number
): Promise<{ organizationCode: OrganizationCode }> => {
  return api
    .post(`/organizations/${id}/reset-organization-code/`)
    .then(r => makeCamelCase(r.data))
}

/*=============================================
=                    PATCH                    =
=============================================*/
export const partialUpdateOrganization = (
  id: number,
  data: Partial<Organization>
): FormPromise<Organization> => {
  return api
    .patch(`/organizations/${id}/`, makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as Organization)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}
