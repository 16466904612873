import { LotDeviceValues } from 'js/models'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'
// prettier-ignore
import api, { BaseQueryParams, FormErrorArray, FormPromise, Paginated } from './api'

export interface listLotDeviceValuesParams extends BaseQueryParams {
  lot?: number
  lot__in?: string
  device?: number
  device__in?: string
}

/*=============================================
=                     GET                     =
=============================================*/
export const listLotDeviceValues = (
  params?: listLotDeviceValuesParams
): Promise<Paginated<LotDeviceValues>> => {
  return api
    .get('/lot-values/', { params: params })
    .then(r => makeCamelCase(r.data))
}

export const getLotDeviceValues = (id: number): Promise<LotDeviceValues> => {
  return api.get(`/lot-values/${id}/`).then(r => makeCamelCase(r.data))
}

/*=============================================
=                    POST                     =
=============================================*/
export const createLotDeviceValues = (
  data: Partial<LotDeviceValues>
): FormPromise<LotDeviceValues> => {
  return api
    .post('/lot-values/', makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as LotDeviceValues)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

export const bulkLotDeviceValues = (
  data: Partial<LotDeviceValues>[]
): Promise<LotDeviceValues[] | FormErrorArray<LotDeviceValues>> => {
  return api
    .post('/lot-values/bulk/', makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as LotDeviceValues[])
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                    PATCH                    =
=============================================*/

export const partialUpdateLotDeviceValues = (
  id: number,
  data: Partial<LotDeviceValues>
): FormPromise<LotDeviceValues> => {
  return api
    .patch(`/lot-values/${id}/`, makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as LotDeviceValues)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                     DELETE                  =
=============================================*/
export const deleteLotDeviceValues = (id: number): Promise<Response> => {
  return api.delete(`/lot-values/${id}/`)
}
