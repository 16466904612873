import { DashboardControlRun } from 'js/models'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'
import api, { BaseQueryParams } from './api'

export interface ListControlRunsParams extends BaseQueryParams {
  organization?: number
  startMonth?: number
  startYear?: number
  endMonth?: number
  endYear?: number
}

/*=============================================
  =                     GET                     =
  =============================================*/

export const listControlRuns = (
  params?: ListControlRunsParams
): Promise<DashboardControlRun[]> => {
  return api
    .get('/controls/dashboard/', { params: makeSnakeCase(params) })
    .then(r => makeCamelCase(r.data))
}
