import React from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import { CreatePassword, User } from 'js/models'
import { FormFeedback } from 'js/services/api'

export interface NewUserFormFields extends User, CreatePassword {}

interface NUFProps {
  onSubmit: (e?) => void
  onBack: () => void
  errors: FormFeedback<NewUserFormFields>
}

export default function NewUserForm(props: NUFProps): React.ReactElement {
  const errors = props.errors

  return (
    <Form onSubmit={props.onSubmit}>
      {/* Name */}
      <Form.Group as={Col} controlId='newUserForm_name'>
        <Form.Label className='visually-hidden'>Full Name</Form.Label>
        <Form.Control
          type='text'
          autoComplete='name'
          placeholder='Full Name'
          isInvalid={!!errors.name}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.name}
        </Form.Control.Feedback>
      </Form.Group>

      {/* Email */}
      <Form.Group controlId='newUserForm_email'>
        <Form.Label className='visually-hidden'>Email</Form.Label>
        <Form.Control
          type='email'
          autoComplete='username'
          placeholder='Email'
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>

      {/* Password */}
      <Form.Group controlId='newUserForm_password1'>
        <Form.Label className='visually-hidden'>Password</Form.Label>
        <Form.Control
          type='password'
          autoComplete='new-password'
          placeholder='Password'
          isInvalid={!!errors.password1}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.password1}
        </Form.Control.Feedback>
      </Form.Group>

      {/* Confirm Password */}
      <Form.Group controlId='newUserForm_password2'>
        <Form.Label className='visually-hidden'>Confirm Password</Form.Label>
        <Form.Control
          type='password'
          autoComplete='new-password'
          placeholder='Confirm Password'
          isInvalid={!!errors.password2}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.password2}
        </Form.Control.Feedback>
      </Form.Group>
      <p className='text-danger'>{errors.nonFieldErrors}</p>
      <p className='text-danger'>{errors.detail}</p>
      <div className='d-flex justify-content-around mt-4'>
        <Button variant='outline-primary' onClick={(): void => props.onBack()}>
          &larr;&nbsp;&nbsp;Back
        </Button>
        <Button variant='primary' type='submit'>
          Next&nbsp;&nbsp;&rarr;
        </Button>
      </div>
    </Form>
  )
}
