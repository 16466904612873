import React, { useEffect, useState } from 'react'
import kebabCase from 'lodash/kebabCase'
import Button from 'react-bootstrap/Button'
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer'
import { useParams } from 'react-router-dom'

import FormattedAddress from 'js/components/FormattedAddress'
import InfoPiece from 'js/components/InfoPiece'
import Loader from 'js/components/Loader'
import PrintButton from 'js/components/PrintButton'

import { Device, LotGroup, Site, Organization } from 'js/models'
import { getOrganization } from 'js/services/organization'
import { getSite } from 'js/services/site'
import { MONTH_NAMES } from 'js/utils/constants'
import { formatDate, formatTime } from 'js/utils/helpers'
import Paths, { ControlRunUrlParams } from 'js/utils/paths'
import VQCPermissions from 'js/utils/permissions'

import 'scss/control-run-details.scss'

export default function ControlRunDetailHeader(props: {
  device: Device
  lotGroup: LotGroup
  isReportView: boolean
}): React.ReactElement {
  const urlParams = useParams<ControlRunUrlParams>()
  const { isReportView, lotGroup } = props
  const {
    id: deviceId,
    site: siteId,
    organization: orgId,
    ...device
  } = props.device

  const [siteInfo, setSiteInfo] = useState({} as Site)
  const [orgInfo, setorgInfo] = useState({} as Organization)
  const [loading, setLoading] = useState(true)
  const viewCustomerData = VQCPermissions.canViewCustomerData()

  useEffect(() => {
    Promise.all([
      getSite(siteId).then(r => setSiteInfo(r)),
      getOrganization(orgId).then(r => setorgInfo(r)),
    ]).then(() => setLoading(false))
  }, [])

  const ViewToggleButton = (): React.ReactElement => {
    const linkGetter = isReportView
      ? Paths.getControlRunDetailLink
      : Paths.getControlRunDetailReportLink
    return (
      <LinkContainer to={linkGetter(urlParams)}>
        <Button variant='secondary' size='sm' active={false}>
          View {isReportView ? 'Details' : 'Report'}
        </Button>
      </LinkContainer>
    )
  }

  const BackButton = (): React.ReactElement => (
    <LinkContainer exact to={Paths.getControlRunsTableLink(urlParams)}>
      <Button variant='secondary' size='sm' active={false}>
        Back to Control Runs
      </Button>
    </LinkContainer>
  )

  const UploadButton = (): React.ReactElement => (
    <LinkContainer to={Paths.getSubmitDataLink(orgId, lotGroup.id, deviceId)}>
      <Button variant='secondary' size='sm'>
        Submit Data
      </Button>
    </LinkContainer>
  )

  if (loading) return <Loader />

  const now = new Date()
  const titleEl = (
    <h1 className='crd-header-title'>
      {MONTH_NAMES[urlParams.month]} {urlParams.year}{' '}
      <span className='nowrap'>
        {lotGroup.testName} - {device.label}
      </span>
    </h1>
  )

  const infoPieces = [
    { label: 'Model', children: device.model },
    { label: 'Label', children: device.label },
    { label: 'Serial Number', children: device.serialNumber },
    {
      label: 'Printed',
      className: 'd-none d-print-block',
      children: `${formatDate(now)} ${formatTime(now)}`,
    },
    { label: 'Organization', children: <FormattedAddress data={orgInfo} /> },
    { label: 'Site', children: <FormattedAddress data={siteInfo} /> },
    {
      label: 'Lot Numbers',
      children: lotGroup.lots.map(l => (
        <div key={'header-lot-id-' + l.id}>{l.lotNumber}</div>
      )),
    },
  ]

  return (
    <div className='crd-header'>
      <div className='d-print-none straddled-header mb-4'>
        <div className='d-grid gap-2'>
          <BackButton />
          <ViewToggleButton />
        </div>
        {titleEl}
        <div className='d-grid gap-2'>
          {lotGroup.active && <UploadButton />}
          <PrintButton variant='secondary' size='sm' />
        </div>
      </div>
      <div className='crd-header-box'>
        <div className='crd-header-box-content'>
          {titleEl}
          {infoPieces.map(ip => (
            <InfoPiece
              key={'ip-' + ip.label}
              style={{ gridArea: 'crd-hb-' + kebabCase(ip.label) }}
              {...ip}
            />
          ))}
        </div>
        {viewCustomerData && (
          <a
            className='small d-print-none'
            href={Paths.getAdminDeviceDetailLink(deviceId)}
          >
            View device registration&nbsp;&rarr;
          </a>
        )}
      </div>
    </div>
  )
}
