import { DeviceSummary } from 'js/models'
import { WorkingTimeOptions } from 'js/utils/constants'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'

import api, { FormErrorArray, FormPromise, Paginated } from './api'

export interface DeviceSummaryParams {
  lot__group: number | null
  device: number | null
  month: number | null
  year: number | null
}

export interface UploadDeviceSummaryParams {
  lot: number
  device: number
  month: number
  year: number
  count: number
  average: number
  standardDeviation: number
  temperatureCorrected?: boolean | null
  workingTime?: WorkingTimeOptions | null
}

/*=============================================
=                     GET                     =
=============================================*/
export const listDeviceSummaries = (
  params?: Partial<DeviceSummaryParams>
): Promise<Paginated<DeviceSummary>> => {
  return api
    .get('/entrysummaries/', { params: params })
    .then(r => makeCamelCase(r.data))
}

/*=============================================
=                    POST                     =
=============================================*/
export const uploadBulkSummary = (
  data: UploadDeviceSummaryParams[]
): Promise<void | FormErrorArray<UploadDeviceSummaryParams>> => {
  return api
    .post('/entrysummaries/bulk/', makeSnakeCase(data))
    .then(() => Promise.resolve())
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

export const createDeviceSummary = (
  data: Partial<DeviceSummary>
): FormPromise<DeviceSummary> => {
  return api
    .post('/entrysummaries/', makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as DeviceSummary)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                    DELETE                   =
=============================================*/
export const deleteSummary = (id: number): Promise<Response | unknown> => {
  return api
    .delete(`/entrysummaries/${id}/`)
    .then(r => makeCamelCase(r.data))
    .catch(e => Promise.reject(e))
}
