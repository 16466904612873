import React from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'

import { ApprovedStatus } from 'js/utils/constants'

export const approveDenyAlert = (
  show: boolean,
  setOrgStatus: (status: ApprovedStatus) => void
): React.ReactElement => {
  return (
    <Alert show={show} variant='warning'>
      <Alert.Heading>Organization Pending Approval</Alert.Heading>
      <p>
        Approving this organization will give its users access to submit data to
        lots. While awaiting approval, this organization may still add new
        devices and users.
      </p>
      <hr />
      <div className='d-flex justify-content-end'>
        <Button
          variant='outline-danger'
          className='me-2'
          onClick={(): void => setOrgStatus(ApprovedStatus.denied)}
        >
          Deny
        </Button>
        <Button
          variant='warning'
          onClick={(): void => setOrgStatus(ApprovedStatus.approved)}
        >
          Approve
        </Button>
      </div>
    </Alert>
  )
}
