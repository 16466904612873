import React from 'react'

export default function HelpButton(props: {
  href: string
}): React.ReactElement {
  return (
    <a href={props.href} className='help-button'>
      ?
    </a>
  )
}
