import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import { Link } from 'react-router-dom'

import { HelpKeys } from 'js/containers/Help/helpContent'

import { PasswordReset } from 'js/models'
import { FormFeedback } from 'js/services/api'
import { resetPassword } from 'js/services/user'
import Paths from 'js/utils/unauthorizedPaths'

import Loader from '../Loader'

enum FormState {
  unsubmitted = 0,
  waiting = 1,
  submitted = 2,
  noReceive = 3,
}

export default function PasswordResetForm(): React.ReactElement {
  const [errors, setErrors] = useState({} as FormFeedback<PasswordReset>)
  const [email, setEmail] = useState()
  const [formState, setFormState] = useState<FormState>(FormState.unsubmitted)

  const resetFormFeedback = (): void =>
    setErrors({} as FormFeedback<PasswordReset>)

  const sendReset = (e): void => {
    e.preventDefault()
    resetFormFeedback()

    if (!email) setEmail(e.target.elements.passwordReset_email.value)

    resetPassword({
      email: e.target.elements?.passwordReset_email.value || email,
    })
      .then(() => {
        setFormState(FormState.waiting)
        // Show the loader for a second so the user feels like something is happening
        setTimeout(() => setFormState(FormState.submitted), 1000)
      })
      .catch(e => setErrors(e.formErrors))
  }

  return (
    <Form onSubmit={sendReset}>
      <Card className='unauth-card'>
        <Card.Header>Reset Password</Card.Header>
        <Card.Body>
          {formState == FormState.waiting && <Loader />}
          {formState == FormState.unsubmitted && (
            <>
              <p>
                We&apos;ll send a password reset link to the email address you
                use to sign in.
              </p>
              <Form.Group controlId='passwordReset_email'>
                <Form.Label className='visually-hidden'>Email</Form.Label>
                <Form.Control
                  type='email'
                  autoComplete='username'
                  placeholder='Email'
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Button className='w-100' variant='primary' type='submit'>
                Send Email
              </Button>
            </>
          )}
          {formState == FormState.submitted && (
            <>
              <p>
                Thanks! We&apos;ll email you a link to reset your password if an
                account exists for {email}.
              </p>
              <p>
                <Button
                  variant='link'
                  className='p-0'
                  onClick={(): void => setFormState(FormState.noReceive)}
                >
                  Didn&apos;t receive an email?
                </Button>
              </p>
            </>
          )}
          {formState == FormState.noReceive && (
            <>
              <p>
                Check your junk folder, and verify you entered your email
                correctly:
              </p>
              <p className='text-center'>
                <b>{email}</b>
              </p>
              <p>
                If you still aren&apos;t receiving an email, then we probably
                don&apos;t have an account with that address. Contact an admin
                to verify the email address you use with VQC.
              </p>
              <p>
                <Button
                  variant='link'
                  className='p-0 d-block'
                  onClick={sendReset}
                >
                  Send again
                </Button>
                <Button
                  variant='link'
                  className='p-0 d-block'
                  onClick={(): void => {
                    setEmail(undefined)
                    setFormState(FormState.unsubmitted)
                  }}
                >
                  Enter a different email
                </Button>
              </p>
            </>
          )}
          <hr className='mt-4' />
          <div className='d-flex justify-content-around'>
            <Link to={Paths.bareRegistrationLink}>Create Account</Link>
            <Link to={Paths.signIn}>Sign In</Link>
            <Link to={Paths.getHelpSectionLink(HelpKeys.contact)}>Help</Link>
          </div>
        </Card.Body>
      </Card>
    </Form>
  )
}
