import axios from 'axios'
import { ELITechEmail, ELITechPhone } from 'js/utils/constants'
import { signOut } from 'js/utils/helpers'

import Config from '../config'

export const MAX_REQUEST_SIZE = 500

// query paramaters common to all serializers
export interface BaseQueryParams {
  ids?: string // use .join(',') to get string from array of ids
  page?: number
  count?: number
  order?: string
  search?: string
}

export interface Paginated<T> {
  currentPage: number
  itemTotal: number
  pageTotal: number
  results: T[]
}

export type genericFormErrorKeys = 'nonFieldErrors' | 'detail'
export type FormFeedback<T> = Record<keyof T | genericFormErrorKeys, string>
export interface FormError<T> extends Error {
  formErrors: FormFeedback<T>
}
export interface FormErrorArray<T> extends Error {
  formErrors: Array<FormFeedback<T>>
}
export type FormPromise<T> = Promise<T | FormError<T>>

const api = axios.create({
  baseURL: Config.apiUrl,
})

api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (is500(error.response))
      alert(
        `An unknown server error occurred. Try again later, or contact ELITech support if the problem persists. \n\nEmail: ${ELITechEmail}\nPhone: ${ELITechPhone}`
      )
    else if (error.response.status === 401) signOut()
    return Promise.reject(error)
  }
)

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
})

const is500 = (r: Response): boolean => r.status.toString().charAt(0) === '5'

export default api
