import React from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import PrintButton from 'js/components/PrintButton'

export default function CorrectiveActionForm(): React.ReactElement {
  return (
    <div className='mw-50'>
      <h1>Corrective Action Form</h1>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Lab Name</Form.Label>
            <Form.Control type='text' />
          </Form.Group>
          <Form.Group>
            <Form.Label>Month / Year</Form.Label>
            <Form.Control type='text' />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Lot Number Level 1</Form.Label>
            <Form.Control type='text' />
          </Form.Group>
          <Form.Group>
            <Form.Label>Lot Number Level 2</Form.Label>
            <Form.Control type='text' />
          </Form.Group>
          <Form.Group>
            <Form.Label>Lot Number Level 3</Form.Label>
            <Form.Control type='text' />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className='mt-5'>
        <Form.Label>Corrective Action</Form.Label>
        <Form.Control as='textarea' rows={5} />
      </Form.Group>

      <Row className='mt-5'>
        <Col>
          <Form.Group>
            <Form.Label>Reviewed By</Form.Label>
            <Form.Control type='text' />
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group>
            <Form.Label>Date</Form.Label>
            <Form.Control type='date' />
          </Form.Group>
        </Col>
      </Row>

      <PrintButton />
    </div>
  )
}
