import React from 'react'
import Card from 'react-bootstrap/Card'

import NewOrganizationForm from 'js/components/forms/NewOrganizationForm'

import { Organization } from 'js/models'
import { getDataFromForm } from 'js/utils/helpers'

import { StepContentProps, Steps } from '.'

interface COProps extends StepContentProps {
  setData: (x: Partial<Organization>) => void
}
interface FormData {
  name: string
  streetAddress: string
  addressLine2: string
  city: string
  state: string
  zip: string
  country: string
  phoneNumber: string
}

export default function CreateOrganization(props: COProps): React.ReactElement {
  const onSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    const formData: FormData = getDataFromForm(
      e.target,
      [
        'name',
        'streetAddress',
        'addressLine2',
        'city',
        'state',
        'zip',
        'country',
        'phoneNumber',
      ],
      'newOrganizationForm_'
    )
    const international = (
      document.querySelector(
        '#newOrganizationForm_international'
      ) as HTMLInputElement
    ).checked
    props.setData({
      name: formData.name,
      phoneNumber: formData.phoneNumber,
      addressLine1: formData.streetAddress,
      addressLine2: international
        ? formData.addressLine2
        : `${formData.city}, ${formData.state} ${formData.zip}`,
      addressLine3: formData.country,
    } as Partial<Organization>)
    props.setStep(Steps.CreateUser)
  }

  return (
    <Card className='unauth-card wide'>
      <Card.Header>Organization Information</Card.Header>
      <Card.Body>
        <NewOrganizationForm
          onSubmit={onSubmit}
          onBack={(): void => props.setStep(Steps.Start)}
        />
      </Card.Body>
    </Card>
  )
}
