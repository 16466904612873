import React from 'react'
import HelpButton from './HelpButton'

interface InfoPieceProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string
  helpLink?: string
}

export default function InfoPiece(props: InfoPieceProps): React.ReactElement {
  const { label, helpLink, children, className, ...rest } = props
  return (
    <div className={'info-piece ' + (className || '')} {...rest}>
      <div className='label'>
        {label}
        {helpLink && <HelpButton href={helpLink} />}
      </div>
      <div className='content'>{children}</div>
    </div>
  )
}
