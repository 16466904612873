import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

import { DeviceType } from 'js/models'
import { listDeviceTypes, listDeviceTypesParams } from 'js/services/deviceType'
import VQCPermissions from 'js/utils/permissions'

import CreateDeviceTypeModal from '../forms/CreateDeviceTypeModal'
import DeleteDeviceTypeModal from '../forms/DeleteDeviceTypeModal'
import EditDeviceTypeModal from '../forms/EditDeviceTypeModal'
import Loader from '../Loader'
import PaginationBar from '../PaginationBar'
import SearchBar from '../SearchBar'

export default function AdminDeviceTypeTable(props: {
  listParams?: listDeviceTypesParams
  order?: string
}): React.ReactElement {
  const { listParams, order } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>()
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [selectedDeviceType, setSelectedDeviceType] = useState(0)
  const [searchString, setSearchString] = useState('')
  const [showNewDeviceType, setShowNewDeviceType] = useState(false)

  const canEdit = VQCPermissions.canEditElitechData()

  const handleShowNewDeviceType = (): void => setShowNewDeviceType(true)
  const handleCloseNewDeviceType = (): void => setShowNewDeviceType(false)

  useEffect(() => {
    refreshDeviceTypes()
  }, [currentPage, listParams, order, searchString])

  const refreshDeviceTypes = (): void => {
    listDeviceTypes({
      ...listParams,
      page: currentPage,
      order: order || 'name',
      search: searchString,
    }).then(r => {
      setDeviceTypes(r.results)
      setTotalPages(r.pageTotal)
    })
  }

  const search = (s: string): void => {
    setCurrentPage(1)
    setSearchString(s)
  }

  const handleShowEdit = (id: number): void => {
    setSelectedDeviceType(id)
    setShowEdit(true)
  }
  const handleCloseEdit = (): void => setShowEdit(false)

  const handleShowDelete = (): void => {
    setShowDelete(true)
  }
  const handleCloseDelete = (): void => setShowDelete(false)

  const renderTableRow = (
    deviceType: DeviceType,
    idx: number
  ): React.ReactElement => {
    return (
      <tr
        key={idx}
        className={canEdit ? 'clickable' : ''}
        onClick={(): void => {
          canEdit && handleShowEdit(deviceType.id)
        }}
      >
        <td>{deviceType.name}</td>
      </tr>
    )
  }

  if (!deviceTypes) return <Loader />

  return (
    <>
      <div className='table-top-bar'>
        <div className='table-top-section'>
          <SearchBar
            label={'Search'}
            placeholder={'Search by model name'}
            doSearch={search}
          />
        </div>
        <div className='table-top-section'>
          {canEdit && (
            <Button onClick={handleShowNewDeviceType}>Add New Model</Button>
          )}
        </div>
      </div>
      {deviceTypes.length == 0 ? (
        <p>No models to display</p>
      ) : (
        <>
          <Table striped hover={canEdit} className='table-header-primary'>
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {deviceTypes.map((deviceType, idx) =>
                renderTableRow(deviceType, idx)
              )}
            </tbody>
          </Table>
          <PaginationBar
            pageTotal={totalPages}
            currentPage={currentPage}
            clickPage={setCurrentPage}
          />
        </>
      )}

      <EditDeviceTypeModal
        deviceTypeId={selectedDeviceType}
        show={showEdit}
        handleClose={handleCloseEdit}
        handleShowDelete={handleShowDelete}
        refreshDeviceTypes={refreshDeviceTypes}
      />

      <DeleteDeviceTypeModal
        deviceTypeId={selectedDeviceType}
        show={showDelete}
        handleClose={handleCloseDelete}
        refreshDeviceTypes={refreshDeviceTypes}
      />

      <CreateDeviceTypeModal
        show={showNewDeviceType}
        handleClose={handleCloseNewDeviceType}
        refreshDeviceTypes={refreshDeviceTypes}
      />
    </>
  )
}
