import React from 'react'

import Loader from 'js/components/Loader'

import { DetailedDeviceData } from 'js/services/report-models'
import { findByLevelNumber } from 'js/utils/helpers'
// prettier-ignore
import { getStrFromTableConfig, SRTProps, TableRowDataset, TABLE_CONFIG } from './statistical-report-table-utils'

export default function StatisticalReportTable(
  props: SRTProps
): React.ReactElement {
  if (!props.device || !props.detailedDevices) return <Loader />

  const getTableRowDataset = (dd: DetailedDeviceData): TableRowDataset => ({
    device: dd,
    group: findByLevelNumber(props.detailedGroups, dd.level),
    deviceCum: findByLevelNumber(props.detailedDevicesCums, dd.level),
    groupCum: findByLevelNumber(props.detailedGroupsCums, dd.level),
  })

  const renderTableRow = (dataset: TableRowDataset): React.ReactElement => (
    <tr key={`${dataset.device.lotNumber}-tr`}>
      {TABLE_CONFIG.month.map(tc => (
        <td key={`${dataset.device.lotNumber}-${tc.label}-td`}>
          {getStrFromTableConfig(tc, dataset)}
        </td>
      ))}
      {TABLE_CONFIG.cumulative.map(tc => (
        <td
          key={`${dataset.device.lotNumber}-${tc.label}-cum-td`}
          className='d-print-none'
        >
          {getStrFromTableConfig(tc, dataset)}
        </td>
      ))}
    </tr>
  )

  const renderPrintOnlyTableRow = (
    dataset: TableRowDataset
  ): React.ReactElement => (
    <tr
      key={`${dataset.device.lotNumber}-tr-print`}
      className='d-none d-print-table-row'
    >
      {TABLE_CONFIG.month.slice(0, 4).map(tc => (
        <td key={`${dataset.device.lotNumber}-${tc.label}-td-print`}>
          {getStrFromTableConfig(tc, dataset)}
        </td>
      ))}
      {TABLE_CONFIG.cumulative.map(tc => (
        <td key={`${dataset.device.lotNumber}-${tc.label}-cum-td-print`}>
          {getStrFromTableConfig(tc, dataset)}
        </td>
      ))}
    </tr>
  )

  return (
    <div className='report-table-wrapper table-responsive'>
      <table className='table table-striped table-sm small'>
        <colgroup span={TABLE_CONFIG.month.length}></colgroup>
        <colgroup span={TABLE_CONFIG.cumulative.length}></colgroup>
        <thead>
          <tr>
            <th className='h3' colSpan={TABLE_CONFIG.month.length}>
              {props.device.label} - {props.device.serialNumber}
            </th>
            <th
              colSpan={TABLE_CONFIG.cumulative.length}
              className='border border-primary border-bottom-0 d-print-none'
            >
              Cumulative Results
            </th>
          </tr>
          <tr>
            {TABLE_CONFIG.month.map(({ label }) => (
              <th key={`${label}-th`}>{label}</th>
            ))}
            {TABLE_CONFIG.cumulative.map(({ label }) => (
              <th key={`${label}-cum-th`} className='d-print-none'>
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.detailedDevices.map(dd =>
            renderTableRow(getTableRowDataset(dd))
          )}
          <tr className='d-none d-print-table-row'></tr>
          <tr className='d-none d-print-table-row bg-primary text-light fw-bold'>
            <td colSpan={TABLE_CONFIG.month.length}>Cummulative Results</td>
          </tr>
          {props.detailedDevices.map(dd =>
            renderPrintOnlyTableRow(getTableRowDataset(dd))
          )}
        </tbody>
      </table>
    </div>
  )
}
