import React from 'react'
import Alert from 'react-bootstrap/Alert'

import FormattedAddress from 'js/components/FormattedAddress'
import ForamttedEmail from 'js/components/FormattedEmail'
import FormattedPhone from 'js/components/FormattedPhone'
import InfoPiece from 'js/components/InfoPiece'

import { ELITechAddress, ELITechEmail, ELITechPhone } from 'js/utils/constants'

export interface HelpSection {
  title: string
  key: HelpKeys
  content: React.ReactElement
}

// --------- IMPORTANT ---------
// The order of the sections on the help page is based on the order of the keys in this enum!!
export enum HelpKeys {
  contact = 'contact',
  aboutVQC = 'about-vqc',
  newOrganization = 'new-organization',
  newUser = 'new-user',
  joinCode = 'join-code',
  removeUser = 'remove-user',
  organizationAdmins = 'org-admins',
  manageSites = 'manage-sites',
  manageDevices = 'manage-devices',
  submitData = 'submit-data',
  passwords = 'passwords',
}

const anchor = (section: HelpKeys): string => {
  return `#${section}`
}

export const helpSectionList: HelpSection[] = [
  {
    title: 'Contact Us',
    key: HelpKeys.contact,
    content: (
      <>
        <p>
          If you need additional support with VQC, let us know! Our office is
          open weekdays 8AM-5PM Mountain Time.
        </p>
        <InfoPiece label='Email'>
          <ForamttedEmail email={ELITechEmail} />
        </InfoPiece>
        <InfoPiece label='Phone'>
          <FormattedPhone phone={ELITechPhone} clickable />
        </InfoPiece>
        <InfoPiece label='Mailing Address'>
          <FormattedAddress data={ELITechAddress} />
        </InfoPiece>
      </>
    ),
  },
  {
    title: 'About ELITech VQC',
    key: HelpKeys.aboutVQC,
    content: (
      <>
        <p>
          ELITechGroup&apos;s VQC program provides customers with ELITech
          analyzers an opportunity to participate in an online, inter-laboratory
          comparison of QC data. Although not required, regulatory and
          accreditation organizations highly recommend participation in an
          inter-laboratory program as good laboratory practice. It is an
          effective way to evaluate the performance of a laboratory&apos;s
          instrument or method against a peer group and demonstrate compliance
          with quality practices.
        </p>
        <p>
          Ready to get started?{' '}
          <a href={anchor(HelpKeys.newOrganization)}>
            Register your organization
          </a>{' '}
          or <a href={anchor(HelpKeys.contact)}>contact us</a> for additional
          information about this program.
        </p>
      </>
    ),
  },
  {
    title: 'Organization Registration',
    key: HelpKeys.newOrganization,
    content: (
      <>
        <p>
          The user who will act as the organization&apos;s admin should register
          the organization with ELITech VQC. If your organization participated
          in the original VQC program, you&apos;ll need to register again to use
          this newer version of VQC.
        </p>
        <p>Follow these instructions to get started:</p>
        <ol>
          <li>
            Click <i>Register</i> in the menu bar at the top of the page
          </li>
          <li>
            Select <i>No</i> when asked if your organization already has an
            account
          </li>
          <li>Enter your organization&apos;s contact information</li>
          <li>
            Enter your own personal information. This will be used to create
            your admin account to manage the organziation.
          </li>
        </ol>
        <p>
          Your admin account and organization have been created! You can use the
          organization code shown to{' '}
          <a href={anchor(HelpKeys.newUser)}>register additional users</a>, and
          you can always{' '}
          <a href={anchor(HelpKeys.joinCode)}>access this code</a> later through
          the <i>Users</i> tab in <i>Organization Settings</i>.
        </p>
        <p>
          If you have any difficulty registering,{' '}
          <a href={anchor(HelpKeys.contact)}>contact us</a> for additional
          support.
        </p>
      </>
    ),
  },
  {
    title: 'User Registration',
    key: HelpKeys.newUser,
    content: (
      <>
        <Alert variant='info'>
          Before registering, you&apos;ll need to obtain an organization join
          code from one of your organization&apos;s admins. Admins can{' '}
          <a href={anchor(HelpKeys.joinCode)}>obtain a join code</a> through
          organization settings.
        </Alert>
        <p>
          If your organization is not already registered with VQC, follow the
          instructions to{' '}
          <a href={anchor(HelpKeys.newOrganization)}>
            register your organization
          </a>{' '}
          before adding additional users.
        </p>
        <p>
          After obtaining your organization join code, follow these instructions
          to register a new user account:
        </p>
        <ol>
          <li>
            Click <i>Register</i> in the menu bar at the top of the page
          </li>
          <li>
            Select <i>Yes</i> when asked if your organization already has an
            account
          </li>
          <li>
            Enter your organization join code, then confirm the organization is
            correct.
          </li>
          <li>Enter your personal information to create an account login</li>
        </ol>
      </>
    ),
  },
  {
    title: 'Organization Join Code',
    key: HelpKeys.joinCode,
    content: (
      <>
        <Alert variant='warning'>
          Anyone with your join code will be able to create an account and
          submit data for your organization. Keep this code private, and reset
          it regularly if needed.
        </Alert>
        <p>
          Only organization admins can manage the join code. New users will need
          to use your organization join code to{' '}
          <a href={anchor(HelpKeys.newUser)}>create an account</a>. You can
          access your join code by clicking on <i>Organization Settings</i> in
          the menu bar, then navigating to the <i>Users</i> tab. The join code
          is listed underneath your list of registered users.
        </p>
        <p>
          To prevent unauthorized users from joining your organization, click
          the <i>Reset Code</i> button to generate a new code. After generating
          a new code, the old one is invalidated and can no longer be used to
          join your organization.
        </p>
      </>
    ),
  },
  {
    title: 'Remove User',
    key: HelpKeys.removeUser,
    content: (
      <>
        <Alert variant='warning'>
          After removing a user, you may want to{' '}
          <a href={anchor(HelpKeys.joinCode)}>
            reset your organization join code
          </a>{' '}
          so they cannot create a new account with the existing code.
        </Alert>
        <p>
          Only organization admins have the ability to remove users. To remove a
          user from your organization:
        </p>
        <ol>
          <li>
            Click on <i>Organization Settings</i> in the menu bar, then navigate
            to the <i>Users</i> tab
          </li>
          <li>Select a user to view their information</li>
          <li>
            Click <i>Remove from Organization</i>
          </li>
        </ol>
        <p>
          Once a user has been removed from your organization, they will no
          longer have access to view or submit data. If you wish to re-add them
          in the future, you&apos;ll need to{' '}
          <a href={anchor(HelpKeys.contact)}>contact ELITech VQC support</a>.
        </p>
      </>
    ),
  },
  {
    title: 'Manage Organization Admins',
    key: HelpKeys.organizationAdmins,
    content: (
      <>
        <p>
          Organization admins have access to perform the following actions that
          standard users cannot:
        </p>
        <ul>
          <li>Add and remove other admins</li>
          <li>Remove users from organization</li>
          <li>Reset organization join code</li>
          <li>Edit organization contact information</li>
          <li>Manage organization sites</li>
          <li>Manage organization devices</li>
        </ul>
        <p>
          By default, the user who registered the organization is automatically
          given admin permissions. To manage which users have admin permisisons:
        </p>
        <ol>
          <li>
            Click on <i>Organization Settings</i> in the menu bar, then navigate
            to the <i>Users</i> tab
          </li>
          <li>
            Select a user to view their information. Users with admin
            permissions will have an <i>Admin</i> tag next to their name.
          </li>
          <li>
            Click <i>Edit</i>, make changes to their permissions, then{' '}
            <i>Save Changes</i>
          </li>
        </ol>
      </>
    ),
  },
  {
    title: 'Submit Data',
    key: HelpKeys.submitData,
    content: (
      <>
        <p>
          Users can submit data for any of the instruments at sites they belong
          to. Organization admins can submit data for any instruments in their
          organization.
        </p>
        <p>To select a lot for submitting data:</p>
        <ol>
          <li>
            Go to <i>Control Runs</i> in the navigation bar, or go to the home
            page
          </li>
          <li>
            Click the button, <i>Enter New Data</i>. If your organization is
            awaiting approval, additional instructions will be shown instead.
          </li>
          <li>
            Select a lot to submit data to. Lot numbers are shown in the
            drop-down.
          </li>
          <li>Select the device to submit data from</li>
        </ol>
        <p>
          Choose one of three options for entering and uploading data: Manual
          Entry, File Upload, or Summary Data.
        </p>
        <h3>Manual Entry</h3>
        <p>
          For manual entry, you will manually type in each value. First select
          the month for which you are reporting, then enter the corresponding
          values for each date and level.
        </p>
        <p>
          You can leave fields blank that don&apos;t have data. If you have
          multiple values for each day, this form can be submitted as many times
          as needed.
        </p>
        <h3>File Upload</h3>
        <p>
          Follow the on-page instructions for submitting a CSV or Excel file of
          your data, taking care to submit in the correct format. A CSV template
          is available for download in the correct format.
        </p>
        <h3>Summary Data (Not Recommended)</h3>
        <p>
          Uploading only summary data is not recommended, because it produces
          less effective results when comparing data between labs, as outliers
          may be excluded.
        </p>
      </>
    ),
  },
  {
    title: 'Manage Sites',
    key: HelpKeys.manageSites,
    content: (
      <>
        <p>
          Sites are separate physical locations within your organization: either
          different buildings, or separate labs within the same building. Each
          device can only be assigned to one site, while users can belong to
          multiple sites. Users can only view and submit data for devices at
          sites they belong to. Organization admins have access to devices at
          all sites in their organization.
        </p>
        <p>
          Organization admins can add new sites from the <i>Sites</i> tab on the
          organization settings page.
        </p>
      </>
    ),
  },
  {
    title: 'Manage Devices',
    key: HelpKeys.manageDevices,
    content: (
      <>
        <p>
          Before submitting data from a device, the device must be registered in
          VQC. Organization admins can register devices from the <i>Devices</i>{' '}
          tab on the organization settings page. When registering a device,
          enter a unique label to help recognize it later when submitting data.
          (e.g. Excyte mini or Excyte 20)
        </p>
        <p>
          Devices must be registered to a specific site, and users can only
          submit and view data for devices regitered to sites they belong to.
        </p>
        <p>
          If a device is no longer at a site, organization admins can deactivate
          it from organization settings. To move a device to a different site,
          first deactivate it, then re-register it at the new site.
        </p>
      </>
    ),
  },
  {
    title: 'Account Passwords',
    key: HelpKeys.passwords,
    content: (
      <>
        <h3>Changing Current Password</h3>
        <p>
          To change your current password, sign in, then click on <i>Profile</i>{' '}
          in the navigation bar.
        </p>
        <h3>Resetting Lost/Forgotten Password</h3>
        <p>
          If you cannot sign in, click the <i>Reset Password</i> link on the
          sign in screen, then follow the instructions to send yourself a
          password reset link. If you do not have access to your email, contact
          your organization admin or{' '}
          <a href={anchor(HelpKeys.contact)}>ELITech VQC support</a>.
        </p>
        <p>
          Organization admins can reset passwords for users in their
          organization by going to the <i>Users</i> tab in organization
          settings. Click on a user to view their details, then use the{' '}
          <i>Change Password</i> form to set a new password.
        </p>
      </>
    ),
  },
]
