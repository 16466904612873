import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer'
import { useParams } from 'react-router-dom'

import { DashboardControlRun, Organization } from 'js/models'
import { MAX_REQUEST_SIZE } from 'js/services/api'
import { listControlRuns, ListControlRunsParams } from 'js/services/controlRuns'
import { listOrganizations } from 'js/services/organization'
import { MONTH_NAMES } from 'js/utils/constants'
import { formatDate, SelfUser } from 'js/utils/helpers'
import Paths from 'js/utils/paths'
import VQCPermissions from 'js/utils/permissions'

import Loader from '../Loader'

export default function ControlRunsTable(props: {
  listParams?: ListControlRunsParams
  showOrgFilter?: boolean
}): React.ReactElement {
  const { listParams, showOrgFilter } = props
  const params = useParams<{ year?: string; month?: string }>()
  const selfUser = useContext(SelfUser)
  const [runs, setRuns] = useState<DashboardControlRun[]>()
  const [organizationList, setOrganizationList] = useState<Organization[]>([])
  const [orgId, setOrgId] = useState<number | null>(
    listParams?.organization || (selfUser.organization ?? null)
  )
  const [month, setMonth] = useState<number>()
  const [year, setYear] = useState<number>()
  const [awaitingResponse, setAwaitingResponse] = useState(true)

  const isElitechAdmin = VQCPermissions.isElitechAdmin(selfUser)
  const canSubmitData =
    VQCPermissions.canEditCustomerData(selfUser) ||
    (selfUser.organization && orgId && selfUser.organization == orgId)

  useEffect(() => {
    listOrganizations({ count: MAX_REQUEST_SIZE }).then(r =>
      setOrganizationList(r.results)
    )
    setMonth(params.month ? parseInt(params.month) : new Date().getMonth() + 1)
    setYear(params.year ? parseInt(params.year) : new Date().getFullYear())
  }, [])

  useEffect(() => {
    setAwaitingResponse(true)
    if (month && year) {
      listControlRuns({
        ...listParams,
        ...(showOrgFilter && orgId && { organization: orgId }),
        startMonth: month,
        startYear: year,
      })
        .then(r => {
          setRuns(r)
        })
        .catch(() => setRuns([]))
        .finally(() => setAwaitingResponse(false))
    }
  }, [month, year, orgId])

  if (!(runs && month && year)) return <Loader />

  const renderTableRow = (
    controlRun: DashboardControlRun,
    i: number
  ): React.ReactElement => {
    const rowLink = Paths.getControlRunDetailLink({
      year: controlRun.datetimeYear,
      month: controlRun.datetimeMonth,
      deviceId: controlRun.device,
      lotGroupId: controlRun.lotGroup,
    })
    return (
      <LinkContainer to={rowLink} key={'control-run-table-row-' + i}>
        <tr className='clickable'>
          <td>
            {MONTH_NAMES[controlRun.datetimeMonth]} {controlRun.datetimeYear}
          </td>
          <td>{controlRun.test}</td>
          <td>
            {controlRun.label && controlRun.serialNumber
              ? `${controlRun.label} (${controlRun.serialNumber})`
              : '–'}
          </td>
          <td>{controlRun.lots.join(', ')}</td>
          <td>{controlRun.count}</td>
          <td>{formatDate(controlRun.lastEdit)}</td>
        </tr>
      </LinkContainer>
    )
  }

  return (
    <>
      <div className='table-top-bar'>
        <div className='table-top-section'>
          <Form.Group>
            <Form.Label>Month</Form.Label>
            <Form.Control
              type='month' min='2023-06' max='2026-12' 
              onChange={(e): void => {
                if (e.target.value) {
                  setYear(parseInt(e.target.value.split('-')[0]))
                  setMonth(parseInt(e.target.value.split('-')[1]))
                }
              }}
              defaultValue={`${year}-${month.toString().padStart(2, '0')}`}
            />
          </Form.Group>
          {isElitechAdmin && showOrgFilter && (
            <Form.Group className='ps-3'>
              <Form.Label>Organization</Form.Label>
              <Form.Control
                as='select'
                defaultValue={orgId ?? 'default'}
                onChange={(e): void => setOrgId(parseInt(e.target.value))}
              >
                <option disabled value='default'>
                  {' -- Select an Organization -- '}
                </option>
                {organizationList.map(org => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
        </div>
        <div className='table-top-section'>
          {canSubmitData && (
            <LinkContainer
              to={orgId ? Paths.getSubmitDataLink(orgId) : Paths.submitData}
            >
              <Button variant='primary'>Enter New Data</Button>
            </LinkContainer>
          )}
        </div>
      </div>
      {awaitingResponse ? (
        <Loader />
      ) : runs.length == 0 ? (
        <p className='m-0'>No control runs for the selected month</p>
      ) : (
        <Table striped hover className='table-header-primary'>
          <thead>
            <tr>
              <th>Month</th>
              <th>Test</th>
              <th>Device</th>
              <th>Lot Numbers</th>
              <th># Data Points</th>
              <th>Last Edited</th>
            </tr>
          </thead>
          <tbody>{runs.map((cr, i) => renderTableRow(cr, i))}</tbody>
        </Table>
      )}
    </>
  )
}
