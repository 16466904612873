import React, { useEffect, useState } from 'react'
import { ChartData, ChartOptions } from 'chart.js'
import Alert from 'react-bootstrap/Alert'
import { Scatter } from 'react-chartjs-2'

import Loader from 'js/components/Loader'

import Controller from './controller'
import { YoudenChartProps } from './models'

export default function YoudenChart(
  props: YoudenChartProps
): React.ReactElement {
  const [alerts, setAlerts] = useState<string[]>([])
  const [options, setOptions] = useState<ChartOptions>()
  const [chartData, setChartData] = useState<ChartData>()

  useEffect(() => {
    // this optimization assumes that if xAvg is defined then the other average and the sd props are
    // also defined if this isn't the case, the error will still be caught by the try...catch
    if (!props.reportData.results || isNaN(props.xAvg)) return

    try {
      const calculatedData = Controller.calculateYoudenData(props)
      setChartData(Controller.getYoudenChartData(calculatedData))
      setOptions(Controller.getYoudenOptions(calculatedData))
      setAlerts(Controller.validateYoudenData(calculatedData))
    } catch (e) {
      console.error(e)
    }
  }, [props])

  // Check that standard deviations aren't null
  if (props.ySd == 0 || props.xSd == 0)
    return <Alert variant='danger'>Standard deviation cannot be 0.</Alert>

  if (!chartData) return <Loader />

  return (
    <div className='youden-chart-wrapper position-relative'>
      {alerts.length > 0 && (
        <Alert variant='warning'>
          {alerts.map((message, index) => (
            <p key={'youden-warning-' + index}>{message}</p>
          ))}
        </Alert>
      )}
      <Scatter
        height={400}
        width={400}
        data={chartData as Chart.ChartData}
        options={options as Chart.ChartOptions}
      />
    </div>
  )
}
