import { Lot } from 'js/models'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'

import api, { FormPromise } from './api'

/*=============================================
=                     GET                     =
=============================================*/
export const getLot = (id: number): Promise<Lot> => {
  return api.get(`/lots/${id}/`).then(r => makeCamelCase(r.data))
}

/*=============================================
=                    POST                     =
=============================================*/
export const createLot = (data: Partial<Lot>): FormPromise<Lot> => {
  return api
    .post('/lots/', makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as Lot)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                    PATCH                    =
=============================================*/

/*=============================================
=                    DELETE                   =
=============================================*/
export const deleteLot = (id: number): Promise<Response> => {
  return api.delete(`/lots/${id}/`).then(r => makeCamelCase(r.data))
}
