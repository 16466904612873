import React, { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import ForamttedEmail from 'js/components/FormattedEmail'
import ChangePasswordSelf from 'js/components/forms/ChangePasswordSelf'
import EditUserModal from 'js/components/forms/EditUserModal'
import InfoPiece from 'js/components/InfoPiece'

import { User } from 'js/models'
import { SelfUser } from 'js/utils/helpers'
import VQCPermissions from 'js/utils/permissions'

export default function Profile(): React.ReactElement {
  const selfUser = useContext(SelfUser)
  const [user, setUser] = useState<User>(selfUser)
  const [showEdit, setShowEdit] = useState(false)
  const canView = VQCPermissions.canViewCustomerData(selfUser)

  const handleShowEdit = (): void => setShowEdit(true)
  const handleCloseEdit = (): void => setShowEdit(false)

  return (
    <>
      <h1>Profile</h1>
      <Row>
        <Col>
          <h2>Contact</h2>
          <InfoPiece label='Name'>{user.name}</InfoPiece>
          <InfoPiece label='Email'>
            <ForamttedEmail email={user.email} />
          </InfoPiece>
          <InfoPiece label='Organization'>
            {user.organizationDetails?.name || 'None'}
          </InfoPiece>
          <InfoPiece label='Sites'>
            {user.sites.length > 0 ? user.siteNames.join(', ') : 'None'}
          </InfoPiece>
          {canView && (
            <InfoPiece label='Roles'>
              {user.groups.length > 0 ? user.groups.join(', ') : 'None'}
            </InfoPiece>
          )}
          <Button onClick={handleShowEdit}>Edit</Button>
        </Col>
        <Col>
          <h2>Change Password</h2>
          <ChangePasswordSelf />
        </Col>
      </Row>

      <EditUserModal
        show={showEdit}
        user={user}
        handleClose={handleCloseEdit}
        setUser={setUser}
      />
    </>
  )
}
