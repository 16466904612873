import React, { useEffect, useState } from 'react'
import { ChartData, ChartOptions } from 'chart.js'
import { Scatter } from 'react-chartjs-2'
import Controller from './controller'
import { ErrorBarChartProps } from './models'

const LegendItem = (props: {
  content: string
  color: string
}): React.ReactElement => {
  const dotSize = '0.5rem'
  return (
    <div className='d-flex align-items-center nowrap'>
      <span
        className={`bg-${props.color} me-2`}
        style={{ width: dotSize, height: dotSize, borderRadius: dotSize }}
      ></span>
      {props.content}
    </div>
  )
}

export default function ErrorBarChart(
  props: ErrorBarChartProps
): React.ReactElement {
  const [options, setOptions] = useState<ChartOptions>()
  const [chartData, setChartData] = useState<ChartData>()

  useEffect(() => {
    // this assumes that if groupData.groupAverage is defined then the rest of the props data is also defined
    // if this isn't the case, the error will still be caught by the try...catch
    if (!props.groupData.groupAverage) return
    try {
      const data = Controller.getCalculatedErrorBarChartData(props)
      if (data) setChartData(Controller.getErrorBarChartData(data))
      setOptions(Controller.getErrorBarChartOptions(data))
    } catch (e) {
      console.error(e)
    }
  }, [props])

  return (
    <div className='border border-secondary p-2 mb-3 position-relative print-avoid-break-inside'>
      <div className='d-flex align-items-center'>
        <h3 className='text-center flex-grow-1'>{props.level}</h3>
        <div className='small'>
          <LegendItem content='Mean' color='primary' />
          <LegendItem content='+2 SD' color='danger' />
          <LegendItem content='-2 SD' color='secondary' />
        </div>
      </div>
      {chartData && (
        <Scatter
          data={chartData as Chart.ChartData}
          options={options as Chart.ChartOptions}
        />
      )}
    </div>
  )
}
