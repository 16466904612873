import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { useHistory } from 'react-router-dom'

import { Site } from 'js/models'
import { listSites, listSitesParams } from 'js/services/site'
import Paths from 'js/utils/paths'
import VQCPermissions from 'js/utils/permissions'

import CreateSiteModal from '../forms/CreateSiteModal'
import Loader from '../Loader'
import PaginationBar from '../PaginationBar'
import SearchBar from '../SearchBar'

export default function AdminSiteTable(props: {
  listParams?: listSitesParams
  order?: string
  refreshParent?: () => void
}): React.ReactElement {
  const { listParams, order, refreshParent } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [sites, setSites] = useState<Site[]>()
  const [searchString, setSearchString] = useState('')
  const [showNewSite, setShowNewSite] = useState(false)
  const history = useHistory()

  const canEdit = VQCPermissions.canEditCustomerData()

  const handleShowNewSite = (): void => setShowNewSite(true)
  const handleCloseNewSite = (): void => setShowNewSite(false)

  useEffect(() => {
    refreshSites()
  }, [currentPage, listParams, order, searchString])

  const search = (s: string): void => {
    setCurrentPage(1)
    setSearchString(s)
  }

  const inlineAddress = (site: Site): string => {
    const lines: string[] = []
    if (site.addressLine2) lines.push(site.addressLine2)
    if (site.addressLine3) lines.push(site.addressLine3)
    return lines.join(', ')
  }

  const refreshSites = (): void => {
    listSites({
      ...listParams,
      page: currentPage,
      order: order || 'name',
      search: searchString,
    }).then(r => {
      setSites(r.results)
      setTotalPages(r.pageTotal)
    })
  }

  const renderTableRow = (site: Site): React.ReactElement => {
    return (
      <tr
        className='clickable'
        onClick={(): void => {
          history.push(Paths.getAdminSiteDetailLink(site.id))
        }}
        key={site.id}
      >
        <td>{site.name}</td>
        <td>{site.organizationName}</td>
        <td>{inlineAddress(site) || '–'}</td>
      </tr>
    )
  }

  if (!sites) return <Loader />

  if (sites.length === 0 && !searchString && canEdit)
    return (
      <>
        <Alert variant='info'>
          <Alert.Heading>Creating Sites</Alert.Heading>
          <p>
            <i>Sites</i> are your organization&apos;s physical locations. They
            could be different buildings within your medical system, or even
            separate labs within the same building.
          </p>
          <p>
            Each device you have will be registered to a specific site, and
            users can be assigned to one or more sites. Users only have access
            to the data from instruments at thier sites. Organization admins
            automatically have access to instruments at all sites.
          </p>
          <Button variant='info' onClick={handleShowNewSite}>
            Create Your First Site
          </Button>
        </Alert>
        <CreateSiteModal
          show={showNewSite}
          handleClose={handleCloseNewSite}
          orgId={listParams?.organization}
          refreshSites={(): void => {
            refreshParent && refreshParent()
            refreshSites()
          }}
        />
      </>
    )

  return (
    <>
      <div className='table-top-bar'>
        <div className='table-top-section'>
          <SearchBar
            label={'Search'}
            placeholder={'Search by name or address'}
            doSearch={search}
          />
        </div>
        <div className='table-top-section'>
          {canEdit && <Button onClick={handleShowNewSite}>New Site</Button>}
        </div>
      </div>
      {sites.length == 0 ? (
        <p>No sites to display</p>
      ) : (
        <>
          <Table striped hover className='table-header-primary'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Organization</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>{sites.map(site => renderTableRow(site))}</tbody>
          </Table>
          <PaginationBar
            pageTotal={totalPages}
            currentPage={currentPage}
            clickPage={setCurrentPage}
          />
        </>
      )}

      <CreateSiteModal
        show={showNewSite}
        handleClose={handleCloseNewSite}
        orgId={listParams?.organization}
        refreshSites={(): void => {
          refreshParent && refreshParent()
          refreshSites()
        }}
      />
    </>
  )
}
