import { WorkingTimeOptions } from 'js/utils/constants'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'
import api from './api'
//prettier-ignore
import { CompleteDeviceReport, DeviceReport, LotCumulativeReport, LotReport } from './report-models'

interface CommonReportParams {
  // Required params
  year: number //Default None
  month: number //Default None
  lotGroup: number //Default None

  // Query parameters
  yearEnd?: number //Default None
  monthEnd?: number //Default None
}

interface DeviceReportParams extends CommonReportParams {
  // Required params
  device: number //Default None

  // Query parameters
  temperatureCorrected?: boolean | null //Default None
  workingTime?: WorkingTimeOptions //Default None

  // Indicates which reports to include in device report
  detailedDevice?: boolean //Default false
  detailedDeviceCumulative?: boolean //Default false
  detailedGroup?: boolean //Default false
  detailedGroupCumulative?: boolean //Default false
  youden?: boolean //Default false
}

interface LotCumulativeReportParams {
  deviceTypes: number[]
  lot: number
}

type LotReportParams = CommonReportParams

/*=============================================
=                     GET                     =
=============================================*/
export const getDeviceReports = (
  params: DeviceReportParams
): Promise<DeviceReport> => {
  return api
    .get('/device-report/', { params: makeSnakeCase(params) })
    .then(r => makeCamelCase(r.data))
}

export const getAllDeviceReports = (
  params: DeviceReportParams
): Promise<CompleteDeviceReport> => {
  const completeParams = {
    detailedDevice: true,
    detailedDeviceCumulative: true,
    detailedGroup: true,
    detailedGroupCumulative: true,
    youden: true,
  }
  return getDeviceReports({
    ...completeParams,
    ...makeCamelCase(params),
  }) as Promise<CompleteDeviceReport>
}

export const getLotReport = (params: LotReportParams): Promise<LotReport> => {
  return api
    .get('/lot-report/', { params: makeSnakeCase(params) })
    .then(r => makeCamelCase(r.data))
}

export const getLotCumulativeReport = (
  params: LotCumulativeReportParams
): Promise<LotCumulativeReport> => {
  const { deviceTypes, ...rest } = params
  const dtString = deviceTypes.join(',')
  return api
    .get('/lot-cumulative-report/', {
      params: makeSnakeCase({ deviceTypes: dtString, ...rest }),
    })
    .then(r => makeCamelCase(r.data))
}
