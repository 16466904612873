import { ChartData, ChartDataset, ChartOptions } from 'chart.js'
import { DeviceEntry } from 'js/models'
import { COLORS } from 'js/utils/constants'
import { BaseChartOptions } from '../chart-utils'
import { LJChartProps, CalculatedLJData } from './models'
import Utils from './utils'

const calculateLJData = (
  props: LJChartProps,
  deviceEntries: DeviceEntry[]
): CalculatedLJData => {
  // validate prop data
  if (!deviceEntries) throw Error('`deviceEntries` is undefined')
  if (isNaN(props.avg)) throw Error('`props.avg` must be a number')
  if (isNaN(props.sd) || props.sd <= 0)
    throw Error('Invalid value for `props.sd`: ' + props.sd)

  const data = {} as CalculatedLJData
  data.avg = props.avg
  data.sd = props.sd
  data.sdM2 = data.avg - data.sd * 2
  data.sdP2 = data.avg + data.sd * 2
  data.sdM3 = data.avg - data.sd * 3
  data.sdP3 = data.avg + data.sd * 3
  data.avgLineCoordinates = []
  data.devicePointsCoordinates = []

  const deviceDict = Utils.mapDeviceEntriesByDate(deviceEntries)

  data.labels = Utils.getLabels(props.year, props.month)

  data.avgLineCoordinates = Object.keys(deviceDict).map(k => ({
    x: parseInt(k),
    y: deviceDict[k].dayAvg,
  }))

  data.devicePointsCoordinates = []
  Object.keys(deviceDict).map(k => {
    deviceDict[k].devices.map(({ value }) => {
      data.devicePointsCoordinates.push({ x: parseInt(k), y: value })
    })
  })

  data.showBothDatasets =
    data.avgLineCoordinates.length !== data.devicePointsCoordinates.length

  return data
}

const getLJChartData = (data: CalculatedLJData): ChartData => {
  const averagesDataset: ChartDataset = {
    label: 'Daily Averages',
    type: 'line',
    data: data.avgLineCoordinates,
    fill: false,
    backgroundColor: COLORS.primary,
    borderColor: COLORS.primary,
  }

  if (data.showBothDatasets) {
    return {
      labels: data.labels,
      datasets: [
        averagesDataset,
        {
          label: 'Entries',
          type: 'scatter',
          data: data.devicePointsCoordinates,
          borderColor: COLORS.secondary,
        },
      ],
    }
  }

  return {
    labels: data.labels,
    datasets: [averagesDataset],
  }
}

const getLJOptions = (data: CalculatedLJData): ChartOptions => {
  const options: BaseChartOptions = { scales: {}, plugins: {}, elements: {} }

  options.scales.y = { suggestedMin: data.sdM3, suggestedMax: data.sdP3 }
  options.plugins.legend = { display: data.showBothDatasets }
  options.plugins.annotation = {
    annotations: {
      meanLine: Utils.getMeanLineAnnotation(data.avg),
      stdPos2Line: Utils.getSDLineAnnotation(data.sdP2, '+'),
      stdNeg2Line: Utils.getSDLineAnnotation(data.sdM2, '-'),
      topBg: Utils.getTopBgAnnotation(data.sdP2),
      bottomBg: Utils.getBottomBgAnnotation(data.sdM2),
    },
  }

  return options
}

const Controller = { calculateLJData, getLJChartData, getLJOptions }
export default Controller
