import React from 'react'

export default function SignatureLine(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const { className, ...rest } = props
  return (
    <div className={'signature-line-wrapper ' + (className || '')} {...rest}>
      <div className='signature label-line-pair'>
        <div>Signature:</div>
        <div className='line'></div>
      </div>
      <div className='date label-line-pair'>
        <div>Date: </div>
        <div className='line'></div>
      </div>
    </div>
  )
}
