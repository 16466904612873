import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Organization, OrganizationCode } from 'js/models'

import { Steps } from '.'
import ConfirmOrganization from './ConfirmOrganization'
import CreateOrganization from './CreateOrganization'
import CreateUser from './CreateUser'
import FindOrganization from './FindOrganization'
import RegistrationFinish from './RegistrationFinish'
import Start from './Start'

export default function RegistrationFlowEntry(): React.ReactElement {
  const [currentStep, setCurrentStep] = useState(Steps.Start)
  const [previousStep, setPreviousStep] = useState(Steps.Start)
  const [newOrgData, setNewOrgData] = useState<Partial<Organization>>()
  const { organizationCode } =
    useParams<{ organizationCode: OrganizationCode }>()
  // Hold on to email and password to log in on the last step
  const [token, storeToken] = useState<string>()

  useEffect(() => {
    if (organizationCode) {
      setCurrentStep(Steps.ConfirmOrgainization)
    }
  }, [organizationCode])

  const changeStep = (newStep: Steps): void => {
    setPreviousStep(currentStep)
    setCurrentStep(newStep)
  }

  switch (currentStep) {
    case Steps.FindOrganization:
      return <FindOrganization setStep={changeStep} />
    case Steps.ConfirmOrgainization:
      return (
        <ConfirmOrganization
          setStep={changeStep}
          organizationCode={organizationCode as OrganizationCode}
        />
      )
    case Steps.CreateOrganization:
      return <CreateOrganization setStep={changeStep} setData={setNewOrgData} />
    case Steps.CreateUser:
      return (
        <CreateUser
          setStep={changeStep}
          storeToken={storeToken}
          previousStep={previousStep}
        />
      )
    case Steps.ShareJoinCode:
      return (
        <RegistrationFinish
          setStep={changeStep}
          token={token as string}
          organizationCode={organizationCode}
          organizationData={newOrgData}
        />
      )
    default:
      return <Start setStep={changeStep} />
  }
}
