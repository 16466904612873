import { useContext } from 'react'
import { User } from 'js/models'
import { SelfUser } from './helpers'

const hasGroup = (group: Groups, user?: User): boolean => {
  if (!user) user = useContext(SelfUser)
  return user.groups.includes(group)
}

const hasAnyGroups = (groupSet: Groups[], user?: User): boolean => {
  if (!user) user = useContext(SelfUser)
  return user.groups.some(group => groupSet.includes(group))
}

// const hasAllGroups = (groupSet: Groups[], user?: User): boolean => {
//   if (!user) user = useContext(SelfUser)
//   return user.groups.every(group => groupSet.includes(group))
// }

export enum Groups {
  superAdmin = 'Super Admin',
  supportAdmin = 'Support Admin',
  viewerAdmin = 'Viewer Admin',
  docController = 'Doc Controller',
  organizationAdmin = 'Organization Admin',
}
export const ELITECH_ADMINS = [
  Groups.superAdmin,
  Groups.supportAdmin,
  Groups.viewerAdmin,
  Groups.docController,
]
export const ALL_ADMINS = [
  Groups.superAdmin,
  Groups.supportAdmin,
  Groups.viewerAdmin,
  Groups.docController,
  Groups.organizationAdmin,
]

const CAN_EDIT_CUSTOMER_DATA = [
  Groups.superAdmin,
  Groups.supportAdmin,
  Groups.organizationAdmin,
]
const CAN_EDIT_ELITECH_DATA = [Groups.superAdmin, Groups.docController]
const CAN_EDIT_ELITECH_GROUPS = [Groups.superAdmin]
const CAN_EDIT_USER_ORGANIZATION = [Groups.superAdmin, Groups.supportAdmin]
const CAN_APPROVE_ORGANIZATION = [Groups.superAdmin, Groups.supportAdmin]
const CAN_REENABLE_DEVICES = [Groups.superAdmin, Groups.supportAdmin]

const CAN_VIEW_CUSTOMER_DATA = [...CAN_EDIT_CUSTOMER_DATA, Groups.viewerAdmin]
const CAN_VIEW_ELITECH_DATA = ELITECH_ADMINS

const VQCPermissions: { [key: string]: { (u?: User): boolean } } = {
  isSuperAdmin: u => hasGroup(Groups.superAdmin, u),
  isSupportAdmin: u => hasGroup(Groups.supportAdmin, u),
  isViewerAdmin: u => hasGroup(Groups.viewerAdmin, u),
  isDocController: u => hasGroup(Groups.docController, u),
  isOrganizationAdmin: u => hasGroup(Groups.organizationAdmin, u),

  isElitechAdmin: u => hasAnyGroups(ELITECH_ADMINS, u),
  canEditCustomerData: u => hasAnyGroups(CAN_EDIT_CUSTOMER_DATA, u),
  canEditElitechData: u => hasAnyGroups(CAN_EDIT_ELITECH_DATA, u),
  canEditElitechGroups: u => hasAnyGroups(CAN_EDIT_ELITECH_GROUPS, u),
  canEditUserOrganization: u => hasAnyGroups(CAN_EDIT_USER_ORGANIZATION, u),
  canApproveOrganization: u => hasAnyGroups(CAN_APPROVE_ORGANIZATION, u),
  canReenableDevices: u => hasAnyGroups(CAN_REENABLE_DEVICES, u),
  canViewCustomerData: u => hasAnyGroups(CAN_VIEW_CUSTOMER_DATA, u),
  canViewElitechData: u => hasAnyGroups(CAN_VIEW_ELITECH_DATA, u),
}

export default VQCPermissions
