import RegistrationFlowEntry from './RegistrationFlowEntry'
export default RegistrationFlowEntry

export enum Steps {
  Start,
  FindOrganization,
  ConfirmOrgainization,
  CreateOrganization,
  CreateUser,
  ShareJoinCode,
}

export interface StepContentProps {
  setStep: (Steps) => void
}
