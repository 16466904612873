import React, { useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

export default function SearchBar(props: {
  doSearch: (terms: string) => void
  label: string
  placeholder: string
  disabled?: boolean
}): React.ReactElement {
  const { doSearch, label, placeholder, disabled } = props
  const searchField = useRef<HTMLInputElement>(null)

  const search = (e): void => {
    if (searchField.current) {
      e.preventDefault()
      doSearch(searchField.current.value)
    }
  }

  const clearSearch = (): void => {
    if (searchField.current) searchField.current.value = ''
    doSearch('')
  }

  return (
    <Form onSubmit={search}>
      <InputGroup className='search-bar'>
        <Form.Label className='visually-hidden'>{label}</Form.Label>
        <Form.Control
          placeholder={placeholder}
          ref={searchField}
          autoComplete='off'
          disabled={disabled}
        />
        <InputGroup.Append>
          <Button variant='primary' type='submit' disabled={disabled}>
            Search
          </Button>
        </InputGroup.Append>
        <InputGroup.Append hidden={false}>
          <Button
            variant='warning'
            className='ms-2'
            onClick={clearSearch}
            hidden={!searchField.current?.value}
            disabled={disabled}
          >
            Clear Search
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  )
}
