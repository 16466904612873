import { Control, Lot } from 'js/models'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'
import api, { BaseQueryParams, FormPromise, Paginated } from './api'

interface ListControlsParams extends BaseQueryParams {
  name?: string
  name__icontains?: string
  test_name?: string
  test_name__icontains?: string
  device_types?: string
  device_types__icontains?: string
}

/*=============================================
=                     GET                     =
=============================================*/
export const listControls = (
  params?: ListControlsParams
): Promise<Paginated<Control>> => {
  return api
    .get('/controls/', { params: params })
    .then(r => makeCamelCase(r.data))
}

export const getControl = (id: number): Promise<Control> => {
  return api.get(`/controls/${id}/`).then(r => makeCamelCase(r.data))
}

export const getControlsByLots = async (lots: Lot[]): Promise<Control[]> => {
  if (lots.length == 0) return []
  const controlIds = lots.map(l => l.control).join(',')
  return (await listControls({ ids: controlIds })).results
}

/*=============================================
=                    POST                     =
=============================================*/
export const createControl = (data: Partial<Control>): FormPromise<Control> => {
  return api
    .post('/controls/', makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as Control)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                    PATCH                    =
=============================================*/

export const partialUpdateControl = (
  id: number,
  data: Partial<Control>
): FormPromise<Control> => {
  return api
    .patch(`/controls/${id}/`, makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as Control)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                     DELETE                  =
=============================================*/
export const deleteControl = (id: number): Promise<Response> => {
  return api.delete(`/controls/${id}/`)
}
