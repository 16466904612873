import { ChartOptions, FontSpec, LinearScaleOptions, Point } from 'chart.js'
// prettier-ignore
import { DrawTime, LabelOptions, LineAnnotationOptions } from 'chartjs-plugin-annotation'
import { COLORS } from 'js/utils/constants'
import { round } from 'js/utils/helpers'

// Sometimes the types in the node packages aren't as robust as what's
// actually allowed in the documents (or found in Stack Overflow) so
// we extend them instead of using "any"
export interface XYCoordinate extends Point {
  deviceId?: number
}

type baseLinearScaleTickOptions = Omit<LinearScaleOptions['ticks'], 'callback'>
export interface LinearScaleTickOptions extends baseLinearScaleTickOptions {
  callback: (number) => string | string[]
}

export type BaseChartOptions = ChartOptions & { scales; plugins; elements }

interface getLineAnnotationProps extends LineAnnotationOptions {
  id: string
  value: number
  labelStr?: string
  mode?: 'horizontal' | 'vertical'
}
export const getLineAnnotation = (
  props: getLineAnnotationProps
): LineAnnotationOptions => {
  const { id, mode, value, labelStr, ...rest } = props

  return Object.assign(rest || {}, {
    drawTime: 'beforeDatasetsDraw' as DrawTime,
    id: id,
    type: 'line',
    mode: mode || 'horizontal',
    value: value,
    scaleID: mode === 'vertical' ? 'x' : 'y',
    borderWidth: 2,
    label: {
      enabled: Boolean(labelStr),
      content: labelStr,
      position: 'start',
    } as LabelOptions,
  })
}

export const getAnnotationLabel = (
  val: number | string,
  enabled = true
): { label: LabelOptions } => {
  return {
    label: {
      backgroundColor: `${COLORS.light}cc`,
      color: COLORS.secondary,
      enabled: enabled,
      content: (isNaN(val as number) ? val : round(val as number, 2)) as string,
      position: 'end',
      cornerRadius: 0,
      font: { style: 'normal' } as FontSpec,
    } as LabelOptions,
  }
}
