import { Device } from 'js/models'
// prettier-ignore
import { DetailedDeviceData, DetailedGroupData } from 'js/services/report-models'
import { getPeerIndex, getSDIndex, round } from 'js/utils/helpers'

export interface SRTProps {
  device: Device
  detailedGroups: DetailedGroupData[]
  detailedDevices: DetailedDeviceData[]
  detailedGroupsCums: DetailedGroupData[]
  detailedDevicesCums: DetailedDeviceData[]
}

export interface TableRowDataset {
  device: DetailedDeviceData
  group: DetailedGroupData
  deviceCum: DetailedDeviceData
  groupCum: DetailedGroupData
}

type DataSubset = 'device' | 'group' | 'deviceCum' | 'groupCum'
type CallbackFunc = (dataset: TableRowDataset) => string | number

interface CallbackConfig {
  label: string
  callback: CallbackFunc
}
interface AttrConfig {
  label: string
  subset: DataSubset
  attr: string
}
export type TableConfig = CallbackConfig | AttrConfig

export const getStrFromTableConfig = (
  tc: TableConfig,
  dataset: TableRowDataset
): string => {
  let val
  if ('subset' in tc && 'attr' in tc && tc.subset && tc.attr)
    val = dataset[tc.subset][tc.attr]
  else if ('callback' in tc && tc.callback) val = tc.callback(dataset)
  if (typeof val == 'number') val = round(val as number, 2)
  else if (val == '') val = '-'
  return val as string
}

export const TABLE_CONFIG: { month: TableConfig[]; cumulative: TableConfig[] } =
  {
    month: [
      { label: 'Test Analyte', subset: 'device', attr: 'testName' },
      { label: 'Lot Number', subset: 'device', attr: 'lotNumber' },
      { label: 'Control Level', subset: 'device', attr: 'level' },
      { label: 'Units', subset: 'device', attr: 'units' },
      { label: 'Your Mean', subset: 'device', attr: 'machineAverage' },
      { label: 'Group Mean', subset: 'group', attr: 'groupAverage' },
      { label: 'Your SD', subset: 'device', attr: 'machineStandardDeviation' },
      {
        label: 'Your CV',
        subset: 'device',
        attr: 'machineCoefficientOfVariance',
      },
      { label: 'Group SD', subset: 'group', attr: 'groupStandardDeviation' },
      {
        label: 'Group CV',
        subset: 'group',
        attr: 'groupCoefficientOfVariance',
      },
      { label: 'Your Points', subset: 'device', attr: 'machineCount' },
      { label: 'Group Points', subset: 'group', attr: 'groupCount' },
      {
        label: 'Your SDI',
        callback: (dataset => {
          return getSDIndex(
            dataset.device.machineAverage,
            dataset.group.groupAverage,
            dataset.group.groupStandardDeviation
          )
        }) as CallbackFunc,
      },
      {
        label: 'Peer Index',
        callback: (dataset =>
          getPeerIndex(
            dataset.device.machineCoefficientOfVariance,
            dataset.group.groupCoefficientOfVariance
          )) as CallbackFunc,
      },
    ],
    cumulative: [
      { label: 'Your Mean', subset: 'deviceCum', attr: 'machineAverage' },
      { label: 'Group Mean', subset: 'groupCum', attr: 'groupAverage' },
      {
        label: 'Your SD',
        subset: 'deviceCum',
        attr: 'machineStandardDeviation',
      },
      {
        label: 'Your CV',
        subset: 'deviceCum',
        attr: 'machineCoefficientOfVariance',
      },
      { label: 'Group SD', subset: 'groupCum', attr: 'groupStandardDeviation' },
      {
        label: 'Group CV',
        subset: 'groupCum',
        attr: 'groupCoefficientOfVariance',
      },
      { label: 'Your Points', subset: 'deviceCum', attr: 'machineCount' },
      { label: 'Group Points', subset: 'groupCum', attr: 'groupCount' },
      {
        label: 'Your SDI',
        callback: (dataset => {
          return getSDIndex(
            dataset.deviceCum.machineAverage,
            dataset.groupCum.groupAverage,
            dataset.groupCum.groupStandardDeviation
          )
        }) as CallbackFunc,
      },
      {
        label: 'Peer Index',
        callback: (dataset =>
          getPeerIndex(
            dataset.deviceCum.machineCoefficientOfVariance,
            dataset.groupCum.groupCoefficientOfVariance
          )) as CallbackFunc,
      },
    ],
  }
