import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import InfoPiece from 'js/components/InfoPiece'
import Loader from 'js/components/Loader'

import { Control, DeviceType, Lot, LotGroup } from 'js/models'
import { getControlsByLots } from 'js/services/controls'
import { listDeviceTypes } from 'js/services/deviceType'
import { formatDate } from 'js/utils/helpers'
import VQCPermissions from 'js/utils/permissions'

import CreateLotForm from './CreateLotForm'
import DeviceLotValuesTable from './DeviceLotValuesTable'
import { deleteLotModal } from './Modals'
import LotCumulativeReportTable from './LotCumulativeReportTable'

export default function LotTabs(props: {
  lotGroup: LotGroup
  setLotGroup: (lotGroup: LotGroup) => void
}): React.ReactElement {
  const { lotGroup, setLotGroup } = props
  const [controls, setControls] = useState<Control[]>()
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>()
  const [activeTab, setActiveTab] = useState(lotGroup.lots[0]?.id || 'newLot')
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const canEdit = VQCPermissions.canEditElitechData()

  const handleShowConfirmDelete = (): void => setShowConfirmDelete(true)
  const handleCloseConfirmDelete = (): void => setShowConfirmDelete(false)

  useEffect(() => {
    getControlsByLots(lotGroup.lots).then(r => setControls(r))
    listDeviceTypes().then(r => setDeviceTypes(r.results))
  }, [lotGroup])

  const renderTab = (lot: Lot, control: Control): React.ReactElement => {
    return (
      <Tab
        eventKey={lot.id}
        title={`${control.level} - ${control.levelName}`}
        key={lot.id}
      >
        <Row>
          <Col>
            <h3>Lot Information</h3>
            <InfoPiece label='Date Created'>
              {formatDate(lot.creationTime)}
            </InfoPiece>
            <InfoPiece label='Lot Number'>{lot.lotNumber}</InfoPiece>
            {canEdit && (
              <Button variant='danger' onClick={handleShowConfirmDelete}>
                Delete Lot
              </Button>
            )}
          </Col>
          <Col>
            <h3>Control</h3>
            <InfoPiece label='Test'>{control.testName}</InfoPiece>
            <InfoPiece label='Name'>{control.name}</InfoPiece>
            <InfoPiece label='Level'>
              {control.level} – {control.levelName}
            </InfoPiece>
            <InfoPiece label='Expires'>
              {formatDate(lotGroup?.expirationDate)}
            </InfoPiece>
            <InfoPiece label='Device Models'>
              {control.deviceTypes
                .map(t => deviceTypes?.find(type => type.id == t)?.name)
                .join(', ')}
            </InfoPiece>
          </Col>
        </Row>
        <h3>Expected Values</h3>
        <DeviceLotValuesTable control={control} lot={lot} />
        <h3 className='mt-5'>Reporting Values</h3>
        <LotCumulativeReportTable control={control} lot={lot} />
      </Tab>
    )
  }

  if (!controls || controls.length != lotGroup.lots.length) return <Loader />

  const getControl = (lot: Lot): Control => {
    return controls.find(control => control.id == lot.control) as Control
  }

  return (
    <>
      <Tabs
        id='lot-group-levels-nav'
        activeKey={activeTab}
        onSelect={(key): void => setActiveTab(key as string)}
      >
        {lotGroup.lots
          .sort((a, b) => {
            return getControl(a).level - getControl(b).level
          })
          .map(lot => renderTab(lot, getControl(lot)))}
        {canEdit && (
          <Tab eventKey='newLot' title='+ New Lot'>
            <h3>Create New Lot</h3>
            <CreateLotForm
              lotGroup={lotGroup}
              setLotGroup={setLotGroup}
              setActiveTab={setActiveTab}
            />
          </Tab>
        )}
      </Tabs>

      {deleteLotModal(
        activeTab as number,
        showConfirmDelete,
        handleCloseConfirmDelete
      )}
    </>
  )
}
