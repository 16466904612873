import { HelpKeys } from 'js/containers/Help/helpContent'

// Path params interfaces
export interface ControlRunUrlParams {
  year: string
  month: string
  deviceId: string
  lotGroupId: string
}

// Control run link getters
interface CRLinkParams {
  year: string | number
  month: string | number
  deviceId: string | number
  lotGroupId: string | number
}
type IcrLink = (params: CRLinkParams) => string
const crDateLink = (params: Pick<CRLinkParams, 'year' | 'month'>): string =>
  `/control-run/${params.year}/${params.month}`

const crDetailLink: IcrLink = ({ deviceId, lotGroupId, ...dateParams }) =>
  crDateLink(dateParams) + `/${deviceId}/${lotGroupId}`

const crReportLink: IcrLink = params => crDetailLink(params) + '/report'

const Paths = {
  home: '/',
  profile: '/profile',
  privacyPolicy: '/privacy-policy',
  help: '/help',
  getHelpSectionLink: (section: HelpKeys): string => `/help#${section}`,
  correctiveActionForm: '/corrective-action-form',
  submitData: '/submit-data',
  getSubmitDataLink: (
    organization?: number,
    lotGroup?: number,
    device?: number
  ): string => {
    let path = '/submit-data?'
    organization && (path += `organization=${organization}&`)
    lotGroup && (path += `lot-group=${lotGroup}&`)
    device && (path += `device=${device}&`)
    return path
  },

  // Admin Paths
  adminDeviceTypes: '/admin/device-types',
  adminControlTypes: '/admin/control-types',
  adminControlTypeDetail: '/admin/control-type/:pk',
  getAdminControlTypeDetailLink: (pk): string => `/admin/control-type/${pk}`,
  adminDevices: '/admin/devices',
  adminDeviceDetail: '/admin/device/:pk',
  getAdminDeviceDetailLink: (pk): string => `/admin/device/${pk}`,
  adminUsers: '/admin/users',
  adminUserDetail: '/admin/user/:pk',
  getAdminSiteDetailLink: (pk): string => `/admin/site/${pk}`,
  adminSiteDetail: '/admin/site/:pk',
  getAdminUserDetailLink: (pk): string => `/admin/user/${pk}`,
  adminLotGroups: '/admin/lot-groups',
  adminLotGroupDetail: '/admin/lot-group/:pk',
  getAdminLotGroupDetailLink: (pk): string => `/admin/lot-group/${pk}`,
  adminOrganizations: '/admin/organizations',
  adminOrganizationDetail: '/admin/organization/:pk',
  getAdminOrganizationDetailLink: (pk): string => `/admin/organization/${pk}`,

  // Control Run Paths
  controlRuns: '/control-run',
  controlRunsTable: '/control-run/:year/:month',
  getControlRunsTableLink: crDateLink,
  controlRunDetail: '/control-run/:year/:month/:deviceId/:lotGroupId',
  getControlRunDetailLink: crDetailLink,
  controlRunReport: '/control-run/:year/:month/:deviceId/:lotGroupId/report',
  getControlRunDetailReportLink: crReportLink,
}

export default Paths
