import { findById, findByLevelName } from 'js/utils/helpers'
import { BaseChartOptions, XYCoordinate } from '../chart-utils'
import { ChartData, ChartOptions } from 'chart.js'
import { CalculatedYoudenData, YoudenChartProps } from './models'
import Utils from './utils'

const calculateYoudenData = (props: YoudenChartProps): CalculatedYoudenData => {
  const numberProps = ['xSd', 'ySd', 'xAvg', 'yAvg']
  numberProps.forEach(atr => {
    if (isNaN(props[atr])) throw Error(`\`props.${atr}\` must be a number`)
  })

  const { reportData, ...rest } = props
  const data = { ...rest } as CalculatedYoudenData

  Utils.AXES.forEach(axis => {
    Utils.SD_DISTANCES.forEach(distance => {
      data[`sdM${distance}${axis}`] =
        data[`${axis}Avg`] - data[`${axis}Sd`] * distance

      data[`sdP${distance}${axis}`] =
        data[`${axis}Avg`] + data[`${axis}Sd`] * distance
    })
  })

  data.xDevices = findByLevelName(reportData.results, data.xLevelName).devices
  data.yDevices = findByLevelName(reportData.results, data.yLevelName).devices

  data.myDeviceId = reportData.myDevice
  const myXDevice = findById(data.xDevices, data.myDeviceId)
  const myYDevice = findById(data.yDevices, data.myDeviceId)
  data.myDeviceCoordinates = {
    x: myXDevice?.average,
    y: myYDevice?.average,
  } as XYCoordinate

  data.coordinates = Utils.getYoudenCoordinates(data.xDevices, data.yDevices)

  return data
}

const getYoudenChartData = (data: CalculatedYoudenData): ChartData => ({
  datasets: [{ data: data.coordinates }],
})

const getYoudenOptions = (data: CalculatedYoudenData): ChartOptions => {
  const scaleTitleOptions = { display: true, font: { size: 14 } }
  // prettier-ignore
  const options: BaseChartOptions = { scales: {}, plugins: {}, elements: { point: {} } }

  options.aspectRatio = 1
  // Set min/max of y axis to be 3 standard deviations
  options.scales.y = {
    min: data.sdM3y,
    max: data.sdP3y,
    title: { text: data.yLevelName, ...scaleTitleOptions },
    ticks: Utils.getSDTickOptions(data.yAvg, data.ySd, data.sdM2y, data.sdP2y),
  }
  // Set min/max of x axis to be 3 standard deviations
  options.scales.x = {
    min: data.sdM3x,
    max: data.sdP3x,
    title: { text: data.xLevelName, ...scaleTitleOptions },
    ticks: Utils.getSDTickOptions(data.xAvg, data.xSd, data.sdM2x, data.sdP2x),
  }
  options.plugins.legend = { display: false }
  options.plugins.annotation = {
    annotations: [
      ...Utils.getAvgLineAnnotations(data),
      ...Utils.getSDBoxAnnotations(data),
      ...Utils.getCrosshairAnnotations(data),
    ],
  }
  options.elements.point.backgroundColor = (ctx): string =>
    Utils.getPointColor(ctx, data)

  return options
}

const validateYoudenData = (data: CalculatedYoudenData): string[] => {
  const alerts: string[] = []
  if (!data.myDeviceCoordinates.y)
    alerts.push(
      `No ${data.yLevelName} average found for your device (${data.myDeviceId}) so it is not shown on this chart.`
    )
  if (!data.myDeviceCoordinates.x)
    alerts.push(
      `No ${data.xLevelName} average found for your device (${data.myDeviceId}) so it is not shown on this chart.`
    )
  return alerts
}

const Controller = {
  calculateYoudenData,
  getYoudenChartData,
  getYoudenOptions,
  validateYoudenData,
}

export default Controller
