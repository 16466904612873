import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import { useHistory } from 'react-router-dom'

import FormattedPhone from 'js/components/FormattedPhone'

import { Organization } from 'js/models'
// prettier-ignore
import { listOrganizations, listOrganizationsParams } from 'js/services/organization'

import Paths from 'js/utils/paths'
import Loader from '../Loader'
import PaginationBar from '../PaginationBar'
import SearchBar from '../SearchBar'

export default function AdminOrganizationTable(props: {
  listParams?: listOrganizationsParams
  order?: string
}): React.ReactElement {
  const { listParams, order } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [organizations, setOrganizations] = useState<Organization[]>()
  const [searchString, setSearchString] = useState('')
  const history = useHistory()

  const inlineAddress = (org: Organization): string => {
    const lines: string[] = []
    if (org.addressLine2) lines.push(org.addressLine2)
    if (org.addressLine3) lines.push(org.addressLine3)
    return lines.join(', ')
  }

  const search = (s: string): void => {
    setCurrentPage(1)
    setSearchString(s)
  }

  useEffect(() => {
    listOrganizations({
      ...listParams,
      page: currentPage,
      order: order || 'name',
      search: searchString,
    }).then(r => {
      setOrganizations(r.results)
      setTotalPages(r.pageTotal)
    })
  }, [listParams, currentPage, order, searchString])

  const renderTableRow = (organization: Organization): React.ReactElement => {
    return (
      <tr
        className='clickable'
        onClick={(): void => {
          history.push(Paths.getAdminOrganizationDetailLink(organization.id))
        }}
        key={organization.id}
      >
        <td>{organization.name}</td>
        <td>
          <FormattedPhone phone={organization.phoneNumber || '-'} />
        </td>
        <td>{inlineAddress(organization) || '-'}</td>
      </tr>
    )
  }

  if (!organizations) return <Loader />

  return (
    <>
      <div className='table-top-bar'>
        <div className='table-top-section'>
          <SearchBar
            label={'Search'}
            placeholder={'Search by name, phone, or address'}
            doSearch={search}
          />
        </div>
      </div>

      {organizations.length == 0 ? (
        <p>No organizations to display</p>
      ) : (
        <>
          <Table striped hover className='table-header-primary'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>{organizations.map(user => renderTableRow(user))}</tbody>
          </Table>
          <PaginationBar
            pageTotal={totalPages}
            currentPage={currentPage}
            clickPage={setCurrentPage}
          />
        </>
      )}
    </>
  )
}
