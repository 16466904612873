import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

export default function Loader(): React.ReactElement {
  return (
    <div className='row'>
      <Spinner className='mx-auto' animation='grow' variant='primary' />
    </div>
  )
}
