import React from 'react'
import ErrorBarChart from 'js/components/charts/ErrorBarChart'
import LeveyJenningsChart from 'js/components/charts/LeveyJenningsChart'
import YoudenChart from 'js/components/charts/YoudenChart'
import { YoudenChartProps } from 'js/components/charts/YoudenChart/models'
import LotReportTable from 'js/components/tables/LotReportTable'
import MonthReportTable from 'js/components/tables/MonthReportTable'
import StatisticalReportTable from 'js/components/tables/StatisticalReportTable'
// prettier-ignore
import { CompleteDeviceReport, MappedReportData, YoudenData } from "js/services/report-models"
import { findByLevelName } from 'js/utils/helpers'

export interface ReportContent {
  monthReportTable: React.ReactElement<typeof MonthReportTable>
  errorBarCharts: Array<React.ReactElement<typeof ErrorBarChart>>
  leveyJenningsCharts: Array<React.ReactElement<typeof LeveyJenningsChart>>
  youdenChart: React.ReactElement<typeof YoudenChart> | void
  statisticalReportTable: React.ReactElement<typeof StatisticalReportTable>
  lotReportTable: React.ReactElement<typeof LotReportTable>
}

export const mapData = (report: CompleteDeviceReport): MappedReportData => {
  const { detailedGroup, detailedDevice } = report
  const md = {} as MappedReportData
  const levelName = detailedGroup.map(x => x.levelName) || []
  levelName.forEach(ln => {
    md[ln] = {
      deviceData: findByLevelName(detailedDevice, ln),
      groupData: findByLevelName(detailedGroup, ln),
    }
  })
  return md
}

export const renderYoudenChart = (
  data: YoudenData,
  mappedData: MappedReportData
): React.ReactElement | void => {
  const levelNames = Object.keys(mappedData)
  if (levelNames.length !== 2) return

  /**
   * Original Youden plot followed this pattern:
   * Abnormal = X axis
   * Normal   = Y axis
   *
   * But if we have 2 levels and are missing either 'Normal' or
   * 'Abnormal' we'll just assign the two levels to the axis
   */
  const youdenProps = { reportData: data } as YoudenChartProps
  let xKey = 'Abnormal'
  let yKey = 'Normal'

  if (!levelNames.includes(xKey) || !levelNames.includes(yKey)) {
    xKey = levelNames[0]
    yKey = levelNames[1]
  }

  youdenProps.xLevelName = xKey
  youdenProps.xSd = mappedData[xKey].groupData.groupStandardDeviation
  youdenProps.xAvg = mappedData[xKey].groupData.groupAverage

  youdenProps.yLevelName = yKey
  youdenProps.ySd = mappedData[yKey].groupData.groupStandardDeviation
  youdenProps.yAvg = mappedData[yKey].groupData.groupAverage

  return <YoudenChart {...youdenProps} />
}
