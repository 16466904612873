import React, { useEffect, useState } from 'react'

import Loader from 'js/components/Loader'

import { LotGroup, Device, Control } from 'js/models'
import { getControlsByLots } from 'js/services/controls'

import DetailViewTabs from './DetailViewTabs'

export default function DetailView(props: {
  lotGroup: LotGroup
  device: Device
}): React.ReactElement {
  const { lotGroup, device } = props

  const [controls, setControls] = useState<Control[]>()

  useEffect(() => {
    if (lotGroup) {
      getControlsByLots(lotGroup.lots).then(r => setControls(r))
    }
  }, [props])

  if (!(lotGroup && controls && device)) return <Loader />

  return (
    <div>
      <DetailViewTabs controls={controls} {...props} />
    </div>
  )
}
