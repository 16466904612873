import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import { Device, Organization, Site } from 'js/models'
import { FormFeedback, MAX_REQUEST_SIZE } from 'js/services/api'
import { listOrganizations } from 'js/services/organization'
import { createSite } from 'js/services/site'
import { getDataFromForm } from 'js/utils/helpers'

export default function CreateSiteModal(props: {
  show: boolean
  handleClose: () => void
  orgId?: number
  refreshSites?: () => void
}): React.ReactElement {
  const { show, handleClose, orgId, refreshSites } = props
  const [organizationList, setOrganizationList] = useState<Organization[]>([])
  const [errors, setErrors] = useState({} as FormFeedback<Site>)

  const resetFormFeedback = (): void => setErrors({} as FormFeedback<Site>)

  useEffect(() => {
    listOrganizations({ count: MAX_REQUEST_SIZE }).then(r =>
      setOrganizationList(r.results)
    )
  }, [])

  const onSave = (e: React.FormEvent): void => {
    e.preventDefault()
    resetFormFeedback()

    const data = getDataFromForm(
      e.target,
      ['organization', 'name', 'addressLine1', 'addressLine2', 'addressLine3'],
      'siteForm_'
    )

    createSite(data as Partial<Device>)
      .then(() => {
        handleClose()
        if (refreshSites) refreshSites()
        else location.reload()
      })
      .catch(e => setErrors(e.formErrors))
    // Lesser TODO: Show saved successfull message
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop='static' scrollable>
      <Form onSubmit={onSave}>
        <Modal.Header>
          <Modal.Title>Create New Site</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId='siteForm_organization'>
            <Form.Label>Organization</Form.Label>
            <Form.Control
              as='select'
              className={orgId != undefined ? 'cursor-auto' : ''}
              defaultValue={orgId != undefined ? orgId : 'default'}
              disabled={orgId != undefined}
              autoComplete='off'
              isInvalid={!!errors.organization}
            >
              <option disabled value='default'>
                {' -- Select an Organization -- '}
              </option>
              {organizationList
                .sort((a, b) => {
                  return a.name < b.name ? -1 : 1
                })
                .map(org => (
                  <option key={`org-${org.id}`} value={org.id}>
                    {org.name}
                  </option>
                ))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {errors.organization}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='siteForm_name'>
            <Form.Label>Site Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Site Name'
              autoComplete='off'
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Label>Physical Address</Form.Label>
          <Form.Group controlId='siteForm_addressLine1'>
            <Form.Label hidden>Address Line 1</Form.Label>
            <Form.Control
              type='text'
              placeholder='Line 1'
              isInvalid={!!errors.addressLine1}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.addressLine1}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='siteForm_addressLine2'>
            <Form.Label hidden>Address Line 2</Form.Label>
            <Form.Control
              type='text'
              placeholder='Line 2'
              isInvalid={!!errors.addressLine2}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.addressLine2}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='siteForm_addressLine3'>
            <Form.Label>Country</Form.Label>
            <Form.Control
              type='text'
              placeholder='Country'
              isInvalid={!!errors.addressLine3}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.addressLine3}
            </Form.Control.Feedback>
          </Form.Group>
          <p className='text-danger'>{errors.nonFieldErrors}</p>
          <p className='text-danger'>{errors.detail}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={(): void => {
              resetFormFeedback()
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' type='submit'>
            Create Site
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
