import React, { useEffect, useState } from 'react'
import Badge from 'react-bootstrap/Badge'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import AdminOrganizationTable from 'js/components/tables/AdminOrganizationTable'

import { listOrganizations } from 'js/services/organization'
import { ApprovedStatus } from 'js/utils/constants'

export default function AdminOrganizations(): React.ReactElement {
  const [numPending, setNumPending] = useState(0)

  useEffect(() => {
    listOrganizations({ approvedStatus: ApprovedStatus.pending }).then(r =>
      setNumPending(r.itemTotal)
    )
  }, [])

  return (
    <div>
      <h1>Organizations</h1>
      <Tabs id='organizations-tabs'>
        <Tab
          title={
            <React.Fragment>
              Pending Approval
              {numPending > 0 && (
                <Badge className='ms-2 bg-danger'>{numPending}</Badge>
              )}
            </React.Fragment>
          }
          eventKey='pending'
        >
          <AdminOrganizationTable
            listParams={{ approvedStatus: ApprovedStatus.pending }}
          />
        </Tab>

        <Tab title='Approved' eventKey='approved'>
          <AdminOrganizationTable
            listParams={{ approvedStatus: ApprovedStatus.approved }}
          />
        </Tab>

        <Tab title='Denied' eventKey='denied'>
          <AdminOrganizationTable
            listParams={{ approvedStatus: ApprovedStatus.denied }}
          />
        </Tab>
      </Tabs>
    </div>
  )
}
