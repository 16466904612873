import React, { ChangeEvent, useEffect, useState } from 'react'
import _debounce from 'lodash/debounce'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'

import Loader from 'js/components/Loader'

import { Control, DeviceType, Lot, LotDeviceValues } from 'js/models'
import { listDeviceTypes } from 'js/services/deviceType'

import {
  bulkLotDeviceValues,
  listLotDeviceValues,
  partialUpdateLotDeviceValues,
} from 'js/services/lotDeviceValues'

export default function DeviceLotValuesTable(props: {
  control: Control
  lot: Lot
}): React.ReactElement {
  const { control, lot } = props
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>()
  const [expectedValuesList, setExpectedValuesList] = useState<
    LotDeviceValues[]
  >([])
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    listDeviceTypes({ ids: control.deviceTypes.join(',') }).then(r =>
      setDeviceTypes(r.results)
    )
  }, [])

  useEffect(() => {
    if (deviceTypes)
      listLotDeviceValues({
        lot: lot.id,
        device__in: deviceTypes.map(dt => dt.id).join(','),
      }).then(existingValues => {
        // If there are any device types that do not have corresponding expected values, create them
        const missingValues: Partial<LotDeviceValues>[] = []
        deviceTypes.forEach(dt => {
          if (!existingValues.results.some(ev => ev.device === dt.id))
            missingValues.push({
              device: dt.id,
              lot: lot.id,
              expectedMean: 0,
              expectedHigh: 0,
              expectedLow: 0,
            })
        })
        if (missingValues.length > 0)
          bulkLotDeviceValues(missingValues).then(newValues =>
            setExpectedValuesList(
              existingValues.results.concat(newValues as LotDeviceValues[])
            )
          )
        else setExpectedValuesList(existingValues.results)
      })
  }, [deviceTypes])

  type saveParams = {
    lotDeviceValuesId: number
    newValues: Partial<LotDeviceValues>
    event: ChangeEvent<HTMLInputElement>
  }

  const debounceSave: (params: saveParams) => null = _debounce(
    (params: saveParams) => {
      setIsSaving(true)
      partialUpdateLotDeviceValues(params.lotDeviceValuesId, params.newValues)
        .then(() => params.event.target.classList.remove('is-invalid'))
        .catch(() => params.event.target.classList.add('is-invalid'))
        .finally(() => setIsSaving(false))
    },
    500
  )

  const save = (params: saveParams): void => {
    setIsSaving(true)
    params.event.target.classList.remove('is-invalid')
    debounceSave(params)
  }

  if (!deviceTypes || !expectedValuesList) return <Loader />

  const renderTableRow = (
    expectedValue: LotDeviceValues
  ): React.ReactElement => {
    const deviceType = deviceTypes.find(dt => expectedValue.device === dt.id)

    if (!deviceType) return <></>

    return (
      <tr key={deviceType.id}>
        <td>{deviceType.name}</td>
        <td>
          <Form.Control
            type='number'
            defaultValue={expectedValue.expectedMean}
            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
              save({
                event: e,
                lotDeviceValuesId: expectedValue.id,
                newValues: { expectedMean: parseInt(e.target.value) },
              })
            }
          />
        </td>
        <td>
          <Form.Control
            type='number'
            defaultValue={expectedValue.expectedLow}
            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
              save({
                event: e,
                lotDeviceValuesId: expectedValue.id,
                newValues: { expectedLow: parseInt(e.target.value) },
              })
            }
          />
        </td>
        <td>
          <Form.Control
            type='number'
            defaultValue={expectedValue.expectedHigh}
            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
              save({
                event: e,
                lotDeviceValuesId: expectedValue.id,
                newValues: { expectedHigh: parseInt(e.target.value) },
              })
            }
          />
        </td>
        <td>
          <Form.Control
            readOnly
            type='number'
            defaultValue={expectedValue.expectedStandardDeviation}
          />
        </td>
      </tr>
    )
  }

  const expectedValueDeviceName = (expectedValue: LotDeviceValues): string =>
    deviceTypes.find(dt => expectedValue.device === dt.id)?.name ?? ''

  const expectedValueDeviceOrder = (expectedValue: LotDeviceValues): number =>
    deviceTypes.find(dt => expectedValue.device === dt.id)?.order ?? 0

  return (
    <>
      <div
        className={`badge mb-2 px-3 ${isSaving ? 'bg-warning' : 'bg-primary'}`}
      >
        {isSaving ? 'Saving...' : 'Saved'}
      </div>
      <Table striped className='table-header-primary'>
        <thead>
          <tr>
            <th>Device</th>
            <th>Mean</th>
            <th>Low</th>
            <th>High</th>
            <th>
              <span className='d-none d-md-block'>Standard Deviation</span>
              <span className='d-md-none'>SD</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {expectedValuesList
            .sort((a, b) =>
              expectedValueDeviceOrder(a) < expectedValueDeviceOrder(b) ? -1 : 1
            )
            .map(ev => renderTableRow(ev))}
        </tbody>
      </Table>
    </>
  )
}
