import React from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import { LotGroup } from 'js/models'

export const noLots = (
  updateLotGroup: (data: Partial<LotGroup>) => void
): React.ReactElement => {
  return (
    <Alert variant='warning' className='mb-4'>
      <Alert.Heading>No Lots</Alert.Heading>
      <p>
        Organizations have access to this lot group, but it currently has no
        lots. Unpublish and close this lot group until it has lots available.
      </p>
      <hr />
      <div className='d-flex justify-content-end'>
        <Button
          variant='warning'
          onClick={(): void =>
            updateLotGroup({ active: false, published: false })
          }
        >
          Unpublish and Close
        </Button>
      </div>
    </Alert>
  )
}

export const unpublishedInactiveAlert = (
  updateLotGroup: (data: Partial<LotGroup>) => void
): React.ReactElement => {
  return (
    <Alert variant='warning' className='mb-4'>
      <Alert.Heading>Unpublished Lot Group</Alert.Heading>
      <p>
        Organizations cannot view this lot group&apos;s data or reports, and
        cannot submit new data.
      </p>
      <hr />
      <div className='d-flex justify-content-end'>
        <Button
          variant='warning'
          onClick={(): void =>
            updateLotGroup({ active: true, published: true })
          }
        >
          Publish
        </Button>
      </div>
    </Alert>
  )
}

export const unpublishedInactiveExpiredAlert = (): React.ReactElement => {
  return (
    <Alert variant='warning' className='mb-4'>
      <Alert.Heading>Unpublished Expired Lot Group</Alert.Heading>
      <p>
        This lot group has expired, but organizations cannot view its data or
        reports.
      </p>
    </Alert>
  )
}

export const unpublishedActiveAlert = (
  updateLotGroup: (data: Partial<LotGroup>) => void
): React.ReactElement => {
  return (
    <Alert variant='warning' className='mb-4'>
      <Alert.Heading>Unpublished Active Lot Group</Alert.Heading>
      <p>
        This lot group is hidden from control runs, but organizations can still
        submit data to it.
      </p>
      <hr />
      <div className='d-flex justify-content-end'>
        <Button
          variant='warning'
          onClick={(): void =>
            updateLotGroup({ active: false, published: false })
          }
        >
          Close Submissions
        </Button>
        <Button
          variant='warning'
          className='ms-3'
          onClick={(): void =>
            updateLotGroup({ active: true, published: true })
          }
        >
          Publish
        </Button>
      </div>
    </Alert>
  )
}

export const publishedInactiveAlert = (): React.ReactElement => {
  return (
    <Alert variant='warning' className='mb-4'>
      <Alert.Heading>Closed Lot Group</Alert.Heading>
      <p>
        Organizations can view this lot group, but not submit new data to it.
        Lot groups are automatically closed 30 days after one of its lots
        expire.
      </p>
    </Alert>
  )
}
