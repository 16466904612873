import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { useHistory } from 'react-router-dom'

import FormattedAddress from 'js/components/FormattedAddress'
import Loader from 'js/components/Loader'

import { Organization } from 'js/models'
import { retrieveByOrganizationCode } from 'js/services/organization'
import Paths from 'js/utils/unauthorizedPaths'

import { StepContentProps, Steps } from '.'

interface COProps extends StepContentProps {
  organizationCode: string
}

export default function ConfirmOrganization(
  props: COProps
): React.ReactElement {
  const history = useHistory()
  const [data, setData] = useState({} as Organization)
  const [receivedResponse, setReceivedResponse] = useState(false)

  useEffect(() => {
    retrieveByOrganizationCode(props.organizationCode)
      .then(r => {
        setData(r)
      })
      .finally(() => setReceivedResponse(true))
  }, [])

  const onBack = (e): void => {
    e.preventDefault()
    history.push(Paths.bareRegistrationLink)
    props.setStep(Steps.FindOrganization)
  }

  return (
    <Card className='unauth-card'>
      <Card.Header>Confirm Organization</Card.Header>
      <Card.Body className='text-center'>
        {receivedResponse ? (
          data.name ? (
            <FormattedAddress data={data} />
          ) : (
            <div className='text-danger'>Invalid Organization Code</div>
          )
        ) : (
          <Loader />
        )}
        <div className='d-flex justify-content-around mt-4'>
          <Button variant='outline-primary' onClick={onBack}>
            &larr;&nbsp;&nbsp;Back
          </Button>
          <Button
            disabled={!data.name}
            variant='primary'
            onClick={(): void => props.setStep(Steps.CreateUser)}
          >
            Next&nbsp;&nbsp;&rarr;
          </Button>
        </div>
      </Card.Body>
    </Card>
  )
}
