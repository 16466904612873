import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import { Link } from 'react-router-dom'

import { HelpKeys } from 'js/containers/Help/helpContent'

import { Login } from 'js/models'
import { FormFeedback } from 'js/services/api'
import { SignIn } from 'js/services/auth'
import Paths from 'js/utils/unauthorizedPaths'

export default function SignInForm(): React.ReactElement {
  const [errors, setErrors] = useState({} as FormFeedback<Login>)

  const resetFormFeedback = (): void => setErrors({} as FormFeedback<Login>)

  const onSubmit = (e): void => {
    e.preventDefault()
    resetFormFeedback()

    const rememberMe = e.target.elements.signInForm_rememberMe.checked
    const email = e.target.elements.signInForm_email.value
    const password = e.target.elements.signInForm_password.value

    if (rememberMe) localStorage.setItem('elitechUser', email)
    else localStorage.removeItem('elitechUser')

    SignIn(email, password)
      .then(() => (window.location.href = '/'))
      .catch(e => setErrors(e.formErrors))
  }

  const doesRemember = (): boolean => !!localStorage.getItem('elitechUser')
  const rememberedUser = (): string | null =>
    localStorage.getItem('elitechUser')

  return (
    <Form onSubmit={onSubmit}>
      <Card className='unauth-card'>
        <Card.Header>VQC Sign In</Card.Header>
        <Card.Body>
          <Form.Group controlId='signInForm_email'>
            <Form.Label className='visually-hidden'>Email</Form.Label>
            <Form.Control
              type='text'
              autoComplete='username'
              placeholder='Email'
              isInvalid={!!errors.email}
              defaultValue={rememberedUser() || ''}
              autoFocus={!doesRemember()}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='signInForm_password'>
            <Form.Label className='visually-hidden'>Password</Form.Label>
            <Form.Control
              type='password'
              autoComplete='current-password'
              placeholder='Password'
              isInvalid={!!errors.password}
              autoFocus={doesRemember()}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>
          <p className='text-danger'>{errors.nonFieldErrors}</p>
          <div className='d-flex justify-content-between mt-4'>
            <Form.Group
              controlId='signInForm_rememberMe'
              className='align-self-center m-0'
            >
              <Form.Check
                type='checkbox'
                label='Remember Me'
                defaultChecked={doesRemember()}
              />
            </Form.Group>
            <Button
              variant='primary'
              type='submit'
              className='align-self-center w-50 ml-auto'
            >
              Sign In
            </Button>
          </div>
          <hr className='mt-4' />
          <div className='d-flex justify-content-around'>
            <Link to={Paths.bareRegistrationLink}>Create Account</Link>
            <Link to={Paths.passwordReset}>Reset Password</Link>
            <Link to={Paths.getHelpSectionLink(HelpKeys.contact)}>Help</Link>
          </div>
          <div className='d-flex justify-content-around'>
             <p><br/>Thanks for joining us.</p>
          </div>
          <div className='d-flex justify-content-around'>
             <p>If this is the first time visiting by anyone in your organization, please register to get started.</p>
          </div>
        </Card.Body>
      </Card>
    </Form>
  )
}
