// prettier-ignore
import { CreateRegisterUserResponse, PasswordChange, CreatePassword, PasswordReset, PasswordResetConfirm, User } from 'js/models'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'
// prettier-ignore
import api, { BaseQueryParams, FormError, FormFeedback, FormPromise, Paginated } from './api'

export interface listUsersParams extends BaseQueryParams {
  organization?: number
  organization__name__icontains?: string
  name__icontains?: string
  email__icontains?: string
}

/*=============================================
=                     GET                     =
=============================================*/
export const getSelf = (): Promise<User> => {
  return api.get('/auth/user/').then(r => makeCamelCase(r.data))
}

export const getUser = (id: number): Promise<User> => {
  return api.get(`/users/${id}/`).then(r => makeCamelCase(r.data))
}

export const listUsers = (
  params?: listUsersParams
): Promise<Paginated<User>> => {
  return api
    .get('/users/', { params: makeSnakeCase(params) })
    .then(r => makeCamelCase(r.data))
}

/*=============================================
=                    POST                     =
=============================================*/
export const createRegisterUser = (
  data: Partial<User>
): Promise<
  CreateRegisterUserResponse | FormError<CreateRegisterUserResponse>
> => {
  return api
    .post('/auth/registration/', makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as CreateRegisterUserResponse)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

export const changePasswordSelf = (
  data: PasswordChange
): Promise<FormFeedback<User> | FormError<User>> => {
  // Not using makeSnakeCase because it puts an underscore before the number
  return api
    .post('/auth/password/change/', {
      old_password: data.oldPassword,
      new_password1: data.newPassword1,
      new_password2: data.newPassword2,
    })
    .then(r => makeCamelCase(r.data) as FormFeedback<User>)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

export const changePasswordAdmin = (
  user: User,
  data: CreatePassword
): Promise<FormFeedback<User> | FormError<User>> => {
  return api
    .patch(`/users/${user.id}/set_password/`, makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as FormFeedback<User>)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

export const resetPassword = (data: PasswordReset): FormPromise<User> => {
  return api
    .post('/auth/password/reset/', makeCamelCase(data))
    .then(r => makeCamelCase(r.data) as User)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

export const resetPasswordConfirm = (
  data: PasswordResetConfirm
): FormPromise<User> => {
  return api
    .post('/auth/password/reset/confirm/', {
      new_password1: data.newPassword1,
      new_password2: data.newPassword2,
      token: data.token,
      uid: data.uid,
    })
    .then(r => makeCamelCase(r.data) as User)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                    PATCH                    =
=============================================*/
export const partialUpdateSelf = (
  data: Partial<{
    name: string
    email: string
    sites: number[]
    organization: number | null
    newOrganization: string // company code
  }>
): Promise<User> => {
  return api
    .patch('/auth/user/', makeSnakeCase(data))
    .then(r => makeCamelCase(r))
}

export const partialUpdateUser = (
  id: number,
  data: Partial<User>
): FormPromise<User> => {
  return api
    .patch(`/users/${id}/`, makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as User)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}
