import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'

import { Device, DeviceType, Organization, Site } from 'js/models'
import { FormFeedback, MAX_REQUEST_SIZE } from 'js/services/api'
import { createDevice } from 'js/services/device'
import { listDeviceTypes } from 'js/services/deviceType'
import { getOrganization, listOrganizations } from 'js/services/organization'
import { listSites } from 'js/services/site'
import { getDataFromForm } from 'js/utils/helpers'

interface RegisterDeviceFormFields {
  serialNumber: string
  label: string
  site: string
  deviceType: string
}

export default function RegisterDeviceModal(props: {
  show: boolean
  handleClose: () => void
  orgId?: number
  refreshDevices?: () => void
  numSites?: number
}): React.ReactElement {
  const { show, handleClose, orgId, refreshDevices, numSites } = props
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([])
  const [organizationList, setOrganizationList] = useState<Organization[]>([])
  const [sites, setSites] = useState<Site[]>([])
  const [selectedOrg, setSelectedOrg] = useState(orgId)
  const [errors, setErrors] = useState(
    {} as FormFeedback<RegisterDeviceFormFields>
  )

  useEffect(() => {
    listDeviceTypes({ count: MAX_REQUEST_SIZE, order: 'name' }).then(r =>
      setDeviceTypes(r.results)
    )
    listOrganizations({ count: MAX_REQUEST_SIZE, order: 'name' }).then(r =>
      setOrganizationList(r.results)
    )
  }, [])

  useEffect(() => {
    if (selectedOrg) {
      listSites({ organization: selectedOrg, order: 'name' }).then(r =>
        setSites(r.results)
      )
    }
  }, [selectedOrg, numSites])

  const resetFormFeedback = (): void =>
    setErrors({} as FormFeedback<RegisterDeviceFormFields>)

  const selectOrganization = (event): void => {
    getOrganization(event.target.value).then(r => {
      setSelectedOrg(r.id)
      ;(document.querySelector('#deviceForm_site') as HTMLInputElement).value =
        'default'
    })
  }

  const onSave = (e: React.FormEvent): void => {
    e.preventDefault()
    resetFormFeedback()

    const data: Partial<RegisterDeviceFormFields> = getDataFromForm(
      e.target,
      ['serialNumber', 'label', 'site', 'deviceType'],
      'deviceForm_'
    )

    if (data.deviceType?.toString() === 'default') data.deviceType = ''

    if (data.site?.toString() === 'default') data.site = ''

    createDevice(data as Partial<Device>)
      .then(() => {
        handleClose()
        if (refreshDevices) refreshDevices()
        else location.reload()
      })
      .catch(e => setErrors(e.formErrors))
    // Lesser TODO: Show saved successfull message
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop='static'
      size='lg'
      scrollable
    >
      <Form onSubmit={onSave}>
        <Modal.Header>
          <Modal.Title>Register Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group controlId='deviceForm_serialNumber'>
                <Form.Label>Serial Number</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Serial Number'
                  isInvalid={!!errors.serialNumber}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.serialNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='deviceForm_label'>
                <Form.Label>Label</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Label'
                  isInvalid={!!errors.label}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.label}
                </Form.Control.Feedback>
                <Form.Text className='text-muted'>
                  Device labels help distinguish them in lists
                </Form.Text>
              </Form.Group>
              <Form.Group controlId='deviceForm_deviceType'>
                <Form.Label>Model</Form.Label>
                <Form.Control
                  as='select'
                  defaultValue='default'
                  isInvalid={!!errors.deviceType}
                >
                  <option disabled value='default'>
                    {' -- Select a Model -- '}
                  </option>
                  {deviceTypes
                    .sort((a, b) => {
                      return a.name < b.name ? -1 : 1
                    })
                    .map(type => (
                      <option key={`device-type-${type.id}`} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.deviceType}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='deviceForm_organization'>
                <Form.Label>Organization</Form.Label>
                <Form.Control
                  as='select'
                  className={orgId != undefined ? 'cursor-auto' : ''}
                  defaultValue={orgId != undefined ? orgId : 'default'}
                  onChange={selectOrganization}
                  disabled={orgId != undefined}
                >
                  <option disabled value='default'>
                    {' -- Select an Organization -- '}
                  </option>
                  {organizationList
                    .sort((a, b) => {
                      return a.name < b.name ? -1 : 1
                    })
                    .map(org => (
                      <option key={`org-${org.id}`} value={org.id}>
                        {org.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group
                controlId='deviceForm_site'
                hidden={selectedOrg === null}
              >
                <Form.Label>Site</Form.Label>
                <Form.Control
                  as='select'
                  className={selectedOrg === null ? '' : 'cursor-auto'}
                  defaultValue={'default'}
                  disabled={selectedOrg === null}
                  isInvalid={!!errors.site}
                >
                  <option disabled value='default'>
                    {' -- Select a Site -- '}
                  </option>
                  {sites
                    .sort((a, b) => {
                      return a.name < b.name ? -1 : 1
                    })
                    .map(site => (
                      <option key={`site-${site.id}`} value={site.id}>
                        {site.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <p className='text-danger'>{errors.site}</p>
            </Col>
          </Row>
          <p className='text-danger'>{errors.nonFieldErrors}</p>
          <p className='text-danger'>{errors.detail}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={(): void => {
              setSelectedOrg(undefined)
              resetFormFeedback()
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' type='submit'>
            Register
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
