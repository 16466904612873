import React from 'react'
import { AddressInfo } from 'js/models'

export default function FormattedAddress(props: {
  data: AddressInfo
}): React.ReactElement {
  const { name, addressLine1, addressLine2, addressLine3 } = props.data
  return (
    <address className='mb-0'>
      {name}
      <br />
      {addressLine1 && (
        <>
          {addressLine1}
          <br />
        </>
      )}
      {addressLine2 && (
        <>
          {addressLine2}
          <br />
        </>
      )}
      {addressLine3 && <>{addressLine3}</>}
    </address>
  )
}
