import React from 'react'
import { round } from 'js/utils/helpers'
// prettier-ignore
import { TableRowConfig, Dataset, TABLE_CONFIG } from './month-report-table-utils'
import { MappedReportData } from 'js/services/report-models'

export default function MonthReportTable(props: {
  data: MappedReportData
}): React.ReactElement {
  const levelNames = Object.keys(props.data)

  const getStrFromTableRowData = (
    dataset: Dataset,
    tr: TableRowConfig,
    level: string
  ): string => {
    let val: string | number = 0
    if (tr.attr) val = dataset[tr.attr]
    if (tr.callback) val = tr.callback(dataset)
    if (tr.propsCallback) val = tr.propsCallback(props.data, level)
    if (typeof val == 'number') val = round(val as number, 2)
    if (val === undefined) return '-'
    return val.toString()
  }

  const renderTableRow = (trc: TableRowConfig): React.ReactElement => (
    <tr key={trc.label + '_report_table_row'}>
      <th scope='row'>
        <span className='nowrap'>{trc.label}</span>
      </th>
      {levelNames.map(ln => (
        <td key={`${trc.label}-${ln}-td`}>
          {getStrFromTableRowData(props.data[ln][trc.dataset], trc, ln)}
        </td>
      ))}
    </tr>
  )

  return (
    <div className='report-table-wrapper'>
      <table className='table table-header-primary table-striped w-auto'>
        <thead>
          <tr>
            <th></th>
            {levelNames.map(ln => (
              <th key={ln + '-mrt-th'}>{ln}</th>
            ))}
          </tr>
        </thead>
        <tbody>{TABLE_CONFIG.map(tr => renderTableRow(tr))}</tbody>
      </table>
    </div>
  )
}
