import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import { US_STATE_CODES } from 'js/utils/constants'

interface NOFProps {
  onSubmit: (e) => void
  onBack: (e?) => void
}

export default function NewOrganizationForm(
  props: NOFProps
): React.ReactElement {
  const [international, setInternational] = useState(false)

  return (
    <Form onSubmit={props.onSubmit}>
      {/* Organization Name */}
      <Form.Group controlId='newOrganizationForm_name'>
        <Form.Label>Organization Name</Form.Label>
        <Form.Control
          type='text'
          autoComplete='organization'
          placeholder='Organization Name'
          required
        />
      </Form.Group>
      <Form.Group controlId='newOrganizationForm_phoneNumber'>
        <Form.Label>Organization Phone Number</Form.Label>
        <Form.Control
          type='tel'
          autoComplete='tel'
          placeholder='Phone'
          required
        />
      </Form.Group>
      {/* Address */}
      <Form.Group controlId='newOrganizationForm_streetAddress'>
        <Form.Label>Address</Form.Label>
        <Form.Control
          type='text'
          autoComplete='address-line1'
          placeholder={international ? 'Address Line 1' : 'Street Address'}
          required
        />
      </Form.Group>
      <Form.Group
        controlId='newOrganizationForm_addressLine2'
        hidden={!international}
      >
        <Form.Label hidden>Address Line 2</Form.Label>
        <Form.Control
          type='text'
          autoComplete='address-line2'
          placeholder='Address Line 2'
        />
      </Form.Group>
      <Row>
        <Form.Group
          as={Col}
          controlId='newOrganizationForm_city'
          hidden={international}
        >
          <Form.Label className='visually-hidden'>City</Form.Label>
          <Form.Control
            type='text'
            autoComplete='address-level2'
            placeholder='City'
          />
        </Form.Group>
        <Form.Group
          as={Col}
          controlId='newOrganizationForm_state'
          hidden={international}
        >
          <Form.Label className='visually-hidden'>State</Form.Label>
          <Form.Control
            as='select'
            defaultValue='default'
            autoComplete='address-level2'
          >
            <option value='default' disabled>
              {' -- Select a State -- '}
            </option>
            {US_STATE_CODES.map(state => (
              <option key={`state-option-${state.value}`} value={state.value}>
                {state.value} - {state.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId='newOrganizationForm_zip'
          hidden={international}
          md='3'
        >
          <Form.Label className='visually-hidden'>ZIP Code</Form.Label>
          <Form.Control
            type='text'
            autoComplete='postal-code'
            placeholder='ZIP Code'
          />
        </Form.Group>
      </Row>
      <Form.Group controlId='newOrganizationForm_country'>
        <Form.Label className='visually-hidden'>Country</Form.Label>
        <Form.Control
          type='text'
          autoComplete='country-name'
          placeholder='Country'
          defaultValue='United States'
          disabled={!international}
          required
        />
      </Form.Group>
      <Form.Group controlId='newOrganizationForm_international'>
        <Form.Check
          type='checkbox'
          label='International address'
          defaultChecked={false}
          onChange={(e): void => {
            setInternational(e.target.checked)
            ;(
              document.querySelector(
                '#newOrganizationForm_country'
              ) as HTMLInputElement
            ).value = e.target.checked ? '' : 'United States'
          }}
        />
      </Form.Group>
      {/* Phone Number */}
      <div className='d-flex justify-content-around mt-4'>
        <Button variant='outline-primary' onClick={(): void => props.onBack()}>
          &larr;&nbsp;&nbsp;Back
        </Button>
        <Button variant='primary' type='submit'>
          Next&nbsp;&nbsp;&rarr;
        </Button>
      </div>
    </Form>
  )
}
