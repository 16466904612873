// This file is ignored by prettier

import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// Prime Chart.js to use annotation plugin
import { Chart } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
Chart.register(annotationPlugin)

import Footer from 'js/components/Footer'
import Header from 'js/components/Headers/AuthorizedHeader'
import OrganizationDeniedWarn from 'js/components/HelpMessages/OrganizationDeniedWarn'
import Loader from 'js/components/Loader'

import AdminControlTypeDetail from 'js/containers/AdminControlTypeDetail'
import AdminControlTypes from 'js/containers/AdminControlTypes'
import AdminDeviceDetail from 'js/containers/AdminDeviceDetail'
import AdminDevices from 'js/containers/AdminDevices'
import AdminDeviceTypes from 'js/containers/AdminDeviceTypes'
import AdminLotGroupDetail from 'js/containers/AdminLotGroupDetail'
import AdminLotGroups from 'js/containers/AdminLotGroups'
import AdminOrganizationDetail from 'js/containers/AdminOrganizationDetail'
import AdminOrganizations from 'js/containers/AdminOrganizations'
import AdminSiteDetail from 'js/containers/AdminSiteDetail'
import AdminUserDetail from 'js/containers/AdminUserDetail'
import AdminUsers from 'js/containers/AdminUsers'
import ControlRunDetail from 'js/containers/ControlRunDetail'
import ControlRuns from 'js/containers/ControlRuns'
import CorrectiveActionForm from 'js/containers/CorrectiveActionForm'
import Help from 'js/containers/Help'
import PrivacyPolicy from 'js/containers/PrivacyPolicy'
import Profile from 'js/containers/Profile'
import SubmitData from 'js/containers/SubmitData'

import { User } from 'js/models'
import { getSelf } from 'js/services/user'

import { ApprovedStatus } from 'js/utils/constants'
import { SelfUser } from 'js/utils/helpers'
import Paths from 'js/utils/paths'
import VQCPermissions from 'js/utils/permissions'

function AuthorizedMain(): React.ReactElement {
  const [myUser, setMyUser] = useState<User>()

  useEffect(() => {
    getSelf().then(r => setMyUser(r))
  }, [])

  if (!myUser) return <Loader />

  const deniedAccess = myUser.organizationDetails?.approvedStatus == ApprovedStatus.denied && !VQCPermissions.isSuperAdmin(myUser)

  return (
    <Router>
      <SelfUser.Provider value={myUser}>
        <div id='app'>
          <Header />
          <div id='maincontent' className='container'>
            {deniedAccess ? <OrganizationDeniedWarn user={myUser}/> :
            <Switch>
              <Route exact path={Paths.controlRunsTable} component={ControlRuns} />
              <Route exact path={[Paths.controlRunDetail, Paths.controlRunReport]} component={ControlRunDetail} />
              <Route exact path={Paths.profile} component={Profile} />
              <Route exact path={Paths.privacyPolicy} component={PrivacyPolicy} />
              <Route exact path={Paths.submitData} component={SubmitData} />
              <Route exact path={Paths.adminControlTypes} component={AdminControlTypes} />
              <Route exact path={Paths.adminControlTypeDetail} component={AdminControlTypeDetail} />
              <Route exact path={Paths.adminDeviceTypes} component={AdminDeviceTypes} />
              <Route exact path={Paths.adminDevices} component={AdminDevices} />
              <Route exact path={Paths.adminDeviceDetail} component={AdminDeviceDetail} />
              <Route exact path={Paths.adminSiteDetail} component={AdminSiteDetail} />
              <Route exact path={Paths.adminUsers} component={AdminUsers} />
              <Route exact path={Paths.adminUserDetail} component={AdminUserDetail} />
              <Route exact path={Paths.adminLotGroups} component={AdminLotGroups} />
              <Route exact path={Paths.adminLotGroupDetail} component={AdminLotGroupDetail} />
              <Route exact path={Paths.adminOrganizations} component={AdminOrganizations} />
              <Route exact path={Paths.adminOrganizationDetail} component={AdminOrganizationDetail} />
              <Route exact path={Paths.help} component={Help} />
              <Route exact path={Paths.correctiveActionForm} component={CorrectiveActionForm} />
              {/* Default Route */}
              <Route path='/' component={ControlRuns} />
            </Switch>
            }
          </div>
          <Footer />
        </div>
      </SelfUser.Provider>
    </Router>
  )
}

export default AuthorizedMain
