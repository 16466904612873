import React from 'react'
import _camelCase from 'lodash/camelCase'
import _cloneDeep from 'lodash/cloneDeep'
import _isArray from 'lodash/isArray'
import _isPlainObject from 'lodash/isPlainObject'
import _map from 'lodash/map'
import _mapKeys from 'lodash/mapKeys'
import _mapValues from 'lodash/mapValues'
import _mergeWith from 'lodash/mergeWith'
import _pullAt from 'lodash/pullAt'
import _round from 'lodash/round'
import _snakeCase from 'lodash/snakeCase'
import _truncate from 'lodash/truncate'
import { User } from 'js/models'
import { FormErrorArray, FormFeedback } from 'js/services/api'
import { DateTimeStyle, NumericalStyle } from './constants'

export const truncate = (s: string, limit: number): string =>
  _truncate(s, { length: limit, separator: /[,. ]/ })

export function noop(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

// =============================================================================
//  AUTH TOKEN
// =============================================================================\
export const setToken = (token: string): void =>
  localStorage.setItem('token', token)

export const getToken = (): string | null => localStorage.getItem('token')

export const signOut = (): void => {
  localStorage.removeItem('token')
  window.open('/', '_self')
}

// =============================================================================
//  USER CONTEXT
// =============================================================================\
export const SelfUser = React.createContext<User>({} as User)

// =============================================================================
//  CASE CONVERSIONS
// =============================================================================
export function makeCamelCase<IT, OT>(object: IT): OT {
  let camelCaseObject = _cloneDeep(object)

  if (_isArray(camelCaseObject)) {
    return _map(camelCaseObject, makeCamelCase)
  }

  if (!_isPlainObject(camelCaseObject)) {
    return camelCaseObject
  }

  // Convert keys to camel case
  camelCaseObject = _mapKeys(camelCaseObject, (value, key) => {
    return _camelCase(key)
  })

  // Recursively apply throughout object
  return _mapValues(camelCaseObject, value => {
    if (_isPlainObject(value)) {
      return makeCamelCase(value)
    } else if (_isArray(value)) {
      return _map(value, makeCamelCase)
    } else {
      return value
    }
  })
}

export function makeSnakeCase<IT, OT>(object: IT): OT {
  let snakeCaseObject = _cloneDeep(object)

  if (_isArray(snakeCaseObject)) {
    return _map(snakeCaseObject, makeSnakeCase)
  }

  if (!_isPlainObject(snakeCaseObject)) {
    return snakeCaseObject
  }

  // Convert keys to snake case
  snakeCaseObject = _mapKeys(snakeCaseObject, (value, key) => {
    return key.includes('__') ? key : _snakeCase(key)
  })

  // Recursively apply throughout object
  return _mapValues(snakeCaseObject, value => {
    if (_isPlainObject(value)) {
      return makeSnakeCase(value)
    } else if (_isArray(value)) {
      return _map(value, makeSnakeCase)
    } else {
      return value
    }
  })
}

// =============================================================================
//  FORM SUBMISSION
// =============================================================================
export function getDataFromForm<T>(
  formEl: EventTarget, // Form element passed form onSubmit event
  properties: string[], // List of property keys to collect from the form
  formPrefix?: string // controlId prefix for the form
): T {
  const data = <T>{}
  properties.forEach(p => {
    const formKey = (formPrefix || '') + p
    const node = formEl[formKey]
    if (node?.value) data[p] = node.value
    else if (node as NodeListOf<HTMLInputElement>) {
      try {
        data[p] = []
        node.forEach(i => {
          if (i.checked) data[p].push(i.value)
        })
      } catch (e) {
        // TODO: Misty, there is a problem where this crashed with "TypeError: node.forEach is not a function"
        //    if a text field is left empty on a form. Not sure what the correct solution is, so I just
        //    wrapped this in a try-catch
        data[p] = ''
      }
    }
  })
  return data as T
}

export function reduceFromErrorArray<T>(
  errorArr: FormErrorArray<T>
): FormFeedback<T> {
  return _mergeWith(...errorArr.formErrors, (objValue, srcValue) => {
    if (_isArray(objValue)) {
      return objValue.concat(srcValue)
    }
  })
}

// =============================================================================
//  FORMAT DATA FOR DISPLAY
// =============================================================================

export const formatOrganizationCode = (code?: string): string => {
  if (code) return code.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4')
  return ''
}

export const offsetByCurrentTimezone = (d: Date): Date => {
  const timeOffsetInMS: number = d.getTimezoneOffset() * 60000
  d.setTime(d.getTime() + timeOffsetInMS)
  return d
}

export const formatDate = (
  date?: string | Date,
  style = DateTimeStyle.medium
): string => {
  if (!date) return ''
  const dateObj = offsetByCurrentTimezone(
    typeof date === 'string' ? new Date(date) : date
  )
  return dateObj.toLocaleDateString(undefined, { dateStyle: style })
}

export const formatTime = (time?: string | Date): string => {
  if (!time) return ''
  const dateObj = typeof time === 'string' ? new Date(time) : time
  return dateObj.toLocaleTimeString(undefined, {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  })
}

export const formatNumber = (
  number?: number,
  style = NumericalStyle.decimal,
  thousandsSeparator = true
): string => {
  return number
    ? new Number(number).toLocaleString(undefined, {
        style: style,
        useGrouping: thousandsSeparator,
      })
    : ''
}

export const round = (num: number, places = 2): string => _round(num, places)

// =============================================================================
//  CALCULATIONS
// =============================================================================
export const getSDIndex = (
  labMean: number,
  groupMean: number,
  groupSD: number
): number => {
  return (labMean - groupMean) / groupSD
}

// CV = Coefficient of Variance
export const getPeerIndex = (labCV: number, groupCV: number): number => {
  return labCV / groupCV
}

export const getNumDaysInMonth = (year: number, month: number): number =>
  new Date(year, month, 0).getDate()

// =============================================================================
//  ARRAY
// =============================================================================
// prettier-ignore
export function findByLevelName<T extends { levelName: string }>( arr: T[], level: string ): T {
  const ln = arr.find(x => x.levelName.toLowerCase() === level.toLowerCase())
  if (!ln) throw new Error(`No object found with levelName: "${level}"`)
  return ln
}
// prettier-ignore
export function findByLevelNumber<T extends { level: number }>( arr: T[], level: number ): T {
  const l = arr.find(x => x.level === level)
  if (!l) throw new Error(`No object found with level: ${level}`)
  return l
}

// prettier-ignore
export function findById<T extends { id: string | number }>( arr: T[], id: string | number ): T | undefined {
  return arr.find(x => x.id === id)
}

/**
 * @param arr {T[]} array of elements with an 'id' property
 * @param id {number | string} id to match
 * @returns new array with item matching the given id removed
 */
// prettier-ignore
export function removeById<T extends { id: number | string }>( arr: T[], id: number | string ): T[] {
  const index = arr.findIndex(x => x.id === id)
  _pullAt(arr, index)
  return arr
}

// =============================================================================
//  CSV
// =============================================================================
export const downloadCsv = (
  fileName: string,
  data: Array<Array<number | string>>
): void => {
  let csvStr = ''
  data.forEach(row => {
    csvStr += row.join(',')
    csvStr += '\n'
  })
  const a = document.createElement('a')
  a.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvStr)
  a.target = '_blank'
  a.download = `${fileName}.csv`
  a.click()
}
