import { WorkingTimeOptions } from 'js/utils/constants'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'
import api, { FormPromise } from './api'

export interface FileEntryParams {
  lot_group: number | null
  device: number | null
  temperatureCorrected: boolean | null
  workingTime: WorkingTimeOptions | null
  file: File
}

/*=============================================
=                     GET                     =
=============================================*/

/*=============================================
=                    POST                     =
=============================================*/
export const uploadFileEntry = (
  data: FileEntryParams
): FormPromise<FileEntryParams> => {
  data = makeSnakeCase(data)
  const formData = new FormData()
  Object.keys(data).forEach(k => {
    if (data[k] !== null) formData.append(String(k), data[k])
  })
  return api
    .post('/entries/file/', formData)
    .then(r => makeCamelCase(r.data) as FileEntryParams)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                    PATCH                    =
=============================================*/
