import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useParams } from 'react-router-dom'

import InfoPiece from 'js/components/InfoPiece'
import Loader from 'js/components/Loader'

import { LotGroup } from 'js/models'
import { getLotGroup, partialUpdateLotGroup } from 'js/services/lotGroup'
import { formatDate } from 'js/utils/helpers'
import VQCPermissions from 'js/utils/permissions'

import * as Alerts from './Alerts'
import LotTabs from './LotTabs'

export default function AdminLotGroupDetail(): React.ReactElement {
  const [lotGroup, setLotGroup] = useState<LotGroup>()
  const { pk } = useParams<{ pk?: string }>()

  const canEdit = VQCPermissions.canEditElitechData()

  useEffect(() => {
    getLotGroup(parseInt(pk as string)).then(r => setLotGroup(r))
  }, [])

  if (!lotGroup) return <Loader />

  const updateLotGroup = (data: Partial<LotGroup>): void => {
    partialUpdateLotGroup(lotGroup.id, data).then(r => {
      setLotGroup(r)
    })
  }

  const statusWarning = (): React.ReactElement => {
    if (lotGroup.lots.length == 0 && (lotGroup.published || lotGroup.active))
      return Alerts.noLots(updateLotGroup)
    if (lotGroup.lots.length == 0) return <></>
    if (!lotGroup.published && !lotGroup.active)
      if (new Date().getTime() < new Date(lotGroup.expirationDate).getTime()) {
        return Alerts.unpublishedInactiveAlert(updateLotGroup)
      } else {
        return Alerts.unpublishedInactiveExpiredAlert()
      }
    if (!lotGroup.published && lotGroup.active)
      return Alerts.unpublishedActiveAlert(updateLotGroup)
    if (lotGroup.published && !lotGroup.active)
      return Alerts.publishedInactiveAlert()
    return <></>
  }

  return (
    <div>
      <h1>{lotGroup.testName || 'New Lot Group'}</h1>
      {statusWarning()}

      <Row>
        <Col>
          <h2>Lot Group Information</h2>
          <InfoPiece label='Lot Numbers'>
            {lotGroup.lots.map(lot => lot.lotNumber).join(', ') || '–'}
          </InfoPiece>
          <InfoPiece label='Expiration Date'>
            {formatDate(lotGroup.expirationDate) || '–'}
          </InfoPiece>
        </Col>
        {canEdit && (
          <Col>
            <h2>Lot Group Status</h2>
            <InfoPiece label='Published'>
              <Button
                className='w-25'
                size='sm'
                variant={lotGroup.published ? 'primary' : 'danger'}
                onClick={(): void =>
                  updateLotGroup({ published: !lotGroup.published })
                }
              >
                {lotGroup.published ? 'Published' : 'Unpublished'}
              </Button>
            </InfoPiece>
            <InfoPiece label='New Submissions'>
              <Button
                className='w-25'
                size='sm'
                variant={lotGroup.active ? 'primary' : 'danger'}
                onClick={(): void =>
                  updateLotGroup({ active: !lotGroup.active })
                }
              >
                {lotGroup.active ? 'Open' : 'Closed'}
              </Button>
            </InfoPiece>
          </Col>
        )}
      </Row>
      <div className='mt-5'>
        <h2>Lots</h2>
        {lotGroup.lots.length == 0 && !canEdit ? (
          <p>This lot group has no lots.</p>
        ) : (
          <LotTabs lotGroup={lotGroup} setLotGroup={setLotGroup} />
        )}
      </div>
    </div>
  )
}
