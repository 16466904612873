import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { CreatePassword, User } from 'js/models'
import { FormFeedback } from 'js/services/api'
import { changePasswordAdmin } from 'js/services/user'
import { getDataFromForm } from 'js/utils/helpers'

export default function ChangePasswordAdmin(props: {
  user: User
}): React.ReactElement {
  const [errors, setErrors] = useState({} as FormFeedback<CreatePassword>)
  const [successMessage, setSuccessMessage] = useState<string>()

  const resetFormFeedback = (): void =>
    setErrors({} as FormFeedback<CreatePassword>)

  const onSave = (e: React.FormEvent): void => {
    e.preventDefault()
    resetFormFeedback()
    setSuccessMessage(undefined)

    const data = getDataFromForm(
      e.target,
      ['password1', 'password2'],
      'passwordChange_'
    )
    // Lesser TODO: Show saved successfull message

    changePasswordAdmin(props.user, data as CreatePassword)
      .then(r => {
        Array.from(document.querySelectorAll('input')).forEach(
          input => (input.value = '')
        )
        setSuccessMessage((r as FormFeedback<User>).detail)
      })
      .catch(e => setErrors(e.formErrors))
  }

  return (
    <Form onSubmit={onSave}>
      <Form.Group controlId='passwordChange_password1'>
        <Form.Label>New Password</Form.Label>
        <Form.Control
          type='password'
          placeholder='New Password'
          isInvalid={!!errors.password1}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.password1}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='passwordChange_password2'>
        <Form.Label className='visually-hidden'>
          Confirm New Password
        </Form.Label>
        <Form.Control
          type='password'
          placeholder='Confirm New Password'
          isInvalid={!!errors.password2}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.password2}
        </Form.Control.Feedback>
      </Form.Group>
      <p className='text-danger'>{errors.nonFieldErrors}</p>
      <p className='text-danger'>{errors.detail}</p>
      <p className='text-success'>{successMessage}</p>
      <Button variant='primary' type='submit'>
        Change Password
      </Button>
    </Form>
  )
}
