import React from 'react'
import Modal, { ModalProps } from 'react-bootstrap/Modal'

export enum ChartModals {
  Youden,
  LeveyJennings,
  None,
}

export const YoudenAboutModal = (props: ModalProps): React.ReactElement => (
  <Modal {...props}>
    <Modal.Header closeButton>
      <Modal.Title>What is a Youden Graph?</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Youden Graphs provide a visual comparison between within-laboratory QC
        results and inter-laboratory QC results. The Laboratory&apos;s Level 1
        control values (y-axis) are plotted against the Laboratory&apos;s Level
        2 control values (x-axis). The calculated means of the group data are
        used as the midpoints of both axes with the group&apos;s calculated 1sd
        and 2sd limits also displayed. The ratio of the Laboratory&apos;s Level
        1 calculated mean to the Laboratory&apos;s Level 2 calculated mean is
        displayed by the intersection of the bold, darkened line. The data
        should be evaluated to determine the accuracy and precision of your
        laboratory&apos;s data with that of the group. If the Laboratory&apos;s
        calculated mean ratio falls within the inner square (1sd limit), the
        laboratory is considered in agreement with other users of the same
        analyzer and lot number.
      </p>
    </Modal.Body>
  </Modal>
)
export const LeveyJenningsAboutModal = (
  props: ModalProps
): React.ReactElement => (
  <Modal {...props}>
    <Modal.Header closeButton>
      <Modal.Title>What is a Levey-Jennings Graph?</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Levey-Jennings charts provide a graphical representation of the
        laboratory&apos;s Quality Control data during a specified time limit.
        The control values are plotted according to the date (x-axis) and
        obtained concentration (y-axis). The graph contains three vertical lines
        that represent the calculated mean, -2sd limit, and +2sd limit for the
        group. All submitted data points are included in the lab&apos;s report
        and graphs. Any data points listed in the exception report are excluded
        from the group data. If there is more than one data point on a given
        day, all data points will be shown but the average of those points will
        be used for plotting the line for that day. This data should be
        evaluated to determine the accuracy and precision of your analyzer
        compared to other users of the same analyzer and lot number of control
        material.
      </p>
    </Modal.Body>
  </Modal>
)
