import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import Paths from 'js/utils/paths'

export default function HeaderWrapper(props: {
  children: React.ReactNode
}): React.ReactElement {
  return (
    <Navbar expand='sm' className='d-print-none' sticky='top'>
      <div className='container align-items-end'>
        <Navbar.Brand className='flex-grow-1' href={Paths.home}>
          <img
            className='header-logo'
            src={process.env.PUBLIC_URL + '/elitech_logo.png'}
            alt='ELITechGroup: Empowering IVD'
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse className='justify-content-end'>
          <Nav>{props.children}</Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}
