import { ChartData, ChartOptions } from 'chart.js'
import { BaseChartOptions } from '../chart-utils'
import { ErrorBarChartProps, CalculatedErrorBarChartData } from './models'
import Utils from './utils'

const getCalculatedErrorBarChartData = (
  props: ErrorBarChartProps
): CalculatedErrorBarChartData => {
  const groupAvg = props.groupData.groupAverage
  const groupSD = props.groupData.groupStandardDeviation
  const deviceAvg = props.deviceData.machineAverage
  const deviceSD = props.deviceData.machineStandardDeviation

  if (isNaN(groupAvg)) throw Error('groupAvg is NaN')
  if (isNaN(deviceAvg)) throw Error('deviceAvg is NaN')
  if (isNaN(groupSD) || groupSD <= 0)
    throw Error(`Invalid value for groupSD: ${groupSD}`)
  if (isNaN(deviceSD) || deviceSD <= 0)
    throw Error(`Invalid value for deviceSD: ${deviceSD}`)

  return {
    deviceData: {
      avg: deviceAvg,
      sdP2: deviceAvg + deviceSD * 2,
      sdP1: deviceAvg + deviceSD,
      sdM2: deviceAvg - deviceSD * 2,
      sdM1: deviceAvg - deviceSD,
      ...props.deviceData,
    },
    groupData: {
      avg: groupAvg,
      sdP1: groupAvg + groupSD,
      sdP2: groupAvg + groupSD * 2,
      sdM1: groupAvg - groupSD,
      sdM2: groupAvg - groupSD * 2,
      ...props.groupData,
    },
  }
}

const getErrorBarChartData = (
  data: CalculatedErrorBarChartData
): ChartData => ({ datasets: [{ data: Utils.getCoordinates(data) }] })

const getErrorBarChartOptions = (
  data: CalculatedErrorBarChartData
): ChartOptions => {
  const { groupData, deviceData } = data
  const yMin = Math.min(groupData.expectedLow, deviceData.expectedLow)
  const yMax = Math.max(groupData.expectedHigh, deviceData.expectedHigh)

  const options: BaseChartOptions = { scales: {}, plugins: {}, elements: {} }
  options.aspectRatio = 2 / 3
  options.scales.x = Utils.getXScaleOptions()
  options.scales.y = { suggestedMin: yMin, suggestedMax: yMax }
  options.plugins.legend = { display: false }
  options.plugins.annotation = {
    annotations: {
      deviceLine: Utils.getDeviceLineAnnotation(data),
      groupLine: Utils.getGroupLineAnnotation(data),
    },
  }
  options.elements.point = {
    backgroundColor: (ctx): string => Utils.getPointColor(ctx, data),
    radius: 6,
  }

  return options
}

const Controller = {
  getCalculatedErrorBarChartData,
  getErrorBarChartData,
  getErrorBarChartOptions,
}
export default Controller
