import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link, useParams } from 'react-router-dom'

import ForamttedEmail from 'js/components/FormattedEmail'
import ChangePasswordAdmin from 'js/components/forms/ChangePasswordAdmin'
import EditUserModal from 'js/components/forms/EditUserModal'
import InfoPiece from 'js/components/InfoPiece'
import Loader from 'js/components/Loader'

import { User } from 'js/models'
import { getUser, partialUpdateUser } from 'js/services/user'
import Paths from 'js/utils/paths'
import VQCPermissions from 'js/utils/permissions'

import { HelpKeys } from '../Help/helpContent'
import * as Modals from './Modals'

export default function AdminUserDetail(): React.ReactElement {
  const { pk } = useParams<{ pk?: string }>()
  const [user, setUser] = useState<User>()
  const [showEdit, setShowEdit] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const canEdit = VQCPermissions.canEditCustomerData()

  const handleShowEdit = (): void => setShowEdit(true)
  const handleCloseEdit = (): void => setShowEdit(false)

  const handleShowConfirmDelete = (): void => setShowConfirmDelete(true)
  const handleCloseConfirmDelete = (): void => setShowConfirmDelete(false)

  useEffect(() => {
    getUser(parseInt(pk as string)).then(r => setUser(r))
  }, [])

  if (!user) return <Loader />

  const renderSiteLink = (siteId: number, index: number): React.ReactNode => {
    return (
      <Link to={Paths.getAdminSiteDetailLink(siteId)} key={siteId}>
        {user.siteNames[index]}
      </Link>
    )
  }

  const removeUserFromOrganization = (): void => {
    partialUpdateUser(user.id, { organization: null, sites: [] }).then(r =>
      setUser(r as User)
    )
  }

  return (
    <>
      <h1>{user.name}</h1>

      <Row>
        <Col>
          <h2>Contact</h2>
          <InfoPiece label='Name'>{user.name}</InfoPiece>
          <InfoPiece label='Email'>
            <ForamttedEmail email={user.email} />
          </InfoPiece>
          <InfoPiece label='Organization'>
            {user.organization ? (
              <Link
                to={Paths.getAdminOrganizationDetailLink(user.organization)}
              >
                {user.organizationDetails?.name}
              </Link>
            ) : (
              'None'
            )}
          </InfoPiece>
          <InfoPiece label='Sites'>
            {user.sites.length > 0
              ? user.sites
                  .map((siteId, index) => renderSiteLink(siteId, index))
                  .reduce((prev, curr) => [prev, ', ', curr])
              : 'None'}
          </InfoPiece>
          <InfoPiece
            label='Roles'
            helpLink={Paths.getHelpSectionLink(HelpKeys.organizationAdmins)}
          >
            {user.groups.length > 0 ? user.groups.join(', ') : 'None'}
          </InfoPiece>
          {canEdit && (
            <div className='mt-2'>
              <Button onClick={handleShowEdit}>Edit</Button>
              {user.organization && (
                <Button
                  className='ms-2'
                  variant='danger'
                  onClick={handleShowConfirmDelete}
                >
                  Remove from Organization
                </Button>
              )}
            </div>
          )}
        </Col>
        {canEdit && (
          <Col>
            <h2>Change Password</h2>
            <ChangePasswordAdmin user={user} />
          </Col>
        )}
      </Row>

      <EditUserModal
        show={showEdit}
        user={user}
        handleClose={handleCloseEdit}
        setUser={setUser}
      />

      {user.organization &&
        Modals.removeUserModal(
          user,
          showConfirmDelete,
          handleCloseConfirmDelete,
          removeUserFromOrganization
        )}
    </>
  )
}
