import React, { useState, useEffect, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { User, Organization, Site } from 'js/models'
import { FormFeedback, MAX_REQUEST_SIZE } from 'js/services/api'
import { getOrganization, listOrganizations } from 'js/services/organization'
import { listSites } from 'js/services/site'
import { partialUpdateUser } from 'js/services/user'
import { getDataFromForm, SelfUser } from 'js/utils/helpers'
import VQCPermissions, { ELITECH_ADMINS, Groups } from 'js/utils/permissions'

export default function EditUserModal(props: {
  user: User
  show: boolean
  handleClose: () => void
  setUser: (user: User) => void
}): React.ReactElement {
  const { user, show, handleClose, setUser } = props

  const [organizationList, setOrganizationList] = useState<Organization[]>([])
  const [organization, setOrganization] = useState<Organization>()
  const [siteList, setSiteList] = useState<Site[]>([])
  const [errors, setErrors] = useState({} as FormFeedback<User>)

  const selfUser = useContext(SelfUser)
  const editCustomerData = VQCPermissions.canEditCustomerData(selfUser)
  const editElitechGroups = VQCPermissions.canEditElitechGroups(selfUser)
  const editUserOrganization = VQCPermissions.canEditUserOrganization(selfUser)

  useEffect(() => {
    listOrganizations({ count: MAX_REQUEST_SIZE }).then(r =>
      setOrganizationList(r.results)
    )
    if (user.organization) {
      listSites({ organization: user.organization }).then(r =>
        setSiteList(r.results)
      )
    }
  }, [])

  useEffect(() => {
    if (organization) {
      listSites({
        organization: organization.id,
        count: MAX_REQUEST_SIZE,
      }).then(r => setSiteList(r.results))
    }
  }, [organization])

  const resetFormFeedback = (): void => setErrors({} as FormFeedback<User>)

  const selectOrganization = (event): void => {
    getOrganization(event.target.value).then(r => setOrganization(r))
  }

  const renderSiteOption = (site: Site): React.ReactElement => {
    return (
      <Form.Check
        key={site.id}
        type='checkbox'
        label={site.name}
        name='userForm_sites'
        value={site.id}
        id={`${site.id}`}
        defaultChecked={user.sites.includes(site.id)}
      />
    )
  }

  const renderGroupOption = (group: Groups): React.ReactElement => {
    return (
      <div
        hidden={ELITECH_ADMINS.includes(group) && !editElitechGroups}
        key={group}
      >
        <Form.Check
          type='checkbox'
          label={group}
          name='userForm_groups'
          value={group}
          id={`${group}`}
          defaultChecked={user.groups.includes(group)}
        />
      </div>
    )
  }

  const onSave = (e: React.FormEvent): void => {
    e.preventDefault()
    resetFormFeedback()

    const data: Partial<User> = getDataFromForm(
      e.target,
      ['name', 'email', 'organization', 'sites', 'groups'],
      'userForm_'
    )

    if (data.organization?.toString() === 'default') data.organization = null

    if (data.sites?.toString() === 'none') data.sites = []

    if (data['sites']?.toString() === 'none') data['sites'] = []

    partialUpdateUser(user.id, data as Partial<User>)
      .then(r => {
        handleClose()
        setUser(r as User)
      })
      .catch(e => setErrors(e.formErrors))
    // Lesser TODO: Show saved successfull message
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop='static'
      size={editCustomerData ? 'lg' : undefined}
      scrollable
    >
      <Form onSubmit={onSave}>
        <Modal.Header>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group controlId='userForm_name'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Name'
                  defaultValue={user.name}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='userForm_email'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Email'
                  defaultValue={user.email}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.email}
                </Form.Control.Feedback>
                <Form.Text className='text-muted'>
                  Email address for login
                </Form.Text>
              </Form.Group>
              {!editElitechGroups && VQCPermissions.isElitechAdmin(user) ? (
                <>
                  <Form.Label>Roles</Form.Label>
                  <p>
                    You cannot edit this user&apos;s roles because they are an
                    ELITech Admin
                  </p>
                </>
              ) : (
                editCustomerData && (
                  <Form.Group>
                    <Form.Label>Roles</Form.Label>

                    <div>
                      {Object.values(Groups).map(group =>
                        renderGroupOption(group)
                      )}
                    </div>
                    <p className='text-danger'>{errors.groups}</p>
                  </Form.Group>
                )
              )}
            </Col>
            {editCustomerData && (
              <Col>
                {editUserOrganization && (
                  <Form.Group controlId='userForm_organization'>
                    <Form.Label>Organization</Form.Label>
                    <Form.Control
                      as='select'
                      defaultValue={user.organization || 'default'}
                      onChange={selectOrganization}
                      isInvalid={!!errors.organization}
                    >
                      <option disabled value='default'>
                        {' -- Select an Organization -- '}
                      </option>
                      {organizationList.map(org => (
                        <option key={org.id} value={org.id}>
                          {org.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      {errors.organization}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                {editCustomerData && (
                  <Form.Group>
                    <Form.Label>Sites</Form.Label>
                    <div hidden>
                      <Form.Check
                        name='userForm_sites'
                        value='none'
                        defaultChecked={false}
                      />
                    </div>
                    <div>
                      {siteList.length > 0
                        ? siteList.map(site => renderSiteOption(site))
                        : 'No sites available.'}
                    </div>
                    <p className='text-danger'>{errors.sites}</p>
                  </Form.Group>
                )}
              </Col>
            )}
          </Row>
          <p className='text-danger'>{errors.nonFieldErrors}</p>
          <p className='text-danger'>{errors.detail}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={(): void => {
              resetFormFeedback()
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' type='submit'>
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
