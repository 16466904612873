import { ScriptableContext } from 'chart.js'
// prettier-ignore
import { DrawTime, EllipseAnnotationOptions, LineAnnotationOptions } from 'chartjs-plugin-annotation/types/options'

import { YoudenDeviceData } from 'js/services/report-models'
import { COLORS } from 'js/utils/constants'
import { formatNumber, round } from 'js/utils/helpers'

// prettier-ignore
import { XYCoordinate, getLineAnnotation, LinearScaleTickOptions } from '../chart-utils'
import { CalculatedYoudenData } from './models'

/* ----- Constants and Interfaces ----- */
const AXES = ['x', 'y']
const SD_DISTANCES = [1, 2, 3]

/* ----- Local functions ----- */
const getYoudenCoordinates = (
  xDevices: YoudenDeviceData[],
  yDevices: YoudenDeviceData[]
): XYCoordinate[] => {
  return xDevices.reduce((coordinates: XYCoordinate[], x: YoudenDeviceData) => {
    const y = yDevices.find(y => y.device == x.device)
    if (y) coordinates.push({ deviceId: x.device, x: x.average, y: y.average })
    return coordinates
  }, [])
}

const getPointColor = (
  ctx: ScriptableContext<'scatter'>,
  data: CalculatedYoudenData
): string =>
  (ctx.raw as XYCoordinate).deviceId === data.myDeviceId
    ? COLORS.primary
    : COLORS.dark

function getAvgLineAnnotations(
  data: CalculatedYoudenData
): LineAnnotationOptions[] {
  const avgAbnormLine = getLineAnnotation({
    id: 'x-avg',
    value: data.xAvg,
    mode: 'vertical',
  })
  const avgNormLine = getLineAnnotation({ id: 'y-avg', value: data.yAvg })
  return [avgAbnormLine, avgNormLine]
}

function getSDBoxAnnotations(
  data: CalculatedYoudenData
): EllipseAnnotationOptions[] {
  const common = {
    drawTime: 'beforeDatasetsDraw' as DrawTime,
    type: 'box',
  }
  const stdRange1 = {
    ...common,
    xMin: data.sdM1x,
    xMax: data.sdP1x,
    yMin: data.sdM1y,
    yMax: data.sdP1y,
    backgroundColor: 'rgba(255, 99, 132, 0.25)',
  }
  const stdRange2 = {
    ...common,
    xMin: data.sdM2x,
    xMax: data.sdP2x,
    yMin: data.sdM2y,
    yMax: data.sdP2y,
    backgroundColor: 'rgba(255, 255, 255, 0)',
  }
  return [stdRange1, stdRange2]
}

function getCrosshairAnnotations(
  data: CalculatedYoudenData
): LineAnnotationOptions[] {
  if (data.myDeviceCoordinates.x && data.myDeviceCoordinates.y) {
    return [
      getLineAnnotation({
        id: 'x-myDevice',
        value: data.myDeviceCoordinates.x,
        mode: 'vertical',
        borderColor: COLORS.primary,
      }),
      getLineAnnotation({
        id: 'y-myDevice',
        value: data.myDeviceCoordinates.y,
        borderColor: COLORS.primary,
      }),
    ]
  }
  return []
}

const getSDTickOptions = (
  avg: number,
  sd: number,
  sdM2: number,
  sdP2: number
): LinearScaleTickOptions => {
  const ticks = {} as LinearScaleTickOptions
  ticks.stepSize = sd
  const threshold = sd / 2
  ticks.callback = (tickValue): string | string[] => {
    const val = tickValue as number
    // accounting for rounding errors
    if (Math.abs(val - avg) < threshold) return [round(val), 'Mean']
    if (Math.abs(val - sdM2) < threshold) return [round(val), '-2 SD']
    if (Math.abs(val - sdP2) < threshold) return [round(val), '+2 SD']
    return formatNumber(val)
  }
  return ticks
}

const Utils = {
  AXES,
  SD_DISTANCES,
  getYoudenCoordinates,
  getPointColor,
  getAvgLineAnnotations,
  getSDBoxAnnotations,
  getCrosshairAnnotations,
  getSDTickOptions,
}

export default Utils
