import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useParams } from 'react-router-dom'

import DeleteControlTypeModal from 'js/components/forms/DeleteControlTypeModal'
import EditControlTypeModal from 'js/components/forms/EditControlTypeModal'
import InfoPiece from 'js/components/InfoPiece'
import Loader from 'js/components/Loader'

import { Control } from 'js/models'
import { getControl } from 'js/services/controls'
import VQCPermissions from 'js/utils/permissions'

import DeviceTypesTable from './DeviceTypesTable'

export default function AdminControlTypeDetail(): React.ReactElement {
  const [control, setControl] = useState<Control>()
  const { pk } = useParams<{ pk?: string }>()
  const [showControlEdit, setShowControlEdit] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const canEdit = VQCPermissions.canEditElitechData()

  const handleShowControlEdit = (): void => setShowControlEdit(true)
  const handleCloseControlEdit = (): void => setShowControlEdit(false)

  const handleShowConfirmDelete = (): void => setShowConfirmDelete(true)
  const handleCloseConfirmDelete = (): void => setShowConfirmDelete(false)

  useEffect(() => {
    getControl(parseInt(pk as string)).then(r => setControl(r))
  }, [pk])

  if (!control || !control.deviceTypes) return <Loader />

  return (
    <>
      <h1>
        {control.name} ({control.levelName})
      </h1>
      <Row>
        <Col>
          <h2>Control Type Info</h2>
          <InfoPiece label='Name'>{control.name}</InfoPiece>
          <InfoPiece label='Test'>{control.testName}</InfoPiece>
          <InfoPiece label='Level'>
            {control.level} - {control.levelName}
          </InfoPiece>
          {canEdit && (
            <>
              <Button onClick={handleShowControlEdit}>Edit</Button>
              <Button
                onClick={handleShowConfirmDelete}
                variant='danger'
                className='ms-2'
              >
                Delete
              </Button>
            </>
          )}
        </Col>
        <Col>
          <DeviceTypesTable
            deviceTypeIdList={control.deviceTypes}
            controlId={control.id}
            setControlType={setControl}
          />
        </Col>
      </Row>

      <EditControlTypeModal
        show={showControlEdit}
        control={control}
        handleClose={handleCloseControlEdit}
        setControl={setControl}
      />

      <DeleteControlTypeModal
        control={control}
        show={showConfirmDelete}
        handleClose={handleCloseConfirmDelete}
      />
    </>
  )
}
