import React, { useEffect, useState } from 'react'
import { ChartOptions, ChartData } from 'chart.js'
import { Line } from 'react-chartjs-2'

import Loader from 'js/components/Loader'
import { MAX_REQUEST_SIZE } from 'js/services/api'
import { listDeviceEntries } from 'js/services/deviceEntry'
import Controller from './controller'
import { LJChartProps } from './models'

/**
 * @param {number} avg - group average for the given level
 * @param {number} sd - group standard deviation for the given level
 * @param {'Normal' | 'Abnormal'} level - report level for retrieving relevant device entries and for the chart title
 * @param {number} lotGroup - report lotGroup id for retrieving relevant device entries
 * @param {number} device - report device id for retrieving relevant device entries
 * @param {number} month - report month for retrieving relevant device entries
 * @param {number} year - report year for retrieving relevant device entries
 * @returns {React.ReactElement} Levey-Jennings Chart in div with title
 */
export default function LeveyJenningsChart(
  props: LJChartProps
): React.ReactElement {
  const [options, setOptions] = useState<ChartOptions>()
  const [chartData, setChartData] = useState<ChartData>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (isNaN(props.avg) || isNaN(props.sd) || props.sd <= 0) return
    try {
      listDeviceEntries({
        lot__group: props.lotGroup,
        month: props.month,
        year: props.year,
        device: props.device,
        level_name: props.level,
        count: MAX_REQUEST_SIZE,
      }).then(entries => {
        if (entries.results.length > 0) {
          const data = Controller.calculateLJData(props, entries.results)
          const locOptions = Controller.getLJOptions(data)
          setChartData(Controller.getLJChartData(data))
          setOptions(locOptions)
        }
        setLoading(false)
      })
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }, [props])

  if (loading) return <Loader />

  return (
    <div className='print-avoid-break-inside position-relative'>
      <h5 className='text-center'>{props.level} Level</h5>
      {chartData ? (
        <Line
          data={chartData as Chart.ChartData}
          options={options as Chart.ChartOptions}
        />
      ) : (
        <div className='border border-2 p-4 text-center text-muted'>
          No entry data provided.
        </div>
      )}
      <hr />
    </div>
  )
}
