import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { Control, Lot, LotGroup } from 'js/models'
import { FormFeedback, MAX_REQUEST_SIZE } from 'js/services/api'
import { listControls } from 'js/services/controls'
import { createLot } from 'js/services/lot'
import { getLotGroup } from 'js/services/lotGroup'
import { getDataFromForm } from 'js/utils/helpers'

export default function CreateLotForm(props: {
  lotGroup: LotGroup
  setLotGroup: (lotGroup: LotGroup) => void
  setActiveTab: (key: string) => void
}): React.ReactElement {
  const { lotGroup, setLotGroup, setActiveTab } = props
  const [controlList, setControlList] = useState<Control[]>([])
  const [filteredControlList, setFilteredControlList] = useState<Control[]>([])
  const [errors, setErrors] = useState({} as FormFeedback<Lot>)

  useEffect(() => {
    listControls({ count: MAX_REQUEST_SIZE }).then(r =>
      setControlList(r.results)
    )
  }, [])

  useEffect(() => {
    if (lotGroup.lots.length == 0) setFilteredControlList(controlList)
    else {
      const firstControl = controlList.find(
        control => control.id == lotGroup.lots[0].control
      ) as Control
      const thisLotsControls = lotGroup.lots.map(lot => lot.control)
      const filtered = controlList.filter(
        control =>
          control.testName == firstControl.testName &&
          !thisLotsControls.includes(control.id)
      )
      setFilteredControlList(filtered)
    }
  }, [controlList])

  const resetFormFeedback = (): void => setErrors({} as FormFeedback<Lot>)

  const onSave = (e: React.FormEvent): void => {
    e.preventDefault()
    resetFormFeedback()
    const data = getDataFromForm(
      e.target,
      ['lotNumber', 'expirationDate', 'control', 'group'],
      'lotForm_'
    )

    createLot(data as Partial<Lot>)
      .then(r => {
        getLotGroup(lotGroup.id).then(r => {
          setLotGroup(r)
        })
        setActiveTab(`${(r as Lot).id}`)
      })
      .catch(e => setErrors(e.formErrors))
    // Lesser TODO: Show saved successfull message
  }

  if (filteredControlList.length == 0)
    return <p>No more controls available to add to this lot group.</p>

  return (
    <Form onSubmit={onSave}>
      <div className='w-50'>
        <Form.Group controlId='lotForm_group' hidden>
          <Form.Control type='text' defaultValue={lotGroup.id} />
        </Form.Group>
        <Form.Group controlId='lotForm_lotNumber'>
          <Form.Label>Lot Number</Form.Label>
          <Form.Control
            type='text'
            placeholder='Lot Number'
            isInvalid={!!errors.lotNumber}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.lotNumber}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='lotForm_control'>
          <Form.Label>Control Type</Form.Label>
          <Form.Control
            as='select'
            defaultValue='default'
            isInvalid={!!errors.control}
          >
            <option value='default' disabled>
              {' -- Select a Control -- '}
            </option>
            {filteredControlList
              .sort((a, b) => {
                return a.level < b.level ? -1 : 1
              })
              .sort((a, b) => {
                return a.name < b.name ? -1 : 1
              })
              .map(control => (
                <option key={`control-option-${control.id}`} value={control.id}>
                  {control.name} – {control.level} {control.levelName}
                </option>
              ))}
          </Form.Control>
          <Form.Control.Feedback type='invalid'>
            {errors.control}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='lotForm_expirationDate'>
          <Form.Label>Expiration Date</Form.Label>
          <Form.Control type='date' isInvalid={!!errors.expirationDate} />
          <Form.Control.Feedback type='invalid'>
            {errors.expirationDate}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <p className='text-danger'>{errors.nonFieldErrors}</p>
      <p className='text-danger'>{errors.detail}</p>
      <Button variant='primary' type='submit'>
        Create Lot
      </Button>
    </Form>
  )
}
