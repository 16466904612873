import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'

import { Control } from 'js/models'
import { FormFeedback } from 'js/services/api'
import { partialUpdateControl } from 'js/services/controls'
import { getDataFromForm } from 'js/utils/helpers'

export default function EditControlTypeModal(props: {
  control: Control
  show: boolean
  handleClose: () => void
  setControl: (control: Control) => void
}): React.ReactElement {
  const { control, show, handleClose, setControl } = props
  const [errors, setErrors] = useState({} as FormFeedback<Control>)

  const resetFormFeedback = (): void => setErrors({} as FormFeedback<Control>)

  const onSave = (e: React.FormEvent): void => {
    e.preventDefault()
    resetFormFeedback()

    const data = getDataFromForm(
      e.target,
      ['name', 'levelName', 'level', 'testName'],
      'controlForm_'
    )

    partialUpdateControl(control.id, data as Partial<Control>)
      .then(r => {
        handleClose()
        setControl(r as Control)
      })
      .catch(e => setErrors(e.formErrors))
    // Lesser TODO: Show saved successfull message
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop='static'
      size='lg'
      scrollable
    >
      <Form onSubmit={onSave}>
        <Modal.Header>
          <Modal.Title>Edit Control</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group controlId='controlForm_name'>
                <Form.Label>Control Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Name'
                  defaultValue={control.name}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='controlForm_testName'>
                <Form.Label>Test Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Test Name'
                  defaultValue={control.testName}
                  isInvalid={!!errors.testName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.testName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='controlForm_level'>
                <Form.Label>Level Number</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Level Number'
                  defaultValue={control.level}
                  isInvalid={!!errors.level}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.level}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='controlForm_levelName'>
                <Form.Label>Level Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Level Name'
                  defaultValue={control.levelName}
                  isInvalid={!!errors.levelName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.levelName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={(): void => {
              resetFormFeedback()
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' type='submit'>
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
