import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { PasswordChange, User } from 'js/models'
import { FormFeedback } from 'js/services/api'
import { changePasswordSelf } from 'js/services/user'
import { getDataFromForm } from 'js/utils/helpers'

export default function ChangePasswordSelf(): React.ReactElement {
  const [errors, setErrors] = useState({} as FormFeedback<PasswordChange>)
  const [successMessage, setSuccessMessage] = useState<string>()

  const resetFormFeedback = (): void =>
    setErrors({} as FormFeedback<PasswordChange>)

  const onSave = (e: React.FormEvent): void => {
    e.preventDefault()
    resetFormFeedback()
    setSuccessMessage(undefined)

    const data = getDataFromForm(
      e.target,
      ['oldPassword', 'newPassword1', 'newPassword2'],
      'passwordChange_'
    )
    // Lesser TODO: Show saved successfull message

    changePasswordSelf(data as PasswordChange)
      .then(r => {
        Array.from(document.querySelectorAll('input')).forEach(
          input => (input.value = '')
        )
        setSuccessMessage((r as FormFeedback<User>).detail)
      })
      .catch(e => setErrors(e.formErrors))
  }

  return (
    <Form onSubmit={onSave}>
      <Form.Group controlId='passwordChange_oldPassword'>
        <Form.Label>Current Password</Form.Label>
        <Form.Control
          type='password'
          placeholder='Current Password'
          isInvalid={!!errors.oldPassword}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.oldPassword}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='passwordChange_newPassword1'>
        <Form.Label>New Password</Form.Label>
        <Form.Control
          type='password'
          placeholder='New Password'
          isInvalid={!!errors.newPassword1}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.newPassword1}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='passwordChange_newPassword2'>
        <Form.Label className='visually-hidden'>
          Confirm New Password
        </Form.Label>
        <Form.Control
          type='password'
          placeholder='Confirm New Password'
          isInvalid={!!errors.newPassword2}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.newPassword2}
        </Form.Control.Feedback>
      </Form.Group>
      <p className='text-danger'>{errors.nonFieldErrors}</p>
      <p className='text-danger'>{errors.detail}</p>
      <p className='text-success'>{successMessage}</p>
      <Button variant='primary' type='submit'>
        Change Password
      </Button>
    </Form>
  )
}
