import { HelpKeys } from 'js/containers/Help/helpContent'
import Paths from 'js/utils/paths'
import React from 'react'
import { Link } from 'react-router-dom'

export default function NoOrganizationWarn(): React.ReactElement {
  const contactUs = (
    <Link to={Paths.getHelpSectionLink(HelpKeys.contact)}>contact us</Link>
  )
  return (
    <p>
      Your user does not belong to an organization. If you believe this is a
      mistake, {contactUs} to get connected.
    </p>
  )
}
