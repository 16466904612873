import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { useHistory } from 'react-router-dom'

import { Control } from 'js/models'
import { FormFeedback } from 'js/services/api'
import { createControl } from 'js/services/controls'
import { getDataFromForm } from 'js/utils/helpers'
import Paths from 'js/utils/paths'

export default function CreateControlTypeModal(props: {
  show: boolean
  handleClose: () => void
}): React.ReactElement {
  const { show, handleClose } = props
  const history = useHistory()
  const [errors, setErrors] = useState({} as FormFeedback<Control>)

  const resetFormFeedback = (): void => setErrors({} as FormFeedback<Control>)

  const onSave = (e: React.FormEvent): void => {
    e.preventDefault()
    resetFormFeedback()

    const data = getDataFromForm(
      e.target,
      ['name', 'levelName', 'level', 'testName'],
      'controlForm_'
    )

    createControl(data as Partial<Control>)
      .then(r => {
        handleClose()
        history.push(Paths.getAdminControlTypeDetailLink((r as Control).id))
      })
      .catch(e => setErrors(e.formErrors))
    // Lesser TODO: Show saved successfull message
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop='static' scrollable>
      <Form onSubmit={onSave}>
        <Modal.Header>
          <Modal.Title>Create Control Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId='controlForm_name'>
            <Form.Label>Control Type Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Name'
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='controlForm_testName'>
            <Form.Label>Test Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Test'
              isInvalid={!!errors.testName}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.testName}
            </Form.Control.Feedback>
            <Form.Text className='text-muted'>
              Ex. Sweat, ESR, Osmometry
            </Form.Text>
          </Form.Group>
          <Row>
            <Col>
              <Form.Group controlId='controlForm_levelName'>
                <Form.Label>Level Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Level Name'
                  isInvalid={!!errors.levelName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.levelName}
                </Form.Control.Feedback>
                <Form.Text className='text-muted'>
                  Ex. Normal, High Normal, Abnormal
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group controlId='controlForm_level'>
                <Form.Label>Level Number</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Level'
                  isInvalid={!!errors.level}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.level}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <p className='text-danger'>{errors.nonFieldErrors}</p>
          <p className='text-danger'>{errors.detail}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={(): void => {
              resetFormFeedback()
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' type='submit'>
            Create
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
