import React from 'react'

import AdminDeviceTable from 'js/components/tables/AdminDeviceTable'

export default function AdminDevices(): React.ReactElement {
  return (
    <div>
      <h1>Device Registrations</h1>
      <AdminDeviceTable />
    </div>
  )
}
