import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { useParams } from 'react-router-dom'

import ErrorBarChart from 'js/components/charts/ErrorBarChart'
import LeveyJenningsChart from 'js/components/charts/LeveyJenningsChart'
import Loader from 'js/components/Loader'
import LotReportTable from 'js/components/tables/LotReportTable'
import MonthReportTable from 'js/components/tables/MonthReportTable'
import StatisticalReportTable from 'js/components/tables/StatisticalReportTable'

import { Device } from 'js/models'
// prettier-ignore
import { CompleteDeviceReport, LotReport, MappedReportData } from 'js/services/report-models'
import { getAllDeviceReports, getLotReport } from 'js/services/reports'
import { MONTH_NAMES } from 'js/utils/constants'
import { ControlRunUrlParams } from 'js/utils/paths'

import ReportWebView from './ReportWebView'
import * as Utils from './utils'

// Control Run Report View
export default function ReportViewWrapper(props: {
  device: Device
}): React.ReactElement {
  const { lotGroupId, year, month } = useParams<ControlRunUrlParams>()

  const [deviceReport, setDeviceReport] = useState({} as CompleteDeviceReport)
  const [lotReport, setLotReport] = useState<LotReport>([])
  const [mappedData, setMappedData] = useState<MappedReportData>({})
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const serviceParams = {
    month: parseInt(month),
    year: parseInt(year),
    lotGroup: parseInt(lotGroupId),
    device: props.device.id,
  }

  useEffect(() => {
    Promise.all([
      getAllDeviceReports(serviceParams).then(r => {
        setDeviceReport(r)
        setMappedData(Utils.mapData(r))
      }),
      getLotReport(serviceParams).then(r => setLotReport(r)),
    ])
      .then(() => setLoading(false))
      .catch(e => {
        setHasError(true)
        throw Error(`Problem retrieving report data (${e})`)
      })
  }, [])

  if (hasError)
    return (
      <Alert variant='danger'>
        Oops! Something went wrong loading this page.
      </Alert>
    )
  if (loading) return <Loader />

  const levelNames = Object.keys(mappedData)

  const reportContent = {
    monthReportTable: <MonthReportTable data={mappedData} />,
    errorBarCharts: levelNames.map(ln => (
      <ErrorBarChart
        key={'errorbarchart-' + ln}
        level={ln}
        groupData={mappedData[ln].groupData}
        deviceData={mappedData[ln].deviceData}
      />
    )),
    leveyJenningsCharts: levelNames.map(ln => (
      <LeveyJenningsChart
        key={'leveychart-' + ln}
        level={ln}
        sd={mappedData[ln].groupData.groupStandardDeviation}
        avg={mappedData[ln].groupData.groupAverage}
        {...serviceParams}
      />
    )),
    youdenChart: Utils.renderYoudenChart(deviceReport.youden, mappedData),
    statisticalReportTable: (
      <StatisticalReportTable
        device={props.device}
        detailedGroups={deviceReport.detailedGroup}
        detailedDevices={deviceReport.detailedDevice}
        detailedGroupsCums={deviceReport.detailedGroupCumulative}
        detailedDevicesCums={deviceReport.detailedDeviceCumulative}
      />
    ),
    lotReportTable: (
      <LotReportTable data={lotReport} date={`${MONTH_NAMES[month]} ${year}`} />
    ),
  }

  return <ReportWebView content={reportContent} />
}
