import React, { useContext } from 'react'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer'

import { SelfUser, signOut } from 'js/utils/helpers'
import Paths from 'js/utils/paths'
import VQCPermissions from 'js/utils/permissions'
import UnauthorizedPaths from 'js/utils/unauthorizedPaths'
import HeaderWrapper from './HeaderWrapper'

export default function Header(): React.ReactElement {
  const selfUser = useContext(SelfUser)
  const isElitechAdmin = VQCPermissions.isElitechAdmin(selfUser)
  const viewCustomerData = VQCPermissions.canViewCustomerData(selfUser)
  const viewElitechData = VQCPermissions.canViewElitechData(selfUser)

  return (
    <HeaderWrapper>
      {isElitechAdmin && (
        <NavDropdown title='Admin' id='basic-nav-dropdown'>
          {viewCustomerData && (
            <>
              <LinkContainer to={Paths.adminOrganizations}>
                <NavDropdown.Item>Organizations</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to={Paths.adminUsers}>
                <NavDropdown.Item>Users</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to={Paths.adminDevices}>
                <NavDropdown.Item>Device Registrations</NavDropdown.Item>
              </LinkContainer>
            </>
          )}
          {viewElitechData && (
            <>
              <LinkContainer to={Paths.adminLotGroups}>
                <NavDropdown.Item>Lot Management</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to={Paths.adminControlTypes}>
                <NavDropdown.Item>Control Types</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to={Paths.adminDeviceTypes}>
                <NavDropdown.Item>Device Models</NavDropdown.Item>
              </LinkContainer>
            </>
          )}
        </NavDropdown>
      )}

      <LinkContainer exact to={Paths.controlRuns}>
        <Nav.Link>Control Runs</Nav.Link>
      </LinkContainer>
      {VQCPermissions.isOrganizationAdmin(selfUser) && (
        <LinkContainer
          to={Paths.getAdminOrganizationDetailLink(selfUser.organization)}
        >
          <Nav.Link>Organization Settings</Nav.Link>
        </LinkContainer>
      )}
      <LinkContainer to={Paths.profile}>
        <Nav.Link>Profile</Nav.Link>
      </LinkContainer>
      <LinkContainer to={UnauthorizedPaths.signIn}>
        <Nav.Link onClick={signOut}>Sign Out</Nav.Link>
      </LinkContainer>
    </HeaderWrapper>
  )
}
