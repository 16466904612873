import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { InfoIcon } from 'js/components/Icons'
import PrintableCol from 'js/components/PrintableCol'

// prettier-ignore
import { ChartModals, LeveyJenningsAboutModal, YoudenAboutModal } from './modals'
import { ReportContent } from './utils'

const ReportWebView = (props: {
  content: ReportContent
}): React.ReactElement => {
  const [curModal, setCurModal] = useState(ChartModals.None)

  const closeModal = (): void => setCurModal(ChartModals.None)
  const content = props.content
  const showYouden = Boolean(content.youdenChart)

  return (
    <div>
      <p className='text-center'>
        Peer reports are available in real time. For optimal group data
        analysis, print or download monthly reports mid-month.
      </p>
      {content.monthReportTable}
      <Row className='print-avoid-break-inside d-print-block'>
        {content.errorBarCharts.map((ebc, i) => (
          <PrintableCol key={`errorbarchart-col-${i}`} print={6} sm={6} xl={4}>
            {ebc}
          </PrintableCol>
        ))}
      </Row>
      <Row className='mb-5 d-print-block'>
        <Col lg={showYouden ? 6 : 8} className='mx-auto'>
          <h3 className='text-center mb-1 text-uppercase'>Your Lab</h3>
          <h5
            className='text-center text-body clickable'
            onClick={(): void => setCurModal(ChartModals.LeveyJennings)}
          >
            Levey-Jennings{InfoIcon}
          </h5>
          {content.leveyJenningsCharts}
        </Col>
        {showYouden && (
          <Col lg={6} className='print-avoid-break-inside mx-auto'>
            <h3 className='text-center mb-1 text-uppercase'>Group Results</h3>
            <h5
              className='text-center text-body clickable'
              onClick={(): void => setCurModal(ChartModals.Youden)}
            >
              Youden{InfoIcon}
            </h5>
            {content.youdenChart}
          </Col>
        )}
      </Row>
      <div className='mb-5 print-avoid-break-inside'>
        {content.statisticalReportTable}
      </div>
      <div className='mb-5 print-avoid-break-inside'>
        {content.lotReportTable}
      </div>

      {/* MODALS */}
      {showYouden && (
        <YoudenAboutModal
          show={curModal === ChartModals.Youden}
          onHide={closeModal}
        />
      )}
      <LeveyJenningsAboutModal
        show={curModal === ChartModals.LeveyJennings}
        onHide={closeModal}
      />
    </div>
  )
}

export default ReportWebView
