import React from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'

import { User } from 'js/models'
import { ApprovedStatus } from 'js/utils/constants'
import Paths from 'js/utils/paths'
import VQCPermissions from 'js/utils/permissions'

export default function PendingOrganization(props: {
  user: User
  status: ApprovedStatus
}): React.ReactElement {
  const orgLink = Paths.getAdminOrganizationDetailLink(props.user.organization)

  const getAlert = (): React.ReactElement | void => {
    if (props.status === ApprovedStatus.pending)
      return (
        <Alert variant='warning'>
          <Alert.Heading>Organization Pending</Alert.Heading>
          <p>
            Your organization is still pending approval. Once approved, you will
            visit this page to upload data to be compared with other
            organizations.
          </p>
        </Alert>
      )
    else if (props.status === ApprovedStatus.approved)
      return (
        <Alert variant='success'>
          <Alert.Heading>Organization Approved</Alert.Heading>
          <p>Your organization has been approved!</p>
          <hr />
          <div className='d-flex justify-content-end'>
            <Button href={Paths.submitData}>Submit Data</Button>
          </div>
        </Alert>
      )
    // TODO: Alert for denied status
  }

  return (
    <div>
      <h1>Welcome to VQC!</h1>
      <div className='w-75 m-auto'>
        {getAlert()}
        <h2 className='pt-3'>How can I get started?</h2>
        {VQCPermissions.isOrganizationAdmin(props.user) && (
          <div className='pb-5'>
            <h3>Setup Your Organization</h3>
            <p>
              Since you are an organization admin, you have access to change
              your organization settings. Visit the <i>Organization Settings</i>{' '}
              page in the top menu bar to:
            </p>
            <ul>
              <li>Update organization contact information</li>
              <li>Manage and invite new users</li>
              <li>Add different sites</li>
              <li>Register your devices</li>
            </ul>
            <Button size='sm' href={orgLink}>
              Go to Organization Page
            </Button>
          </div>
        )}

        <div className='pb-5'>
          <h3>Update your profile</h3>
          <p>
            Visit the <i>Profile</i> page in the top menu bar to update your
            contact information or change your password
          </p>
          <Button size='sm' href={Paths.profile}>
            Update profile
          </Button>
        </div>

        <div className='pb-5'>
          <h3>Explore the help section</h3>
          <p>
            Go to <i>Help</i> in the bottom footer to read more about using the
            VQC application. Here, you can also find our contact information if
            you need additional support.
          </p>
          <Button size='sm' href={Paths.help}>
            Help page
          </Button>
        </div>
      </div>
    </div>
  )
}
