import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'

import Loader from 'js/components/Loader'

import { Control, DeviceType, Lot } from 'js/models'
import { listDeviceTypes } from 'js/services/deviceType'

import { LotCumulativeReport } from 'js/services/report-models'
import { getLotCumulativeReport } from 'js/services/reports'
import { formatNumber } from 'js/utils/helpers'

export default function LotCumulativeReportTable(props: {
  control: Control
  lot: Lot
}): React.ReactElement {
  const { control, lot } = props
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>()
  const [lotCumulativeReport, setLotCumulativeReport] =
    useState<LotCumulativeReport>()

  useEffect(() => {
    listDeviceTypes({ ids: control.deviceTypes.join(',') }).then(r =>
      setDeviceTypes(r.results)
    )
  }, [])

  useEffect(() => {
    deviceTypes &&
      getLotCumulativeReport({
        lot: lot.id,
        deviceTypes: deviceTypes.map(dt => dt.id),
      }).then(r => setLotCumulativeReport(r))
  }, [deviceTypes])

  if (!deviceTypes || !lotCumulativeReport) return <Loader />

  const renderTableRow = (deviceType: DeviceType): React.ReactElement => {
    const reportData = lotCumulativeReport.find(
      report => parseInt(report.deviceType) === deviceType.id
    )
    if (!reportData) return <></>

    return (
      <tr key={deviceType.id}>
        <td>{deviceType.name}</td>
        <td>{formatNumber(reportData.groupCount)}</td>
        <td>{formatNumber(reportData.groupAverage)}</td>
        <td>{formatNumber(reportData.groupStandardDeviation)}</td>
        <td>{formatNumber(2 * reportData.groupStandardDeviation)}</td>
        <td>{formatNumber(reportData.groupCoefficientOfVariance)}</td>
      </tr>
    )
  }

  return (
    <>
      <Table striped className='table-header-primary'>
        <thead>
          <tr>
            <th>Device</th>
            <th>Data Points</th>
            <th>Mean</th>
            <th>1 SD</th>
            <th>2 SD</th>
            <th>CV</th>
          </tr>
        </thead>
        <tbody>
          {deviceTypes
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map(dt => renderTableRow(dt))}
        </tbody>
      </Table>
    </>
  )
}
