import { Site } from 'js/models'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'
import api, { BaseQueryParams, FormError, FormPromise, Paginated } from './api'

export interface listSitesParams extends BaseQueryParams {
  organization?: number
}

/*=============================================
=                     GET                     =
=============================================*/
export const listSites = (
  params?: listSitesParams
): Promise<Paginated<Site>> => {
  return api
    .get('/sites/', { params: makeSnakeCase(params) })
    .then(r => makeCamelCase(r.data))
}

export const getSite = (id: number): Promise<Site> => {
  return api.get(`/sites/${id}/`).then(r => makeCamelCase(r.data))
}

/*=============================================
=                    POST                     =
=============================================*/
export const createSite = (data: Partial<Site>): FormPromise<Site> => {
  return api
    .post('/sites/', makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as Site)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                    PATCH                    =
=============================================*/

export const partialUpdateSite = (
  id: number,
  data: Partial<Site>
): FormPromise<Site> => {
  return api
    .patch(`/sites/${id}/`, makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as Site)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                     DELETE                  =
=============================================*/
export const deleteSite = (id: number): Promise<void | FormError<Site>> => {
  return api
    .delete(`/sites/${id}/`)
    .then(() => Promise.resolve())
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}
