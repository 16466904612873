import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import { Control, Device, LotGroup, Organization } from 'js/models'
import { TimeTempTests, WorkingTimeOptions } from 'js/utils/constants'

import SubmitDataOptions from './SelectUploadOptions'
import UploadFile from './UploadFile'
import UploadManual from './UploadManual'
import UploadSummary from './UploadSummary'

export interface UploadOptions {
  organization: Organization
  lotGroup: LotGroup
  controls: Control[]
  device: Device
  workingTime?: WorkingTimeOptions
  temperatureCorrection?: boolean
}

export default function SubmitData(): React.ReactElement {
  const [uploadOptions, setUploadOptions] = useState<UploadOptions | null>(null)
  const [useTimeTemp, setUseTimeTemp] = useState(false)

  useEffect(() => {
    uploadOptions &&
      setUseTimeTemp(TimeTempTests.includes(uploadOptions.lotGroup.testName))
  }, [uploadOptions])

  return (
    <div>
      <h1>Submit Data</h1>

      <SubmitDataOptions setUploadOptions={setUploadOptions} />

      {uploadOptions && (
        <Tabs id='data-options' className='mt-5'>
          <Tab eventKey='manual-entry' title='Manual Entry'>
            <UploadManual
              lotGroup={uploadOptions.lotGroup}
              controls={uploadOptions.controls}
              selectedDevice={uploadOptions.device}
              selectedWorkingTime={
                useTimeTemp ? uploadOptions.workingTime : undefined
              }
              selectedTempCorrection={
                useTimeTemp ? uploadOptions.temperatureCorrection : undefined
              }
            />
          </Tab>
          <Tab eventKey='file-upload' title='File Upload'>
            <UploadFile
              lotGroup={uploadOptions.lotGroup}
              selectedDevice={uploadOptions.device}
              selectedWorkingTime={
                useTimeTemp ? uploadOptions.workingTime : undefined
              }
              selectedTempCorrection={
                useTimeTemp ? uploadOptions.temperatureCorrection : undefined
              }
            />
          </Tab>
          <Tab eventKey='summary-data' title='Summary Data'>
            <UploadSummary
              lotGroup={uploadOptions.lotGroup}
              controls={uploadOptions.controls}
              selectedDevice={uploadOptions.device}
              selectedWorkingTime={
                useTimeTemp ? uploadOptions.workingTime : undefined
              }
              selectedTempCorrection={
                useTimeTemp ? uploadOptions.temperatureCorrection : undefined
              }
            />
          </Tab>
        </Tabs>
      )}
    </div>
  )
}
