import React, { useEffect, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import Loader from 'js/components/Loader'
import SignatureLine from 'js/components/SignatureLine'

import { LotGroup, Device } from 'js/models'
import { getDeviceById } from 'js/services/device'
import { getLotGroup } from 'js/services/lotGroup'
import Paths, { ControlRunUrlParams } from 'js/utils/paths'

import CRDHeader from './DetailHeader'
import DetailView from './DetailView'
import ReportViewWrapper from './ReportView'

export default function ControlRunDetail(): React.ReactElement {
  const urlParams = useParams<ControlRunUrlParams>()

  const [lotGroup, setLotGroup] = useState<LotGroup>()
  const [device, setDevice] = useState<Device>()
  const [isReportView, setisReportView] = useState(false)
  const [loading, setLoading] = useState(true)

  const match = useRouteMatch(Paths.controlRunReport)

  useEffect(() => {
    Promise.all([
      getLotGroup(parseInt(urlParams.lotGroupId)).then(r => setLotGroup(r)),
      getDeviceById(parseInt(urlParams.deviceId)).then(r => setDevice(r)),
    ]).then(() => setLoading(false))
  }, [])

  useEffect(() => setisReportView(!!match?.isExact), [match])

  if (loading) return <Loader />
  if (!device || !lotGroup)
    throw Error(
      'Finished loading but missing data. One or more queries failed regarding "device" or "lotGroup"'
    )

  // Using table format to easily print header on each page after attempting and failing to use the "position: fixed" method
  // https://stackoverflow.com/a/51371081/1659121
  return (
    <>
      <table className='crd-container-table'>
        <thead>
          <tr>
            <th>
              <CRDHeader
                device={device}
                lotGroup={lotGroup}
                isReportView={isReportView}
              />
            </th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td className='crd-signature-footer-spacer'></td>
          </tr>
        </tfoot>
        <tbody>
          <tr>
            <td>
              <div className='crd-body'>
                {isReportView ? (
                  <ReportViewWrapper device={device} />
                ) : (
                  <DetailView lotGroup={lotGroup} device={device} />
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className='crd-signature-footer'>
        <SignatureLine />
      </div>
    </>
  )
}
