import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'

import { Site } from 'js/models'
import { FormFeedback } from 'js/services/api'
import { partialUpdateSite } from 'js/services/site'
import { getDataFromForm } from 'js/utils/helpers'

export default function EditSiteModal(props: {
  site: Site
  show: boolean
  handleClose: () => void
  setSite: (site: Site) => void
}): React.ReactElement {
  const { site, show, handleClose, setSite } = props
  const [errors, setErrors] = useState({} as FormFeedback<Site>)

  const resetFormFeedback = (): void => setErrors({} as FormFeedback<Site>)

  const onSave = (e: React.FormEvent): void => {
    e.preventDefault()
    resetFormFeedback()

    const data = getDataFromForm(
      e.target,
      ['name', 'addressLine1', 'addressLine2', 'addressLine3'],
      'siteForm_'
    )

    partialUpdateSite(site.id, data as Partial<Site>)
      .then(r => {
        handleClose()
        setSite(r as Site)
      })
      .catch(e => setErrors(e.formErrors))
    // Lesser TODO: Show saved successfull message
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop='static'
      size='lg'
      scrollable
    >
      <Form onSubmit={onSave}>
        <Modal.Header>
          <Modal.Title>Edit Site</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group controlId='siteForm_name'>
                <Form.Label>Site Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Name'
                  defaultValue={site.name}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Label>Physical Address</Form.Label>
              <Form.Group controlId='siteForm_addressLine1'>
                <Form.Label hidden>Address Line 1</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Line 1'
                  defaultValue={site.addressLine1 || undefined}
                  isInvalid={!!errors.addressLine1}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.addressLine1}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='siteForm_addressLine2'>
                <Form.Label hidden>Address Line 2</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Line 2'
                  defaultValue={site.addressLine2 || undefined}
                  isInvalid={!!errors.addressLine2}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.addressLine2}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='siteForm_addressLine3'>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Country'
                  defaultValue={site.addressLine3 || undefined}
                  isInvalid={!!errors.addressLine3}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.addressLine3}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <p className='text-danger'>{errors.nonFieldErrors}</p>
          <p className='text-danger'>{errors.detail}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={(): void => {
              resetFormFeedback()
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' type='submit'>
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
