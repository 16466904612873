import { Login } from 'js/models'
import { makeCamelCase, setToken } from 'js/utils/helpers'
import api, { FormError } from './api'

/*=============================================
=                     GET                     =
=============================================*/

/*=============================================
=                    POST                     =
=============================================*/
export const SignIn = (
  email: string,
  password: string
): Promise<void | FormError<Login>> => {
  const data = { email: email.toLocaleLowerCase(), password: password }
  return api
    .post('/auth/login/', data)
    .then(r => {
      setToken(r.data.access_token)
    })
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

export const createPasswordReset = (email: string): Promise<string> => {
  const data = { email: email.toLocaleLowerCase() }
  return api.post('/auth/password/reset/', data).then(r => r.data.detail)
}

/*=============================================
=                    PATCH                    =
=============================================*/
