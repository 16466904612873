import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { useHistory } from 'react-router-dom'

import { Device } from 'js/models'
import { listDevices, listDevicesParams } from 'js/services/device'
import Paths from 'js/utils/paths'
import VQCPermissions from 'js/utils/permissions'

import RegisterDeviceModal from '../forms/RegisterDeviceModal'
import Loader from '../Loader'
import PaginationBar from '../PaginationBar'
import SearchBar from '../SearchBar'

export default function AdminDeviceTable(props: {
  listParams?: listDevicesParams
  order?: string
  refreshParent?: () => void
  numSites?: number
}): React.ReactElement {
  const { listParams, order, refreshParent, numSites } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [devices, setDevices] = useState<Device[]>()
  const [searchString, setSearchString] = useState('')
  const [showNewDevice, setShowNewDevice] = useState(false)
  const history = useHistory()

  const canEdit = VQCPermissions.canEditCustomerData()

  const handleShowNewDevice = (): void => setShowNewDevice(true)
  const handleCloseNewDevice = (): void => setShowNewDevice(false)

  useEffect(() => {
    refreshDevices()
  }, [currentPage, listParams, order, searchString])

  const search = (s: string): void => {
    setCurrentPage(1)
    setSearchString(s)
  }

  const refreshDevices = (): void => {
    listDevices({
      ...listParams,
      page: currentPage,
      order: order || '-active,model',
      search: searchString,
    }).then(r => {
      setDevices(r.results)
      setTotalPages(r.pageTotal)
    })
  }

  const renderTableRow = (device: Device): React.ReactElement => {
    return (
      <tr
        className='clickable'
        onClick={(): void =>
          history.push(Paths.getAdminDeviceDetailLink(device.id))
        }
        key={device.id}
      >
        <td>{device.model}</td>
        <td>{device.label || '–'}</td>
        <td>{device.organizationName || '–'}</td>
        <td>{device.siteName || '–'}</td>
        <td>{device.serialNumber || '–'}</td>
        <td className={!device.active ? 'text-danger' : 'text-success'}>
          {device.active ? 'Active' : 'Disabled'}
        </td>
      </tr>
    )
  }

  if (!devices) return <Loader />

  if (devices.length === 0 && !searchString && canEdit)
    return (
      <>
        <Alert variant='info'>
          <Alert.Heading>Registering Devices</Alert.Heading>
          <p>
            To submit data through the VQC program, first register all the
            devices you&apos;ll be using.
          </p>
          <p>
            Each device is registered to a specific site, and users can only
            submit data for devices at their sites. If you ever need to move a
            device to a different site, first disable it, then register it again
            at the new site.
          </p>
          <Button variant='info' onClick={handleShowNewDevice}>
            Register Your First Device
          </Button>
        </Alert>
        <RegisterDeviceModal
          show={showNewDevice}
          handleClose={handleCloseNewDevice}
          orgId={listParams?.organization}
          refreshDevices={(): void => {
            refreshParent && refreshParent()
            refreshDevices()
          }}
        />
      </>
    )

  return (
    <>
      <div className='table-top-bar'>
        <div className='table-top-section'>
          <SearchBar
            label={'Search'}
            placeholder={'Search by any field'}
            doSearch={search}
          />
        </div>
        {canEdit && (
          <div className='table-top-section'>
            <Button onClick={handleShowNewDevice}>Register Device</Button>
          </div>
        )}
      </div>
      {devices.length == 0 ? (
        <p>No devices to display</p>
      ) : (
        <>
          <Table striped hover className='table-header-primary'>
            <thead>
              <tr>
                <th>Model</th>
                <th>Label</th>
                <th>Organization</th>
                <th>Site</th>
                <th>Serial Number</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>{devices.map(device => renderTableRow(device))}</tbody>
          </Table>
          <PaginationBar
            pageTotal={totalPages}
            currentPage={currentPage}
            clickPage={setCurrentPage}
          />
        </>
      )}

      <RegisterDeviceModal
        show={showNewDevice}
        handleClose={handleCloseNewDevice}
        orgId={listParams?.organization}
        refreshDevices={(): void => {
          refreshParent && refreshParent()
          refreshDevices()
        }}
        numSites={numSites}
      />
    </>
  )
}
