import React from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { StepContentProps, Steps } from '.'

export default function RegistrationStart(
  props: StepContentProps
): React.ReactElement {
  return (
    <Card className='unauth-card'>
      <Card.Header>New Account</Card.Header>
      <Card.Body>
        <Button
          variant='outline-secondary'
          onClick={(): void => props.setStep(Steps.FindOrganization)}
          className='d-block m-auto w-100 mt-3'
        >
          Join existing organization&nbsp;&nbsp;&rarr;
        </Button>
        <Button
          variant='outline-secondary'
          onClick={(): void => props.setStep(Steps.CreateOrganization)}
          className='d-block m-auto w-100 mt-3 mb-3'
        >
          Register new organization&nbsp;&nbsp;&rarr;
        </Button>
      </Card.Body>
    </Card>
  )
}
