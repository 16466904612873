import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { User } from 'js/models'

export const removeUserModal = (
  user: User,
  showConfirmDelete: boolean,
  handleCloseConfirmDelete: () => void,
  removeUserFromOrganization: () => void
): React.ReactElement => {
  return (
    <Modal show={showConfirmDelete} onHide={handleCloseConfirmDelete}>
      <Modal.Header className='bg-danger'>
        <Modal.Title className='text-white'>
          Remove User from Organization
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {user.name} will no longer have access to the organization &quot;
        {user.organizationDetails.name}&quot;. Only ELITech Support will be able
        to re-add them to an organization.
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleCloseConfirmDelete}>
          Cancel
        </Button>
        <Button
          variant='outline-danger'
          onClick={(): void => {
            removeUserFromOrganization()
            handleCloseConfirmDelete()
          }}
        >
          Remove User
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
