import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { Control } from 'js/models'
import { deleteControl } from 'js/services/controls'
import Paths from 'js/utils/paths'

export default function DeleteControlTypeModal(props: {
  control: Control
  show: boolean
  handleClose: () => void
}): React.ReactElement {
  const { control, show, handleClose } = props
  const [errorMessage, setErrorMessage] = useState('')

  const onClickDelete = (): void => {
    setErrorMessage('')
    deleteControl(control.id)
      .then(() => {
        handleClose()
        window.open(Paths.adminControlTypes, '_self')
      })
      .catch(() => {
        setErrorMessage(
          'This control could not be deleted. Usually this is because there are device models already using it.'
        )
      })
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className='bg-danger'>
        <Modal.Title className='text-white'>Delete Control Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The control type {control.name} ({control.level} - {control.levelName}
          ) will be immediately deleted.
        </p>
        {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='outline-danger' onClick={onClickDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
