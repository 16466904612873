import { AddressInfo, User } from 'js/models'

// light, dark and danger are currenly set to the bootstrap defaults. If any of these values are changed or overridden in _variables.scss they should be updated here
export const COLORS = { primary: '#6d9e14', secondary: '#23638c', light: '#f8f9fa', dark: '#212529', danger: '#dc3545' }

// eslint-disable-next-line no-useless-escape
export const VIDEO_URL_PATTERN = '^(https?://)?(www.)?(youtube|vimeo|youtu\.?be)'

export const DEFAULT_TEXT_MAX_LENGTH = 250;

export const WEEKDAY_NAMES = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
export const MONTH_NAMES = {1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December'}

export const US_STATE_CODES = [{ value: "AL", label: "Alabama" }, { value: "AK", label: "Alaska" }, { value: "AZ", label: "Arizona" }, { value: "AR", label: "Arkansas" }, { value: "CA", label: "California" }, { value: "CO", label: "Colorado" }, { value: "CT", label: "Connecticut" }, { value: "DE", label: "Delaware" }, { value: "DC", label: "District of Columbia" }, { value: "FL", label: "Florida" }, { value: "GA", label: "Georgia" }, { value: "HI", label: "Hawaii" }, { value: "ID", label: "Idaho" }, { value: "IL", label: "Illinois" }, { value: "IN", label: "Indiana" }, { value: "IA", label: "Iowa" }, { value: "KS", label: "Kansas" }, { value: "KY", label: "Kentucky" }, { value: "LA", label: "Louisiana" }, { value: "ME", label: "Maine" }, { value: "MD", label: "Maryland" }, { value: "MA", label: "Massachusetts" }, { value: "MI", label: "Michigan" }, { value: "MN", label: "Minnesota" }, { value: "MS", label: "Mississippi" }, { value: "MO", label: "Missouri" }, { value: "MT", label: "Montana" }, { value: "NE", label: "Nebraska" }, { value: "NV", label: "Nevada" }, { value: "NH", label: "New Hampshire" }, { value: "NJ", label: "New Jersey" }, { value: "NM", label: "New Mexico" }, { value: "NY", label: "New York" }, { value: "NC", label: "North Carolina" }, { value: "ND", label: "North Dakota" }, { value: "OH", label: "Ohio" }, { value: "OK", label: "Oklahoma" }, { value: "OR", label: "Oregon" }, { value: "PA", label: "Pennsylvania" }, { value: "PR", label: "Puerto Rico" }, { value: "RI", label: "Rhode Island" }, { value: "SC", label: "South Carolina" }, { value: "SD", label: "South Dakota" }, { value: "TN", label: "Tennessee" }, { value: "TX", label: "Texas" }, { value: "UT", label: "Utah" }, { value: "VT", label: "Vermont" }, { value: "VA", label: "Virginia" }, { value: "WA", label: "Washington" }, { value: "WV", label: "West Virginia" }, { value: "WI", label: "Wisconsin" }, { value: "WY", label: "Wyoming" }]


/*----------  SUBMIT DATA  ----------*/
export enum WorkingTimeOptions {fifteen="15", thirty="30", fortyFive="45"}
export const TimeTempTests = ['ESR']

/*----------  ORGANIZATIONS  ----------*/
export enum ApprovedStatus { pending="Pending", approved="Approved", denied="Denied" }

/*----------  LOCALIZATION OPTIONS  ----------*/
export enum DateTimeStyle { medium="medium", full="full" , long="long", short="short" }
export enum NumericalStyle { currency="currency", decimal="decimal" , percent="percent" }

/*----------  DEFAULT MODELS  ----------*/
export const DEFAULT_USER:Partial<User> = {
  sites: [],
  siteNames: [],
  groups: [],
}

/*----------  DEFAULT MODELS  ----------*/
export const ELITechPhone = '1-800-453-2725'
export const ELITechEmail = 'vqc@elitechgroup.com'
export const ELITechAddress = {name: 'ELITech VQC Support', addressLine1: '370 W 1700 S', addressLine2: 'Logan, UT 84321', addressLine3: 'United States'} as AddressInfo
