import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { deleteLot } from 'js/services/lot'

export const deleteLotModal = (
  lotId: number,
  showConfirmDelete: boolean,
  handleCloseConfirmDelete: () => void
): React.ReactElement => {
  return (
    <Modal show={showConfirmDelete} onHide={handleCloseConfirmDelete}>
      <Modal.Header className='bg-danger'>
        <Modal.Title className='text-white'>
          Delete Lot from Lot Group
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        This lot will immediatedly be deleted from the lot group.
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleCloseConfirmDelete}>
          Cancel
        </Button>
        <Button
          variant='outline-danger'
          onClick={(): void => {
            deleteLot(lotId).then(() => location.reload())
          }}
        >
          Delete Lot
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
