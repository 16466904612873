import React, { useEffect, useState } from 'react'
import Badge from 'react-bootstrap/Badge'
import Table from 'react-bootstrap/Table'
import { useHistory } from 'react-router-dom'

import { User } from 'js/models'
import { listUsers, listUsersParams } from 'js/services/user'
import Paths from 'js/utils/paths'

import Loader from '../Loader'
import PaginationBar from '../PaginationBar'
import SearchBar from '../SearchBar'

export default function AdminUserTable(props: {
  listParams?: listUsersParams
  order?: string
}): React.ReactElement {
  const { listParams, order } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [users, setUsers] = useState<User[]>()
  const [searchString, setSearchString] = useState('')
  const history = useHistory()

  useEffect(() => {
    listUsers({
      ...listParams,
      page: currentPage,
      order: order || 'name',
      search: searchString,
    }).then(r => {
      setUsers(r.results)
      setTotalPages(r.pageTotal)
    })
  }, [currentPage, listParams, order, searchString])

  const search = (s: string): void => {
    setCurrentPage(1)
    setSearchString(s)
  }

  const renderTableRow = (user: User): React.ReactElement => {
    return (
      <tr
        className='clickable'
        onClick={(): void => {
          history.push(Paths.getAdminUserDetailLink(user.id))
        }}
        key={user.id}
      >
        <td>
          {user.name || '-'}
          {user.groups.length > 0 && (
            <Badge className='ms-2 bg-primary'>Admin</Badge>
          )}
        </td>
        <td>{user.email}</td>
        <td>{user.organizationDetails?.name || '-'}</td>
      </tr>
    )
  }

  if (!users) return <Loader />

  return (
    <>
      <div className='table-top-bar'>
        <div className='table-top-section'>
          <SearchBar
            label={'Search'}
            placeholder={'Search by name or email'}
            doSearch={search}
          />
        </div>
      </div>
      {users.length == 0 ? (
        <p>No users to display</p>
      ) : (
        <>
          <Table striped hover className='table-header-primary'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Organization</th>
              </tr>
            </thead>
            <tbody>{users.map(user => renderTableRow(user))}</tbody>
          </Table>
          <PaginationBar
            pageTotal={totalPages}
            currentPage={currentPage}
            clickPage={setCurrentPage}
          />
        </>
      )}
    </>
  )
}
