import React from 'react'
import Nav from 'react-bootstrap/Nav'
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer'

import Paths from 'js/utils/unauthorizedPaths'

import HeaderWrapper from './HeaderWrapper'

export default function Header(): React.ReactElement {
  return (
    <HeaderWrapper>
      <LinkContainer to={Paths.signIn}>
        <Nav.Link>Sign In</Nav.Link>
      </LinkContainer>
      <LinkContainer to={Paths.bareRegistrationLink}>
        <Nav.Link>Register</Nav.Link>
      </LinkContainer>
    </HeaderWrapper>
  )
}
