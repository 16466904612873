// prettier-ignore
import { BoxAnnotationOptions, LineAnnotationOptions } from 'chartjs-plugin-annotation'
import pick from 'lodash/pick'
import { DeviceEntry } from 'js/models'
import { COLORS } from 'js/utils/constants'
import { getNumDaysInMonth, round } from 'js/utils/helpers'
import { getLineAnnotation, getAnnotationLabel } from '../chart-utils'
import { DeviceEntryDataDictionary } from './models'

const BoxOptions = {
  drawTime: 'beforeDatasetsDraw',
  type: 'box',
  backgroundColor: `${COLORS.secondary}66`,
  borderColor: `#00000000`,
} as BoxAnnotationOptions

// Map device types to a dictionary with day of month as the key
const mapDeviceEntriesByDate = (
  deviceEntries: DeviceEntry[]
): DeviceEntryDataDictionary => {
  if (!deviceEntries || deviceEntries.length < 1) return {}

  const dateDict = {} as DeviceEntryDataDictionary
  deviceEntries.forEach(x => {
    const dayKey = new Date(x.date).getUTCDate()
    if (!dateDict[dayKey]) dateDict[dayKey] = { devices: [] }
    dateDict[dayKey].devices.push(pick(x, ['id', 'value', 'device']))
  })

  Object.keys(dateDict).forEach(k => {
    dateDict[k].dayAvg =
      dateDict[k].devices.reduce((total, next) => total + next.value, 0) /
      dateDict[k].devices.length
  })

  return dateDict
}

const getLabels = (year: number, month: number): number[] => {
  const daysInMonth = getNumDaysInMonth(year, month)
  return Array.from({ length: daysInMonth }, (_, index) => index + 1)
}

const getMeanLineAnnotation = (val: number): LineAnnotationOptions => {
  const label = getAnnotationLabel(`Mean (${round(val)})`)
  return getLineAnnotation({ id: 'mean', value: val, ...label })
}

const getSDLineAnnotation = (
  val: number,
  direction: '+' | '-'
): LineAnnotationOptions => {
  const label = getAnnotationLabel(`${direction}2 SD (${round(val)})`)
  return getLineAnnotation({
    id: `std${direction}2`,
    value: val,
    borderDash: [10, 5],
    ...label,
  })
}

const getTopBgAnnotation = (val: number): LineAnnotationOptions => {
  return { id: 'top-bg', yMin: val, ...Utils.BoxOptions }
}

const getBottomBgAnnotation = (val: number): LineAnnotationOptions => {
  return { id: 'bottom-bg', yMax: val, ...Utils.BoxOptions }
}

const Utils = {
  BoxOptions,
  mapDeviceEntriesByDate,
  getLabels,
  getMeanLineAnnotation,
  getSDLineAnnotation,
  getTopBgAnnotation,
  getBottomBgAnnotation,
}
export default Utils
