import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import { useHistory } from 'react-router-dom'
import TimePicker, { TimePickerValue } from 'react-time-picker'

import { buttonTextOptions } from '.'
import { Control, Device, DeviceEntry, Lot, LotGroup } from 'js/models'
import { FormErrorArray, MAX_REQUEST_SIZE } from 'js/services/api'
//prettier-ignore
import { listDeviceEntries, uploadBulkEntry, UploadDeviceEntryParams } from 'js/services/deviceEntry'
import { WorkingTimeOptions } from 'js/utils/constants'
import { getNumDaysInMonth } from 'js/utils/helpers'
import Paths from 'js/utils/paths'

type DataForControl = Array<{ time: string | null; value: number | null }>

const enteredData: Map<number, DataForControl> = new Map()

export default function UploadManual(props: {
  lotGroup: LotGroup
  controls: Control[]
  selectedDevice: Device
  selectedWorkingTime?: WorkingTimeOptions
  selectedTempCorrection?: boolean | undefined
}): React.ReactElement {
  const {
    lotGroup,
    controls,
    selectedDevice,
    selectedWorkingTime,
    selectedTempCorrection,
  } = props
  const [errorText, setErrorText] = useState<string>()
  const [month, setMonth] = useState<number | null>(null)
  const [year, setYear] = useState<number | null>(null)
  const [useTime, setUseTime] = useState(false)
  const [showPrevEntries, setShowPrevEntries] = useState(true)
  const [prevEntries, setPrevEntries] = useState<DeviceEntry[]>()
  const [buttonText, setButtonText] = useState(buttonTextOptions.ready)
  const history = useHistory()

  const getLotByControlId = (controlId: number): Lot | null => {
    return lotGroup.lots.find(lot => lot.control === controlId) ?? null
  }

  useEffect(() => {
    enteredData.clear()

    if (!month || !year) return

    listDeviceEntries({
      lot__group: lotGroup.id,
      device: selectedDevice.id,
      month: month,
      year: year,
      count: MAX_REQUEST_SIZE,
    }).then(r => setPrevEntries(r.results))

    controls.forEach(control => {
      const emptyDataArray: DataForControl = new Array(
        getNumDaysInMonth(year, month)
      )
        .fill(null)
        .map(() => ({ time: null, value: null }))
      enteredData.set(control.id, emptyDataArray)
    })
  }, [month, year])

  const upload = (e): void => {
    e.preventDefault()
    setErrorText('')

    if (!year || !month) {
      setErrorText('Unable to upload data. Please select a month and year.')
      return
    }

    setButtonText(buttonTextOptions.uploading)

    const formattedData: UploadDeviceEntryParams[] = []

    enteredData.forEach((data, controlId) => {
      data.forEach((d, idx) => {
        if (d.value != null)
          formattedData.push({
            date: `${year}-${(month as number).toString().padStart(2, '0')}-${(
              idx + 1
            )
              .toString()
              .padStart(2, '0')}`,
            time: d.time,
            value: d.value,
            temperatureCorrected: selectedTempCorrection ?? null,
            workingTime: selectedWorkingTime ?? null,
            lot: (getLotByControlId(controlId) as Lot).id,
            device: selectedDevice.id,
          })
      })
    })

    uploadBulkEntry(formattedData)
      .then(() => {
        setButtonText(buttonTextOptions.success)
        setTimeout(() => {
          history.push(
            Paths.getControlRunDetailLink({
              year: year as number,
              month: month as number,
              deviceId: selectedDevice.id,
              lotGroupId: lotGroup.id,
            })
          )
        }, 1000)
      })
      .catch((e: FormErrorArray<UploadDeviceEntryParams>) => {
        console.error(e.formErrors)
        setButtonText(buttonTextOptions.ready)
        const error = e.formErrors.find(e => e.nonFieldErrors !== null)
        if (error) setErrorText(error.nonFieldErrors)
      })
  }

  const updateTime = (
    controlId: number,
    day: number,
    newTime: TimePickerValue | null
  ): void => {
    const temp = enteredData.get(controlId) as DataForControl
    temp[day].time = newTime != null ? newTime.toString() : null
    enteredData.set(controlId, temp)
  }

  const updateValue = (
    controlId: number,
    day: number,
    newValue: string
  ): void => {
    const temp = enteredData.get(controlId) as DataForControl
    temp[day].value = newValue ? parseFloat(newValue) : null
    enteredData.set(controlId, temp)
  }

  const renderSubheaderCells = (): React.ReactElement[] => {
    const cells: React.ReactElement[] = []
    cells.push(<th>&nbsp;</th>)
    controls.forEach(() => {
      useTime && cells.push(<th>Time</th>)
      cells.push(<th>Value</th>)
      showPrevEntries && cells.push(<th>Previous Data</th>)
    })
    return cells
  }

  const renderTableRows = (): React.ReactElement[] => {
    const rows: React.ReactElement[] = []
    const numDays = getNumDaysInMonth(year as number, month as number)
    for (let day = 0; day < numDays; day++) {
      rows.push(
        <tr key={`${year}-${month}-${day}`}>
          <td>{day + 1}</td>
          {controls.map(control => {
            const cells: React.ReactElement[] = []

            useTime &&
              cells.push(
                <td key={`${control.id}-time`}>
                  <Form.Group className='manualForm_timeInput mb-0'>
                    <TimePicker
                      value=''
                      onChange={(time): void =>
                        updateTime(control.id, day, time)
                      }
                      format='HH:mm'
                      clearIcon={null}
                      disableClock
                    />
                  </Form.Group>
                </td>
              )

            cells.push(
              <td key={`${control.id}-value`}>
                <Form.Group className='manualForm_valueInput mb-0'>
                  <Form.Control
                    type='number'
                    onChange={(e): void =>
                      updateValue(control.id, day, e.target.value)
                    }
                    placeholder='Value'
                  />
                </Form.Group>
              </td>
            )

            showPrevEntries &&
              cells.push(
                <td>
                  {prevEntries
                    ?.filter(
                      entry =>
                        parseInt(entry.date.split('-')[2]) === day + 1 &&
                        entry.level === control.level
                    )
                    .map(entry => entry.value)
                    .join(', ')}
                </td>
              )

            return cells
          })}
        </tr>
      )
    }
    return rows
  }

  return (
    <>
      <Form.Group className='w-50'>
        <Form.Label>Month</Form.Label>
        <Form.Control
          type='month'
          onChange={(e): void => {
            setYear(parseInt(e.target.value.split('-')[0]))
            setMonth(parseInt(e.target.value.split('-')[1]))
          }}
        />
      </Form.Group>
      {year !== null && month !== null && (
        <>
          <Form.Check
            type='checkbox'
            label='Enter times'
            defaultChecked={useTime}
            onChange={(e): void => setUseTime(e.target.checked)}
          />
          <Form.Check
            type='checkbox'
            label='Show previously submitted data'
            defaultChecked={showPrevEntries}
            onChange={(e): void => setShowPrevEntries(e.target.checked)}
            className='mb-2'
          />
          <Table
            striped
            className={`table-header-primary ${
              useTime || showPrevEntries ? 'w-75' : 'w-50'
            }`}
          >
            <thead>
              <tr>
                <th>&nbsp;</th>
                {controls.map(c => (
                  <th
                    colSpan={1 + (useTime ? 1 : 0) + (showPrevEntries ? 1 : 0)}
                    key={c.level}
                    className='text-center'
                  >{`${c.level} - ${c.levelName}`}</th>
                ))}
              </tr>
              <tr className='small'>{renderSubheaderCells()}</tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </Table>

          <p className='text-danger'>{errorText}</p>
          <Button
            onClick={upload}
            disabled={buttonText !== buttonTextOptions.ready}
          >
            {buttonText}
          </Button>
        </>
      )}
    </>
  )
}
