import React, { useState } from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'

import { Device } from 'js/models'
import { partialUpdateDevice } from 'js/services/device'

export default function DisableDeviceModal(props: {
  device: Device
  show: boolean
  handleClose: () => void
  onDisable: () => void
}): React.ReactElement {
  const { device, show, handleClose, onDisable } = props
  const [errorMessage, setErrorMessage] = useState('')

  const onClickDisable = (): void => {
    setErrorMessage('')
    partialUpdateDevice(device.id, {
      active: false,
    })
      .then(() => {
        handleClose()
        onDisable()
      })
      .catch(() => {
        setErrorMessage('There was an issue trying to disable this device.')
      })
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className='bg-danger'>
        <Modal.Title className='text-white'>Disable Device</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The device {device.label} will be immediately disabled. Only ELITech
          support can undo this action.
        </p>
        <p>
          If you are moving this device to another site, first disable it, then
          re-register it for the other site.
        </p>
        {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={(): void => {
            setErrorMessage('')
            handleClose()
          }}
        >
          Cancel
        </Button>
        <Button variant='outline-danger' onClick={onClickDisable}>
          Disable
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
