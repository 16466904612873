import React from 'react'

import AdminUserTable from 'js/components/tables/AdminUserTable'

export default function AdminUsers(): React.ReactElement {
  return (
    <>
      <h1>Users</h1>
      <AdminUserTable />
    </>
  )
}
