import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { useHistory } from 'react-router-dom'

import PaginationBar from 'js/components/PaginationBar'
import SearchBar from 'js/components/SearchBar'

import { Control } from 'js/models'
import { listControls } from 'js/services/controls'
import { DeviceTypeDict, getDeviceTypesDict } from 'js/services/deviceType'
import Paths from 'js/utils/paths'
import VQCPermissions from 'js/utils/permissions'

import CreateControlTypeModal from './CreateControlTypeModal'

export default function AdminControlTypes(): React.ReactElement {
  const [deviceTypesDict, setDeviceTypesDict] = useState<DeviceTypeDict>({})
  const [controlsList, setControlsList] = useState<Control[]>([])
  const [showCreate, setShowCreate] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [searchString, setSearchString] = useState('')
  const history = useHistory()

  const canEdit = VQCPermissions.canEditElitechData()

  const handleShowCreate = (): void => setShowCreate(true)
  const handleCloseCreate = (): void => setShowCreate(false)

  useEffect(() => {
    getDeviceTypesDict().then(r => setDeviceTypesDict(r))
    listControls({
      page: currentPage,
      order: 'test_name,name,level',
      search: searchString,
    }).then(r => {
      setControlsList(r.results)
      setTotalPages(r.pageTotal)
    })
  }, [currentPage, searchString])

  const search = (s: string): void => {
    setCurrentPage(1)
    setSearchString(s)
  }

  const renderTableRow = (control: Control): React.ReactElement => {
    const deviceTypeNames = control.deviceTypes.map(dt =>
      deviceTypesDict[dt] ? deviceTypesDict[dt].name : dt
    )
    return (
      <tr
        className='clickable'
        onClick={(): void => {
          history.push(Paths.getAdminControlTypeDetailLink(control.id))
        }}
        key={control.id}
      >
        <td>{control.name}</td>
        <td>{control.testName}</td>
        <td>
          {control.level} - {control.levelName}
        </td>
        <td>{deviceTypeNames.join(', ')}</td>
      </tr>
    )
  }

  return (
    <div>
      <h1>Control Types</h1>

      {controlsList.length == 0 ? (
        <>
          <p>No control types to display</p>
          <Button variant='primary' onClick={handleShowCreate}>
            Create Control Type
          </Button>
        </>
      ) : (
        <>
          <div className='table-top-bar'>
            <div className='table-top-section'>
              <SearchBar
                label={'Search'}
                placeholder={'Search by name, test type, or level'}
                doSearch={search}
              />
            </div>
            <div className='table-top-section'>
              {canEdit && (
                <Button variant='primary' onClick={handleShowCreate}>
                  Create Control Type
                </Button>
              )}
            </div>
          </div>
          <Table striped hover className='table-header-primary'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Test Type</th>
                <th>Level</th>
                <th>Device Models</th>
              </tr>
            </thead>
            <tbody>{controlsList.map(c => renderTableRow(c))}</tbody>
          </Table>
          <PaginationBar
            pageTotal={totalPages}
            currentPage={currentPage}
            clickPage={setCurrentPage}
          />
        </>
      )}
      <CreateControlTypeModal
        show={showCreate}
        handleClose={handleCloseCreate}
      />
    </div>
  )
}
