import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import { DeviceType } from 'js/models'
import { FormFeedback } from 'js/services/api'
import { createDeviceType } from 'js/services/deviceType'
import { getDataFromForm } from 'js/utils/helpers'

export default function CreateDeviceTypeModal(props: {
  show: boolean
  handleClose: () => void
  refreshDeviceTypes?: () => void
}): React.ReactElement {
  const { show, handleClose, refreshDeviceTypes } = props
  const [errors, setErrors] = useState({} as FormFeedback<DeviceType>)

  const resetFormFeedback = (): void =>
    setErrors({} as FormFeedback<DeviceType>)

  const onSave = (e: React.FormEvent): void => {
    e.preventDefault()
    const data = getDataFromForm(e.target, ['name'], 'deviceTypeForm_')
    resetFormFeedback()

    createDeviceType(data as Partial<DeviceType>)
      .then(() => {
        handleClose()
        if (refreshDeviceTypes) refreshDeviceTypes()
        else location.reload()
      })
      .catch(e => setErrors(e.formErrors))
    // Lesser TODO: Show saved successfull message
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop='static' scrollable>
      <Form onSubmit={onSave}>
        <Modal.Header>
          <Modal.Title>New Device Model</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId='deviceTypeForm_name'>
            <Form.Label>Model Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Name'
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <p className='text-danger'>{errors.nonFieldErrors}</p>
          <p className='text-danger'>{errors.detail}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={(): void => {
              resetFormFeedback()
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' type='submit'>
            Register
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
