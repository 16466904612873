import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import { DeviceType } from 'js/models'
import { FormFeedback } from 'js/services/api'
import { getDeviceType, partialUpdateDeviceType } from 'js/services/deviceType'
import { getDataFromForm } from 'js/utils/helpers'

import Loader from '../Loader'

export default function EditDeviceModal(props: {
  deviceTypeId: number
  show: boolean
  handleClose: () => void
  handleShowDelete: () => void
  refreshDeviceTypes: () => void
}): React.ReactElement {
  const {
    deviceTypeId,
    show,
    handleClose,
    handleShowDelete,
    refreshDeviceTypes,
  } = props
  const [deviceType, setDeviceType] = useState<DeviceType>()
  const [errors, setErrors] = useState({} as FormFeedback<DeviceType>)

  useEffect(() => {
    if (show) getDeviceType(deviceTypeId).then(r => setDeviceType(r))
  }, [show])

  const resetFormFeedback = (): void =>
    setErrors({} as FormFeedback<DeviceType>)

  const onSave = (e: React.FormEvent): void => {
    e.preventDefault()
    const data = getDataFromForm(e.target, ['name'], 'deviceTypeForm_')
    resetFormFeedback()

    partialUpdateDeviceType((deviceType as DeviceType).id, data as DeviceType)
      .then(() => {
        handleClose()
        if (refreshDeviceTypes) refreshDeviceTypes()
        else location.reload()
      })
      .catch(e => setErrors(e.formErrors))
    // Lesser TODO: Show saved successfull message
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop='static' scrollable>
      <Form onSubmit={onSave}>
        <Modal.Header>
          <Modal.Title>Edit Model</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!deviceType || !errors ? (
            <Loader />
          ) : (
            <>
              <Form.Group controlId='deviceTypeForm_name'>
                <Form.Label>Model Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Name'
                  defaultValue={deviceType.name}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <p className='text-danger'>{errors.nonFieldErrors}</p>
              <p className='text-danger'>{errors.detail}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='flex-grow-1'>
            <Button
              variant='outline-danger'
              onClick={(): void => {
                setDeviceType(undefined)
                handleClose()
                setTimeout(() => handleShowDelete(), 200)
              }}
            >
              Delete
            </Button>
          </div>
          <Button
            variant='secondary'
            onClick={(): void => {
              setDeviceType(undefined)
              resetFormFeedback()
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' type='submit'>
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
