import React from 'react'
import Pagination from 'react-bootstrap/Pagination'

export default function PaginationBar(props: {
  pageTotal: number
  currentPage: number
  clickPage: (page: number) => void
}): React.ReactElement {
  const { pageTotal, currentPage, clickPage } = props

  if (pageTotal <= 1) return <></>

  const paginationItem = (index: number): React.ReactElement => {
    return (
      <Pagination.Item
        key={index}
        active={currentPage == index}
        onClick={(): void => clickPage(index)}
      >
        {index}
      </Pagination.Item>
    )
  }

  const listPagesEllipsis = (): React.ReactElement[] => {
    const pages: React.ReactElement[] = []
    const startNumbering = Math.max(1, currentPage - 2)
    const endNumbering = Math.min(pageTotal, currentPage + 2)
    if (currentPage - 2 > 1) pages.push(paginationItem(1))
    if (currentPage - 2 > 2)
      pages.push(<Pagination.Ellipsis key='lelip' disabled />)
    for (let i = startNumbering; i <= endNumbering; i++) {
      pages.push(paginationItem(i))
    }
    if (currentPage + 2 < pageTotal - 1)
      pages.push(<Pagination.Ellipsis key='relip' disabled />)
    if (currentPage + 2 < pageTotal) pages.push(paginationItem(pageTotal))
    return pages
  }

  const listAllPages = (): React.ReactElement[] => {
    const pages: React.ReactElement[] = []
    for (let i = 1; i <= pageTotal; i++) {
      pages.push(paginationItem(i))
    }
    return pages
  }

  if (pageTotal == 0) return <></>

  return (
    <Pagination>
      <Pagination.Prev
        onClick={(): void => clickPage(currentPage - 1)}
        disabled={currentPage == 1}
        key='prev'
      />
      {pageTotal <= 7 ? listAllPages() : listPagesEllipsis()}
      <Pagination.Next
        onClick={(): void => clickPage(currentPage + 1)}
        disabled={currentPage == pageTotal}
        key='next'
      />
    </Pagination>
  )
}
