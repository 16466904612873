import { DeviceType } from 'js/models'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'
import api, { BaseQueryParams, FormPromise, Paginated } from './api'

export interface listDeviceTypesParams extends BaseQueryParams {
  name?: string
  name__icontains?: string
  model?: string
  model__icontains?: string
}

export type DeviceTypeDict = { [id: number]: DeviceType }

/*=============================================
=                     GET                     =
=============================================*/
export const listDeviceTypes = (
  params?: listDeviceTypesParams
): Promise<Paginated<DeviceType>> => {
  return api
    .get('/devicetypes/', { params: params })
    .then(r => makeCamelCase(r.data))
}

export const getDeviceType = (id: number): Promise<DeviceType> => {
  return api.get(`/devicetypes/${id}/`).then(r => makeCamelCase(r.data))
}

export const getDeviceTypesDict = (
  params?: listDeviceTypesParams
): Promise<DeviceTypeDict> => {
  return listDeviceTypes(params).then(r => {
    // Map device types to a dictionary with id as the key
    const mappedDeviceTypes = Object.assign(
      {},
      ...r.results.map(x => ({ [x.id]: x }))
    )
    return mappedDeviceTypes
  })
}

/*=============================================
=                    POST                     =
=============================================*/
export const createDeviceType = (
  data: Partial<DeviceType>
): FormPromise<DeviceType> => {
  return api
    .post('/devicetypes/', makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as DeviceType)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                    PATCH                    =
=============================================*/

export const partialUpdateDeviceType = (
  id: number,
  data: Partial<DeviceType>
): FormPromise<DeviceType> => {
  return api
    .patch(`/devicetypes/${id}/`, makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as DeviceType)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                     DELETE                  =
=============================================*/
export const deleteDeviceType = (id: number): Promise<Response> => {
  return api.delete(`/devicetypes/${id}/`)
}
