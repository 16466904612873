import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'

import Loader from 'js/components/Loader'

import { Control, DeviceType } from 'js/models'
import { MAX_REQUEST_SIZE } from 'js/services/api'
import { partialUpdateControl } from 'js/services/controls'
import { listDeviceTypes } from 'js/services/deviceType'
import VQCPermissions from 'js/utils/permissions'

export default function DeviceTypesTable(props: {
  deviceTypeIdList: number[]
  controlId: number
  setControlType: (control: Control) => void
}): React.ReactElement {
  const { deviceTypeIdList, controlId, setControlType } = props

  const [allDeviceTypes, setAllDeviceTypes] = useState<DeviceType[]>()
  const [unchosenDeviceTypes, setUnchosenDeviceTypes] = useState<DeviceType[]>(
    []
  )
  const [errorMessage, setErrorMessage] = useState('')

  const canEdit = VQCPermissions.canEditElitechData()

  useEffect(() => {
    listDeviceTypes({ order: 'name', count: MAX_REQUEST_SIZE }).then(r => {
      setAllDeviceTypes(r.results)
      setUnchosenDeviceTypes(
        r.results.filter(
          deviceType => !deviceTypeIdList.includes(deviceType.id)
        )
      )
    })
  }, [deviceTypeIdList])

  if (!allDeviceTypes) return <Loader />

  if (allDeviceTypes.length == 0) return <p>No available device models</p>

  const removeDeviceType = (deviceTypeId: number): void => {
    const index = deviceTypeIdList.indexOf(deviceTypeId)
    deviceTypeIdList.splice(index, 1)
    partialUpdateControl(controlId, { deviceTypes: deviceTypeIdList }).then(r =>
      setControlType(r as Control)
    )
  }

  const addDeviceType = (deviceTypeId: number): void => {
    deviceTypeIdList.push(deviceTypeId)
    partialUpdateControl(controlId, { deviceTypes: deviceTypeIdList }).then(r =>
      setControlType(r as Control)
    )
  }

  return (
    <>
      <h2>Device Models</h2>
      <p>Data from these device models can be submitted to this lot</p>
      {deviceTypeIdList.length == 0 ? (
        <p className='pb-4'>No models to display</p>
      ) : (
        <Table striped className='table-header-primary'>
          <thead>
            <tr>
              <td>Model</td>
              {canEdit && <td>&nbsp;</td>}
            </tr>
          </thead>
          <tbody>
            {deviceTypeIdList.map(x => {
              const dt = allDeviceTypes.find(e => e.id === x) as DeviceType
              return (
                <tr key={`device-type-row-${dt.id}`}>
                  <td>{dt.name}</td>
                  {canEdit && (
                    <td
                      className='clickable'
                      onClick={(): void => removeDeviceType(dt.id)}
                    >
                      <strong>&times;</strong>
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </Table>
      )}
      {canEdit && unchosenDeviceTypes.length > 0 && (
        <Form
          onSubmit={(e): void => {
            e.preventDefault()
            setErrorMessage('')
            const deviceTypeId = (
              document.querySelector('#deviceTypePicker') as HTMLInputElement
            ).value
            if (parseInt(deviceTypeId)) {
              addDeviceType(parseInt(deviceTypeId))
              ;(e.target as HTMLFormElement).reset()
            } else setErrorMessage('Select a device model to add')
          }}
        >
          <Form.Group>
            <Form.Label>Add Model</Form.Label>
            <Form.Control
              as='select'
              id='deviceTypePicker'
              defaultValue='default'
            >
              <option value='default' disabled>
                -- Select Model --
              </option>
              {unchosenDeviceTypes
                .sort((a, b) => {
                  return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
                })
                .map(({ id, name }) => (
                  <option key={`add-dt-option-${id}`} value={id}>
                    {name}
                  </option>
                ))}
            </Form.Control>
            <p className='text-danger'>{errorMessage}</p>
            <Button className='mt-2' type='submit'>
              Add Model
            </Button>
          </Form.Group>
        </Form>
      )}
    </>
  )
}
