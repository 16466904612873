import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

import { Organization } from 'js/models'
//prettier-ignore
import { createOrganization, retrieveByOrganizationCode } from 'js/services/organization'
import { partialUpdateSelf } from 'js/services/user'
import { setToken } from 'js/utils/helpers'
import Paths from 'js/utils/paths'

import { StepContentProps } from '.'

interface SJCProps extends StepContentProps {
  token: string
  organizationCode?: string
  organizationData?: Partial<Organization>
}

export default function RegistrationFinish(
  props: SJCProps
): React.ReactElement {
  const [organization, setOrganization] = useState<Organization>()

  useEffect(() => {
    if (props.organizationCode)
      retrieveByOrganizationCode(props.organizationCode).then(r =>
        setOrganization(r)
      )
  }, [])

  const onNext = (e): void => {
    e.preventDefault()
    setToken(props.token)

    // Joining existing organization
    if (props.organizationCode)
      partialUpdateSelf({
        newOrganization: props.organizationCode,
      }).then(() => (window.location.href = Paths.home))
    // Creating new organization
    else if (props.organizationData)
      createOrganization(props.organizationData).then(
        () => (window.location.href = Paths.home)
      )
    else {
      throw new Error(
        'Neither an existing organization code nor new organization data were provided.'
      )
    }
  }

  return (
    <Card className='unauth-card'>
      <Card.Header>Thanks!</Card.Header>
      <Card.Body>
        <p>
          We&apos;ve created your account, and you will be added to{' '}
          {props.organizationData?.name || organization?.name} organization.
        </p>
        <div className='d-flex justify-content-around mt-4'>
          <Button variant='secondary' href='/' onClick={onNext}>
            Finish
          </Button>
        </div>
      </Card.Body>
    </Card>
  )
}
