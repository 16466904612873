import React from 'react'

import AdminDeviceTypeTable from 'js/components/tables/AdminDeviceTypeTable'

export default function AdminDeviceTypes(): React.ReactElement {
  return (
    <>
      <h1>Device Models</h1>

      <AdminDeviceTypeTable />
    </>
  )
}
