import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { deleteDeviceType } from 'js/services/deviceType'

export default function DeleteDeviceTypeModal(props: {
  deviceTypeId: number
  show: boolean
  handleClose: () => void
  refreshDeviceTypes: () => void
}): React.ReactElement {
  const { deviceTypeId, show, handleClose, refreshDeviceTypes } = props
  const [errorMessage, setErrorMessage] = useState('')

  const onClickDelete = (): void => {
    setErrorMessage('')
    deleteDeviceType(deviceTypeId)
      .then(() => {
        handleClose()
        if (refreshDeviceTypes) refreshDeviceTypes()
        else location.reload()
      })
      .catch(() => {
        setErrorMessage(
          'This model could not be deleted. Usually this is because there are existing devices registered as this model.'
        )
      })
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop='static' scrollable>
      <Modal.Header className='bg-danger'>
        <Modal.Title className='text-white'>Delete Model</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this device model?</p>
        {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={(): void => {
            setErrorMessage('')
            handleClose()
          }}
        >
          Cancel
        </Button>
        <Button variant='outline-danger' onClick={onClickDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
