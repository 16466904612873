import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import InfoPiece from 'js/components/InfoPiece'

import { DeviceSummary, LotGroup } from 'js/models'
import { formatNumber } from 'js/utils/helpers'

import { DeleteSummaryModal } from './Modals'

export default function SummaryData(props: {
  deviceSummary?: DeviceSummary
  lotGroup: LotGroup
  remove: (number) => void
}): React.ReactElement {
  const { deviceSummary, lotGroup, remove } = props
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const handleShowConfirmDelete = (): void => setShowConfirmDelete(true)
  const handleCloseConfirmDelete = (): void => setShowConfirmDelete(false)

  if (!deviceSummary) return <p>No summary data to display</p>

  return (
    <div>
      <Row>
        <Col>
          <InfoPiece label='Number of Runs'>{deviceSummary.count}</InfoPiece>
          <InfoPiece label='Mean Average'>
            {formatNumber(deviceSummary.average)}
          </InfoPiece>
          <InfoPiece label='Standard Deviation'>
            {formatNumber(deviceSummary.standardDeviation)}
          </InfoPiece>
        </Col>
        <Col>
          {lotGroup.testName == 'ESR' && (
            <>
              <InfoPiece label='Working Time'>
                {`${deviceSummary.workingTime} minutes` || '–'}
              </InfoPiece>
              <InfoPiece label='Temperature Correction'>
                {deviceSummary.temperatureCorrected ? 'Enabled' : 'Disabled'}
              </InfoPiece>
            </>
          )}
        </Col>
      </Row>
      <Button variant='danger' onClick={handleShowConfirmDelete}>
        Delete Summary Data
      </Button>

      <DeleteSummaryModal
        summary={deviceSummary}
        show={showConfirmDelete}
        handleClose={handleCloseConfirmDelete}
        remove={remove}
      />
    </div>
  )
}
