import React from 'react'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Row from 'react-bootstrap/Row'
import Scrollspy from 'react-scrollspy'

import PrintButton from 'js/components/PrintButton'

import { helpSectionList, HelpSection, HelpKeys } from './helpContent'

export default function Help(): React.ReactElement {
  const orderedSections: HelpSection[] = []

  Object.values(HelpKeys).forEach(key => {
    const section = helpSectionList.find(section => section.key === key)
    if (section) orderedSections.push(section)
    else console.error(`Help section not found with key: ${key}`)
  })

  const renderSection = (section: HelpSection): React.ReactElement => {
    return (
      <section className='section'>
        <a className='anchor' id={section.key}></a>
        <h2>{section.title}</h2>
        <p>{section.content}</p>
        <hr className='my-5' />
      </section>
    )
  }

  const renderTab = (section: HelpSection): React.ReactElement => {
    return (
      <ListGroup.Item action href={`#${section.key}`}>
        {section.title}
      </ListGroup.Item>
    )
  }

  return (
    <>
      <h1>User Manual</h1>
      <Row>
        <Col xs={4} className='d-print-none'>
          <div className='sticky-sidebar'>
            <h2>Contents</h2>
            <Scrollspy
              className='scrollspy'
              items={orderedSections.map(section => section.key)}
              currentClassName='isCurrent'
            >
              {orderedSections.map(section => renderTab(section))}
            </Scrollspy>
            <PrintButton />
          </div>
        </Col>
        <Col>
          {orderedSections.map(section => renderSection(section))}
          <div className='help-bottom-space' />
        </Col>
      </Row>
    </>
  )
}
