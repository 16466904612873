// prettier-ignore
import { DetailedDeviceData as DDD, DetailedGroupData as DGD, MappedReportData } from 'js/services/report-models'
import { getPeerIndex, getSDIndex, round } from 'js/utils/helpers'

export type Dataset = DDD | DGD

type CallbackFunc = (dataset: Dataset) => string | number
type PropsCallbackFunc = (
  props: MappedReportData,
  level: string
) => string | number

export interface TableRowConfig {
  label: string
  dataset: keyof MappedReportData
  attr?: string
  callback?: CallbackFunc
  propsCallback?: PropsCallbackFunc
}

export const TABLE_CONFIG: TableRowConfig[] = [
  { label: 'Your Datapoints', dataset: 'deviceData', attr: 'machineCount' },
  { label: 'Group Datapoints', dataset: 'groupData', attr: 'groupCount' },
  {
    label: 'Expected Ranges',
    dataset: 'groupData',
    propsCallback: ((props, level) =>
      `${props[level]['deviceData'].expectedLow} - ${props[level]['deviceData'].expectedHigh}`) as PropsCallbackFunc,
  },
  { label: 'Your Mean', dataset: 'deviceData', attr: 'machineAverage' },
  { label: 'Group Mean', dataset: 'groupData', attr: 'groupAverage' },
  {
    label: 'Mean Difference',
    dataset: 'groupData',
    propsCallback: ((props, level) =>
      props[level]['deviceData'].machineAverage -
      props[level]['groupData'].groupAverage) as PropsCallbackFunc,
  },
  {
    label: 'Your 1 SD (+/-)',
    dataset: 'deviceData',
    attr: 'machineStandardDeviation',
  },
  {
    label: 'Group 1 SD (+/-)',
    dataset: 'groupData',
    attr: 'groupStandardDeviation',
  },
  {
    label: 'SD Index',
    dataset: 'groupData',
    propsCallback: ((props, level) => {
      return getSDIndex(
        props[level]['deviceData'].machineAverage,
        props[level]['groupData'].groupAverage,
        props[level]['groupData'].groupStandardDeviation
      )
    }) as PropsCallbackFunc,
  },
  {
    label: 'Your 2 SD (+/-)',
    dataset: 'deviceData',
    callback: ((dataset: DDD) =>
      dataset.machineStandardDeviation * 2) as CallbackFunc,
  },
  {
    label: 'Group 2 SD (+/-)',
    dataset: 'groupData',
    callback: ((dataset: DGD) =>
      dataset.groupStandardDeviation * 2) as CallbackFunc,
  },
  {
    label: 'Your CV',
    dataset: 'deviceData',
    callback: ((dataset: DDD) =>
      round(dataset.machineCoefficientOfVariance, 2) + '%') as CallbackFunc,
  },
  {
    label: 'Group CV',
    dataset: 'groupData',
    callback: ((dataset: DGD) =>
      round(dataset.groupCoefficientOfVariance, 2) + '%') as CallbackFunc,
  },
  {
    label: 'Peer Index',
    dataset: 'groupData',
    propsCallback: ((props, level) =>
      getPeerIndex(
        props[level]['deviceData'].machineCoefficientOfVariance,
        props[level]['groupData'].groupCoefficientOfVariance
      )) as PropsCallbackFunc,
  },
]
