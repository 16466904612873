import React from 'react'

import Paths from 'js/utils/paths'

export default function Footer(): React.ReactElement {
  return (
    <div className='footer bg-primary text-light d-print-none'>
      <div className='container footer-content d-flex flex-column flex-sm-row flex-wrap justify-content-end'>
        <span className='flex-grow-1 p-2'>
          &copy; {new Date().getFullYear()} ELITechGroup
        </span>
        <span className='p-2'>
          <a className='text-white' href={Paths.help}>
            Help
          </a>
        </span>
        <span className='p-2'>
          <a className='text-white' href={Paths.correctiveActionForm}>
            Corrective Action Form
          </a>
        </span>
        <span className='p-2'>
          <a className='text-white' href={Paths.privacyPolicy}>
            Privacy Policy
          </a>
        </span>
      </div>
    </div>
  )
}
