import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'

import NewUserForm, { NewUserFormFields } from 'js/components/forms/NewUserForm'

import { CreateRegisterUserResponse } from 'js/models'
import { FormFeedback } from 'js/services/api'
import { createRegisterUser } from 'js/services/user'
import { getDataFromForm } from 'js/utils/helpers'

import { StepContentProps, Steps } from '.'

interface RSProps extends StepContentProps {
  storeToken: (token: string) => void
  previousStep: Steps
}

interface FormData {
  name: string
  email: string
  password1: string
  password2: string
}

export default function RegistrationStart(props: RSProps): React.ReactElement {
  const [errors, setErrors] = useState({} as FormFeedback<NewUserFormFields>)

  const resetFormFeedback = (): void =>
    setErrors({} as FormFeedback<NewUserFormFields>)

  const onSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    resetFormFeedback()

    const formData: FormData = getDataFromForm(
      e.target,
      ['name', 'email', 'password1', 'password2'],
      'newUserForm_'
    )
    createRegisterUser({
      ...formData,
    })
      .then(r => {
        props.storeToken((r as CreateRegisterUserResponse).accessToken)
        props.setStep(Steps.ShareJoinCode)
      })
      .catch(e => setErrors(e.formErrors))
  }
  return (
    <Card className='unauth-card'>
      <Card.Header>Personal Information</Card.Header>
      <Card.Body>
        <NewUserForm
          onSubmit={onSubmit}
          onBack={(): void => props.setStep(props.previousStep)}
          errors={errors}
        />
      </Card.Body>
    </Card>
  )
}
