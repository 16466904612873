import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { Organization } from 'js/models'
import { ApprovedStatus } from 'js/utils/constants'

export const denyAccessModal = (
  organization: Organization,
  show: boolean,
  handleClose: () => void,
  setOrgStatus: (status: ApprovedStatus) => void
): React.ReactElement => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className='bg-danger'>
        <Modal.Title className='text-white'>
          Deny Organization Access
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {organization.name} will no longer have access to ELITech VQC. Users
        belonging to this organization will not be able to log in to VQC or
        access their data. Existing data submitted by {organization.name} will
        still be included in reports.
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant='outline-danger'
          onClick={(): void => {
            setOrgStatus(ApprovedStatus.denied)
            handleClose()
          }}
        >
          Deny Organization Access
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const approveAccessModal = (
  organization: Organization,
  show: boolean,
  handleClose: () => void,
  setOrgStatus: (status: ApprovedStatus) => void
): React.ReactElement => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className='bg-warning'>
        <Modal.Title className='text-dark'>
          Approve Organization Access
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {organization.name} will be granted access to ELITech VQC. Users
        belonging to this organization will have full access to submit and view
        data.
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant='outline-warning'
          onClick={(): void => {
            setOrgStatus(ApprovedStatus.approved)
            handleClose()
          }}
        >
          Approve Organization Access
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
