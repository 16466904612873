import React from 'react'
import Col, { ColProps } from 'react-bootstrap/Col'

// prettier-ignore
declare type NumberAttr = number | '1'| '2'| '3'| '4'| '5'| '6'| '7'| '8'| '9'| '10'| '11'| '12'
declare type ColSize = boolean | 'auto' | NumberAttr

interface PCProps extends ColProps {
  print?: ColSize
}

export default function PrintableCol(props: PCProps): React.ReactElement {
  const { print, ...rest } = props
  if (print) rest.className = `col-print-${print} ${rest.className || ''}`
  return <Col {...rest}>{props.children}</Col>
}
