import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import { Link, useParams } from 'react-router-dom'

import { PasswordResetConfirm } from 'js/models'
import { FormFeedback } from 'js/services/api'
import { resetPasswordConfirm } from 'js/services/user'
import { getDataFromForm } from 'js/utils/helpers'
import Paths from 'js/utils/unauthorizedPaths'

enum FormState {
  unsubmitted = 0,
  submitted = 1,
  invalid = 2,
}

export default function PasswordResetConfirmForm(): React.ReactElement {
  const { token } = useParams<{ token: string }>()
  const { uid } = useParams<{ uid: string }>()
  const [errors, setErrors] = useState({} as FormFeedback<PasswordResetConfirm>)
  const [formState, setFormState] = useState<FormState>(FormState.unsubmitted)

  const resetFormFeedback = (): void =>
    setErrors({} as FormFeedback<PasswordResetConfirm>)

  const onSubmit = (e): void => {
    e.preventDefault()
    resetFormFeedback()

    const data: PasswordResetConfirm = getDataFromForm(
      e.target,
      ['newPassword1', 'newPassword2', 'token', 'uid'],
      'passwordReset_'
    )
    data.uid = uid
    data.token = token
    resetPasswordConfirm(data)
      .then(() => setFormState(FormState.submitted))
      .catch(e => {
        if (e.formErrors.token || e.formErrors.uid)
          setFormState(FormState.invalid)
        setErrors(e.formErrors)
      })
  }

  return (
    <Form onSubmit={onSubmit}>
      <Card className='unauth-card'>
        <Card.Header>Password Reset Confirmation</Card.Header>
        <Card.Body>
          {formState == FormState.submitted && (
            <>
              <p>Your password has been reset!</p>
              <Button href={Paths.signIn} className='w-100'>
                Sign In
              </Button>
            </>
          )}
          {formState == FormState.invalid && (
            <>
              <p className='text-danger'>
                <b>Invalid Request</b>
              </p>
              <p>
                This link to reset your password is invalid or expired. If you
                have sent multiple password reset requests, click the most
                recent link you received, or{' '}
                <Link to={Paths.passwordReset}>submit a new request</Link>.
              </p>
            </>
          )}
          {formState == FormState.unsubmitted && (
            <>
              <p>Create a new password for your account</p>
              <Form.Group controlId='passwordReset_newPassword1'>
                <Form.Label hidden>New Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='New Password'
                  isInvalid={!!errors.newPassword1}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.newPassword1}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='passwordReset_newPassword2'>
                <Form.Label hidden>Confirm New Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Confirm New Password'
                  isInvalid={!!errors.newPassword2}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.newPassword2}
                </Form.Control.Feedback>
              </Form.Group>
              <Button className='w-100' variant='primary' type='submit'>
                Change Password
              </Button>
            </>
          )}
        </Card.Body>
      </Card>
    </Form>
  )
}
