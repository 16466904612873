import React, { useEffect, useState } from 'react'
import _reduce from 'lodash/reduce'
import { LotReport } from 'js/services/report-models'

type MappedData = { [device: number]: { [lotNumber: string]: number } }
type LotSumsDict = { [lotNumber: string]: number }

export default function LotReportTable(props: {
  data: LotReport
  date: string
}): React.ReactElement {
  const [mappedData, setmappedData] = useState<MappedData>({})
  const [lotNumbers, setLotNumbers] = useState<string[]>([]) // Used to make sure lot number columns stay in order across rows
  const [lotSums, setLotSums] = useState<LotSumsDict>({})

  useEffect(() => {
    if (!props.data) return
    const dict = {} as MappedData
    const lotNumSet = new Set<string>()
    const lotSums = {} as LotSumsDict

    props.data.forEach(lrd => {
      const deviceId = lrd.deviceType
      const lotNum = lrd.lotNumber
      if (!dict[deviceId]) dict[deviceId] = {}
      if (!lotSums[lotNum]) lotSums[lotNum] = 0
      dict[deviceId][lotNum] = lrd.value
      lotSums[lotNum] += lrd.value
      lotNumSet.add(lotNum)
    })

    setmappedData(dict)
    setLotNumbers(Array.from(lotNumSet))
    setLotSums(lotSums)
  }, [props])

  return (
    <div className='lot-table-wrapper'>
      <h3>Lot Report</h3>
      <h5>{props.date}</h5>
      <p>The lot report shows the number of labs reporting a particular lot.</p>
      <table className='table table-striped table-sm small'>
        <colgroup></colgroup>
        <colgroup span={lotNumbers.length}></colgroup>
        <colgroup></colgroup>
        <thead>
          <tr className='bg-primary text-light'>
            <th>Analyzer</th>
            <th colSpan={lotNumbers.length}>Control Lot</th>
            <th>TOTAL</th>
          </tr>
          <tr>
            <th></th>
            {lotNumbers.map(x => (
              <th key={x + '-th'}>{x}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(mappedData).map(rowKey => {
            const rowData = mappedData[rowKey]
            const total = _reduce(rowData, (r, v) => (r += v), 0)
            const deviceName = props.data.find(
              x => x.deviceType === parseInt(rowKey)
            )?.deviceTypeName
            return (
              <tr key={`${rowKey}-tr`}>
                <td>{deviceName}</td>
                {lotNumbers.map(colKey => (
                  <td key={`${rowKey}-${colKey}-td`}>{rowData[colKey] || 0}</td>
                ))}
                <td className='fw-bold'>{total}</td>
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr className='fw-bold'>
            <th scope='row'>TOTAL</th>
            {lotNumbers.map(k => (
              <td key={`${k}-total`}>{lotSums[k]}</td>
            ))}
            <td>{_reduce(lotSums, (r, v) => (r += v), 0)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}
