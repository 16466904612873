import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { useHistory } from 'react-router-dom'

import { LotGroup } from 'js/models'
// prettier-ignore
import { createLotGroup, listLotGroups, ListLotGroupsParams } from 'js/services/lotGroup'
import { DateTimeStyle } from 'js/utils/constants'
import { formatDate } from 'js/utils/helpers'
import VQCPermissions from 'js/utils/permissions'

import Paths from 'js/utils/paths'
import Loader from '../Loader'
import PaginationBar from '../PaginationBar'
import SearchBar from '../SearchBar'

export default function AdminLotGroupTable(props: {
  listParams?: ListLotGroupsParams
  order?: string
  showCreateButton?: boolean
}): React.ReactElement {
  const { listParams, order, showCreateButton } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [lotGroups, setLotGroups] = useState<LotGroup[]>()
  const [searchString, setSearchString] = useState('')
  const history = useHistory()

  const canEdit = VQCPermissions.canEditElitechData()

  useEffect(() => {
    listLotGroups({
      ...listParams,
      page: currentPage,
      order: order || 'expiration_date',
      search: searchString,
    }).then(r => {
      setLotGroups(r.results)
      setTotalPages(r.pageTotal)
    })
  }, [currentPage, listParams, order, searchString])

  const search = (s: string): void => {
    setCurrentPage(1)
    setSearchString(s)
  }

  const create = (): void => {
    createLotGroup({ active: false, published: false }).then(r =>
      history.push(Paths.getAdminLotGroupDetailLink(r.id))
    )
  }

  const renderTableRow = (lotGroup: LotGroup): React.ReactElement => {
    return (
      <tr
        className='clickable'
        onClick={(): void => {
          history.push(Paths.getAdminLotGroupDetailLink(lotGroup.id))
        }}
        key={lotGroup.id}
      >
        <td>{lotGroup.testName || '–'}</td>
        <td>{lotGroup.lots.map(lot => lot.lotNumber).join(', ') || '–'}</td>
        <td className={lotGroup.expired ? 'text-danger' : ''}>
          {formatDate(lotGroup.expirationDate, DateTimeStyle.short) || '–'}
        </td>
        <td>{lotGroup.active ? 'Open' : 'Closed'}</td>
        <td>{lotGroup.published ? 'Published' : 'Unpublished'}</td>
      </tr>
    )
  }

  if (!lotGroups) return <Loader />

  return (
    <>
      <div className='table-top-bar'>
        <div className='table-top-section'>
          <SearchBar
            label={'Search'}
            placeholder={'Search by test name'}
            doSearch={search}
          />
        </div>
        {canEdit && showCreateButton && (
          <div className='table-top-section'>
            <Button variant='primary' onClick={create}>
              Create Lot Group
            </Button>
          </div>
        )}
      </div>
      {lotGroups.length == 0 ? (
        <p>No lot groups to display</p>
      ) : (
        <>
          <Table striped hover className='table-header-primary'>
            <thead>
              <tr>
                <th>Test</th>
                <th>Lot Numbers</th>
                <th>Expiration Date</th>
                <th>Open for Submissions</th>
                <th>Published</th>
              </tr>
            </thead>
            <tbody>{lotGroups.map(lotGroup => renderTableRow(lotGroup))}</tbody>
          </Table>
          <PaginationBar
            pageTotal={totalPages}
            currentPage={currentPage}
            clickPage={setCurrentPage}
          />
        </>
      )}
    </>
  )
}
