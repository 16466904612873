import React from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import AdminLotGroupTable from 'js/components/tables/AdminLotGroupTable'

export default function AdminLotGroups(): React.ReactElement {
  return (
    <div>
      <h1>Lot Management</h1>

      <Tabs defaultActiveKey='unpublished' id='lots-tabs'>
        <Tab eventKey='unpublished' title='Unpublished'>
          <AdminLotGroupTable
            listParams={{ published: false }}
            showCreateButton
          />
        </Tab>

        <Tab eventKey='open-published' title='Open Published'>
          <AdminLotGroupTable listParams={{ published: true, active: true }} />
        </Tab>

        <Tab eventKey='closed-published' title='Closed Published'>
          <AdminLotGroupTable listParams={{ published: true, active: false }} />
        </Tab>
      </Tabs>
    </div>
  )
}
