// This file is ignored by prettier
import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Footer from 'js/components/Footer'
import PasswordResetConfirmForm from 'js/components/forms/PasswordResetConfirmForm'
import PasswordResetForm from 'js/components/forms/PasswordResetForm'
import SignInForm from 'js/components/forms/SignInForm'
import Header from 'js/components/Headers/UnauthorizedHeader'
import CorrectiveActionForm from 'js/containers/CorrectiveActionForm'
import Help from 'js/containers/Help'
import PrivacyPolicy from 'js/containers/PrivacyPolicy'
import RegistrationFlowEntry from 'js/containers/RegistrationFlow'

import Paths from 'js/utils/unauthorizedPaths'

import 'scss/unauthorized-main.scss'

function UnauthorizedMain(): React.ReactElement {
  return (
    <Router>
      <div id='app'>
        <Header />
        <div id='maincontent' className='container'>
            <Switch>
              <Route exact path={Paths.signIn} component={SignInForm} />
              <Route exact path={Paths.passwordReset} component={PasswordResetForm} />
              <Route exact path={Paths.passwordResetConfirm} component={PasswordResetConfirmForm} />
              <Route path={Paths.register} component={RegistrationFlowEntry} />
              <Route exact path={Paths.privacyPolicy} component={PrivacyPolicy} />
              <Route exact path={Paths.help} component={Help} />
              <Route exact path={Paths.correctiveActionForm} component={CorrectiveActionForm} />
              {/* Default Route */}
              <Route path='/' component={SignInForm} />
            </Switch>
            <br /><p>VQC is an online, real time quality control evaluation and comparison program.  Through peer group analysis studies, any laboratory participating in VQC can compare its laboratory’s precision and accuracy to other participating laboratories throughout the world.  The use of interlaboratory peer group comparisons is good lab practice.</p>
 
<strong>Advantages of VQC</strong>
<ul><li>Provides confidence in your lab’s testing system.</li>
<li>Validates the quality control performance of the instrument.</li>
<li>Assists with troubleshooting control shifts and trends.</li>
<li>Although not required by CLIA, using peer comparison is considered “Good Lab Practice”.</li>
<li>Similar to proficiency testing, but statistics are generated monthly rather than quarterly.</li>
<li>Analysis reports comparing an individual lab to the group are available in real time.</li>
<li>VQC is provided to users of ELITechGroup products at no cost.</li></ul>
        </div>
        <Footer />
      </div>
    </Router>
  )
}

export default UnauthorizedMain
