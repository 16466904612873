import React from 'react'
import Badge from 'react-bootstrap/Badge'
import Table from 'react-bootstrap/Table'

import { DeviceEntry } from 'js/models'
import { deleteEntry } from 'js/services/deviceEntry'
import { formatDate, formatTime } from 'js/utils/helpers'

export default function DeviceEntryTable(props: {
  deviceEntries: DeviceEntry[]
  remove: (number) => void
}): React.ReactElement {
  const { deviceEntries, remove } = props

  const handleDelete = (id: number): void => {
    deleteEntry(id)
      .then((): void => remove(id))
      .catch(() => alert('An error occurred while deleting this data'))
  }

  const renderTableRow = (deviceEntry: DeviceEntry): React.ReactElement => {
    return (
      <tr key={deviceEntry.id}>
        <td>{formatDate(deviceEntry.date)}</td>
        <td>
          {deviceEntry.time
            ? formatTime(`${deviceEntry.date} ${deviceEntry.time}`)
            : '-'}
        </td>
        <td>{deviceEntry.value}</td>
        <td>
          {deviceEntry.outlier && (
            <Badge className='ms-2 bg-warning text-dark'>Outlier</Badge>
          )}
        </td>
        <td className='d-print-none'>
          <span
            className='clickable'
            onClick={(): void => handleDelete(deviceEntry.id)}
          >
            &times;
          </span>
        </td>
      </tr>
    )
  }

  if (deviceEntries.length == 0) return <p>No device entries to display</p>

  return (
    <Table striped className='table-header-primary w-auto print-small'>
      <thead>
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Value</th>
          <th>
            <span className='sr-only'>Outlier indicator</span>
          </th>
          <th className='d-print-none'>
            <span className='sr-only'>Remove entry</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {deviceEntries.map(deviceEntry => renderTableRow(deviceEntry))}
      </tbody>
    </Table>
  )
}
