import React, { useContext, useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link, useParams } from 'react-router-dom'

import DisableDeviceModal from 'js/components/forms/DisableDeviceModal'
import EditDeviceModal from 'js/components/forms/EditDeviceModal'
import InfoPiece from 'js/components/InfoPiece'
import Loader from 'js/components/Loader'

import { Device, DeviceType } from 'js/models'
import { getDeviceById } from 'js/services/device'
import { partialUpdateDevice } from 'js/services/device'
import { getDeviceType } from 'js/services/deviceType'
import { ELITechEmail } from 'js/utils/constants'
import { SelfUser } from 'js/utils/helpers'
import Paths from 'js/utils/paths'
import VQCPermissions from 'js/utils/permissions'

export default function AdminDeviceDetail(): React.ReactElement {
  const { pk } = useParams<{ pk?: string }>()
  const [device, setDevice] = useState<Device>()
  const [deviceType, setDeviceType] = useState<DeviceType>()
  const [showDeviceEdit, setShowDeviceEdit] = useState(false)
  const [showDeviceDisable, setShowDeviceDisable] = useState(false)

  const selfUser = useContext(SelfUser)
  const canReEnableDevices = VQCPermissions.canReenableDevices(selfUser)
  const canEdit = VQCPermissions.canEditCustomerData(selfUser)
  const isElitechAdmin = VQCPermissions.isElitechAdmin(selfUser)

  const handleShowDeviceEdit = (): void => setShowDeviceEdit(true)
  const handleCloseDeviceEdit = (): void => setShowDeviceEdit(false)

  const handleShowDeviceDisable = (): void => setShowDeviceDisable(true)
  const handleCloseDeviceDisable = (): void => setShowDeviceDisable(false)

  useEffect(() => refreshDevice(), [])

  useEffect(() => {
    if (device) getDeviceType(device.deviceType).then(r => setDeviceType(r))
  }, [device])

  const refreshDevice = (): void => {
    getDeviceById(parseInt(pk as string)).then(r => setDevice(r))
  }

  if (!device || !deviceType) return <Loader />

  const toggleDeviceStatus = (): void => {
    partialUpdateDevice(device.id, {
      active: !device.active,
    }).then(r => {
      setDevice(r as Device)
    })
  }

  return (
    <>
      <h1>
        {device.label}: {deviceType.name}
      </h1>

      {!canReEnableDevices && !device.active && (
        <Alert variant='info'>
          <Alert.Heading>Device Disabled</Alert.Heading>
          <p>
            This device has been disabled. If this was done by mistake, please
            contact ELITech support at{' '}
            <a href={`mailto:${ELITechEmail}`}>{ELITechEmail}</a>.
          </p>
          <p>
            Data for this device is still available in reports. If this device
            was moved to a different site, you can re-register it for the new
            site.
          </p>
        </Alert>
      )}

      <Row>
        <Col>
          <h2>Device Information</h2>
          <InfoPiece label='Model'>
            {isElitechAdmin ? (
              <Link to={Paths.adminDeviceTypes}>{deviceType.name}</Link>
            ) : (
              deviceType.name
            )}
          </InfoPiece>
          <InfoPiece label='Label'>{device.label || '–'}</InfoPiece>
          <InfoPiece label='Serial Number'>
            {device.serialNumber || '–'}
          </InfoPiece>
          <InfoPiece label='Organization'>
            <Link
              to={Paths.getAdminOrganizationDetailLink(device.organization)}
            >
              {device.organizationName}
            </Link>
          </InfoPiece>
          <InfoPiece label='Site'>
            <Link to={Paths.getAdminSiteDetailLink(device.site)}>
              {device.siteName}
            </Link>
          </InfoPiece>

          {/* Only people who can re-enable devices can edit a deactivated device */}
          {(canReEnableDevices || (canEdit && device.active)) && (
            <Button onClick={handleShowDeviceEdit}>Edit</Button>
          )}

          {/* People who can re-enable devices have a different set of controls for doing this */}
          {!canReEnableDevices && device.active && (
            <Button
              onClick={handleShowDeviceDisable}
              className='ms-2'
              variant='danger'
            >
              Disable
            </Button>
          )}
        </Col>
        {canReEnableDevices && (
          <Col>
            <h2>Device Status</h2>
            <InfoPiece label='Current Status'>
              <Button
                className='mb-4 w-25'
                size='sm'
                variant={device.active ? 'primary' : 'danger'}
                onClick={toggleDeviceStatus}
              >
                {device.active ? 'Active' : 'Disabled'}
              </Button>
            </InfoPiece>

            <Alert variant='info'>
              <Alert.Heading>About Device Activation</Alert.Heading>
              <p>
                If you need to move a physical device to another site or
                organization, first disable it, then create a new device
                registration for the new location.
              </p>
              <p>
                Organizations cannot submit measurements from deactivated
                devices. Disabled devices will only appear in device lists for
                organization admins.
              </p>
            </Alert>
          </Col>
        )}
      </Row>

      <EditDeviceModal
        show={showDeviceEdit}
        device={device}
        handleClose={handleCloseDeviceEdit}
        setDevice={setDevice}
      />

      <DisableDeviceModal
        device={device}
        show={showDeviceDisable}
        handleClose={handleCloseDeviceDisable}
        onDisable={refreshDevice}
      />
    </>
  )
}
