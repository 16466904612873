import React, { useRef, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useHistory } from 'react-router-dom'

import { buttonTextOptions } from '.'
import { Device, LotGroup } from 'js/models'
import { FormFeedback } from 'js/services/api'
import { FileEntryParams, uploadFileEntry } from 'js/services/fileEntry'
import { WorkingTimeOptions } from 'js/utils/constants'
import { downloadCsv } from 'js/utils/helpers'
import Paths from 'js/utils/paths'

const sampleCsvData = [
  ['Date', 'Time', 'Value', 'Level'],
  ['2021-12-20', '', '5', '1'],
  ['2021-12-21', '13:45', '2', '2'],
]

export default function UploadFile(props: {
  lotGroup: LotGroup
  selectedDevice: Device
  selectedWorkingTime?: WorkingTimeOptions
  selectedTempCorrection?: boolean | undefined
}): React.ReactElement {
  const {
    lotGroup,
    selectedDevice,
    selectedWorkingTime,
    selectedTempCorrection,
  } = props
  const [errors, setErrors] = useState({} as FormFeedback<FileEntryParams>)
  const fileInput = useRef<HTMLInputElement>(null)
  const [buttonText, setButtonText] = useState(buttonTextOptions.ready)
  const history = useHistory()

  const resetFormFeedback = (): void =>
    setErrors({} as FormFeedback<FileEntryParams>)

  const submit = (e): void => {
    e.preventDefault()
    resetFormFeedback()

    if (fileInput.current?.files?.length === 0) {
      setErrors({
        nonFieldErrors: 'No file selected',
      } as FormFeedback<FileEntryParams>)
      return
    }

    setButtonText(buttonTextOptions.uploading)

    const data: FileEntryParams = {
      lot_group: lotGroup.id,
      device: selectedDevice.id,
      workingTime: selectedWorkingTime || null,
      temperatureCorrected: selectedTempCorrection || null,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      file: (fileInput.current?.files as FileList)[0],
    }
    uploadFileEntry(data)
      .then(() => {
        setButtonText(buttonTextOptions.success)
        setTimeout(() => history.push(Paths.controlRuns), 1000)
      })
      .catch(e => {
        fileInput.current && (fileInput.current.value = '')
        if (Array.isArray(e.formErrors)) setErrors(e.formErrors[0])
        else setErrors(e.formErrors)
        setButtonText(buttonTextOptions.ready)
      })
  }

  return (
    <div>
      <h3>File Upload</h3>
      <Alert variant='info'>
        <p>
          You may upload data in either a CSV or Excel file format (.csv, .xls,
          .xlsx). The table must have the following columns: Date, Time, Value,
          Level. Any other columns may be included, but extra columns will be
          ignored when processing data.
        </p>
        <p>
          Dates must be in the format <b>YYYY-MM-DD</b>. Times must be in the
          format <b>HH:MM</b> (24 hr time).
        </p>
        <Button
          variant='link'
          className='px-0'
          onClick={(): void => downloadCsv('sample_csv', sampleCsvData)}
        >
          Download a template &nbsp;&rarr;
        </Button>
      </Alert>
      <Form onSubmit={submit}>
        <Form.Group>
          <Form.Label>Upload CSV or Excel</Form.Label>
          <Form.File id='file-input' accept='.csv,.xls,.xlsx' ref={fileInput} />
        </Form.Group>
        {Object.keys(errors).map(k => (
          <p key={k} className='text-danger'>
            {errors[k]}
          </p>
        ))}
        <Button
          onClick={submit}
          disabled={buttonText !== buttonTextOptions.ready}
        >
          {buttonText}
        </Button>
      </Form>
    </div>
  )
}
