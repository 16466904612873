import React from 'react'
import { Alert } from 'react-bootstrap'

export default function NoLotGroupsWarning(props: {
  hasDevices: boolean
}): React.ReactElement {
  return (
    <>
      {props.hasDevices ? (
        <Alert variant='info'>
          <Alert.Heading>No Available Lots</Alert.Heading>
          <p>
            There are currently no available lots compatible with any of your
            devices.
          </p>
        </Alert>
      ) : (
        <Alert variant='info'>
          <Alert.Heading>No Registered Devices</Alert.Heading>
          <p>You do not have access to any registered devices.</p>
          <p>
            Contact your organization admin to ensure you are assigned to a site
            with registered devices.
          </p>
        </Alert>
      )}
    </>
  )
}
