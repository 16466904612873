import { Device } from 'js/models'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'
import api, { BaseQueryParams, FormPromise, Paginated } from './api'

export interface listDevicesParams extends BaseQueryParams {
  site?: number
  organization?: number
}

/*=============================================
=                     GET                     =
=============================================*/
export const listDevices = (
  params?: listDevicesParams
): Promise<Paginated<Device>> => {
  return api
    .get('/devices/', { params: makeSnakeCase(params) })
    .then(r => makeCamelCase(r.data))
}

export const getDeviceById = (id: number): Promise<Device> => {
  const url = `/devices/${id}/`
  return api.get(url).then(r => makeCamelCase(r.data))
}

/*=============================================
=                    POST                     =
=============================================*/
export const createDevice = (data: Partial<Device>): FormPromise<Device> => {
  return api
    .post('/devices/', makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as Device)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                    PATCH                    =
=============================================*/

export const partialUpdateDevice = (
  id: number,
  data: Partial<Device>
): FormPromise<Device> => {
  return api
    .patch(`/devices/${id}/`, makeSnakeCase(data))
    .then(r => makeCamelCase(r.data) as Device)
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}
