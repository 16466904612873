import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from 'reportWebVitals'

import AuthorizedMain from 'AuthorizedMain'
import UnauthorizedMain from 'UnauthorizedMain'

import 'scss/global.scss'
import { getToken } from 'js/utils/helpers'

ReactDOM.render(
  <React.StrictMode>
    {getToken() !== null ? <AuthorizedMain /> : <UnauthorizedMain />}
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line @typescript-eslint/no-empty-function
reportWebVitals(process.env.DEBUG ? console.log : (): void => {})
