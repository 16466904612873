import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import { Device } from 'js/models'
import { FormFeedback } from 'js/services/api'
import { partialUpdateDevice } from 'js/services/device'
import { getDataFromForm } from 'js/utils/helpers'

export default function EditDeviceModal(props: {
  device: Device
  show: boolean
  handleClose: () => void
  setDevice: (device: Device) => void
}): React.ReactElement {
  const { device, show, handleClose, setDevice } = props
  const [errors, setErrors] = useState({} as FormFeedback<Device>)

  const resetFormFeedback = (): void => setErrors({} as FormFeedback<Device>)

  const onSave = (e: React.FormEvent): void => {
    e.preventDefault()
    resetFormFeedback()

    const data = getDataFromForm(
      e.target,
      ['serialNumber', 'label'],
      'deviceForm_'
    )

    partialUpdateDevice(device.id, data as Device)
      .then(r => {
        handleClose()
        setDevice(r as Device)
      })
      .catch(e => setErrors(e.formErrors))
    // Lesser TODO: Show saved successfull message
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop='static' scrollable>
      <Form onSubmit={onSave}>
        <Modal.Header>
          <Modal.Title>Edit Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId='deviceForm_serialNumber'>
            <Form.Label>Serial Number</Form.Label>
            <Form.Control
              type='text'
              placeholder='Serial Number'
              defaultValue={device.serialNumber}
              isInvalid={!!errors.serialNumber}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.serialNumber}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='deviceForm_label'>
            <Form.Label>Label</Form.Label>
            <Form.Control
              type='text'
              placeholder='Label'
              defaultValue={device.label}
              isInvalid={!!errors.label}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.label}
            </Form.Control.Feedback>
          </Form.Group>
          <p className='text-danger'>{errors.nonFieldErrors}</p>
          <p className='text-danger'>{errors.detail}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={(): void => {
              resetFormFeedback()
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button variant='primary' type='submit'>
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
