import React from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import { useHistory } from 'react-router-dom'

import Paths from 'js/utils/unauthorizedPaths'

import { StepContentProps, Steps } from '.'

export default function FindOrganization(
  props: StepContentProps
): React.ReactElement {
  const history = useHistory()

  const onSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    const code = e.target[
      'findOrganizationForm_organizationCode'
    ].value.replace(/[^0-9]/g, '')
    history.push(Paths.getRegistrationLinkWithOrganizationCode(code))
    props.setStep(Steps.ConfirmOrgainization)
  }

  return (
    <Card className='unauth-card'>
      <Card.Header>Find Organization</Card.Header>
      <Card.Body>
        <p>Enter your organization&apos;s private join code.</p>
        <Form onSubmit={onSubmit}>
          <Form.Group controlId='findOrganizationForm_organizationCode'>
            <Form.Label className='visually-hidden'>Company Code</Form.Label>
            <Form.Control required type='text' placeholder='Company Code' />
            <Form.Text className='muted'>
              Your organization&apos;s admins can access the code through their
              organization settings
            </Form.Text>
          </Form.Group>
          <div className='d-flex justify-content-around mt-4'>
            <Button
              variant='outline-primary'
              onClick={(): void => props.setStep(Steps.Start)}
            >
              &larr;&nbsp;&nbsp;Back
            </Button>
            <Button variant='primary' type='submit'>
              Next&nbsp;&nbsp;&rarr;
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  )
}
