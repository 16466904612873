import { HelpKeys } from 'js/containers/Help/helpContent'

const Paths = {
  home: '/',
  signIn: '/signin',
  register: '/register/:organizationCode?',
  passwordReset: '/password-reset',
  passwordResetConfirm: '/password-reset-confirm/:uid/:token',
  bareRegistrationLink: '/register',
  getRegistrationLinkWithOrganizationCode: (x): string => `/register/${x}`,
  privacyPolicy: '/privacy-policy',
  help: '/help',
  getHelpSectionLink: (section: HelpKeys): string => `/help#${section}`,
  correctiveActionForm: '/corrective-action-form',
}

export default Paths
