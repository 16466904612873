import { User } from 'js/models'
import { ELITechEmail, ELITechPhone, ELITechAddress } from 'js/utils/constants'
import React from 'react'
import FormattedAddress from '../FormattedAddress'
import ForamttedEmail from '../FormattedEmail'
import FormattedPhone from '../FormattedPhone'
import InfoPiece from '../InfoPiece'

export default function OrganizationDeniedWarn(props: {
  user: User
}): React.ReactElement {
  const orgName = props.user.organizationDetails?.name
  return (
    <div>
      <h1 className='pt-5'>Organization Access Denied</h1>
      <p>
        Your orgnization{orgName && `, ${orgName},`} has been denied access to
        the VQC application. For more information, please contact ELITech VQC
        support.
      </p>
      <p>
        <InfoPiece label='Email'>
          <ForamttedEmail email={ELITechEmail} />
        </InfoPiece>
        <InfoPiece label='Phone'>
          <FormattedPhone phone={ELITechPhone} clickable />
        </InfoPiece>
        <InfoPiece label='Mailing Address'>
          <FormattedAddress data={ELITechAddress} />
        </InfoPiece>
      </p>
    </div>
  )
}
