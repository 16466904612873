import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'

import InfoPiece from 'js/components/InfoPiece'
import { HelpKeys } from 'js/containers/Help/helpContent'

import { Organization, OrganizationCode } from 'js/models'
import { resetOrganizationCode } from 'js/services/organization'
import { formatOrganizationCode } from 'js/utils/helpers'
import Paths from 'js/utils/paths'

import Loader from './Loader'

export default function OrganizationCodeReset(props: {
  organization?: Organization
}): React.ReactElement {
  const organization = props.organization
  const [code, setCode] = useState<OrganizationCode>()

  useEffect(() => {
    if (organization) setCode(organization.organizationCode)
  }, [])

  const generateNewCode = (): void => {
    if (organization) {
      resetOrganizationCode(organization.id).then(r =>
        setCode(r.organizationCode)
      )
    }
  }

  if (!organization || !code) return <Loader />

  return (
    <>
      <h2 className='pt-4'>Invite New Users</h2>
      <InfoPiece
        label='Organization Join Code'
        helpLink={Paths.getHelpSectionLink(HelpKeys.joinCode)}
      >
        {formatOrganizationCode(code)}
        <br />
        <Button size='sm' className='mt-3 me-1' onClick={generateNewCode}>
          Reset Code
        </Button>
      </InfoPiece>
    </>
  )
}
