import React from 'react'

export default function Profile(): React.ReactElement {
  return (
    <div>
      <h1>Privacy Policy</h1>

      <h3>Privacy Statement</h3>
      <p>
        Your privacy is important to ELITechGroup. To better protect your
        privacy, we provide this privacy statement explaining our online
        information practices and the choices you can make about the way your
        information is collected and used. In order to make this statement easy
        to find, we make it available from our homepage and from every page
        where information that could be used to identify or contact you (your
        “Personally Identifiable Information”) may be requested. Your use of
        this website constitutes your acceptance of this privacy statement and
        your consent to the practices described below. This ELITechGroup privacy
        statement applies to data collected by ELITechGroup through its web
        sites as well as its offline product support services. It does not apply
        to those ELITechGroup sites, services and products that do not display
        or link to this statement or that have their own privacy statements.
      </p>

      <h3>Collection and Use of Your Personally Identifiable Information</h3>
      <p>
        ELITechGroup sites linking to this privacy statement do not collect or
        allow you to submit your Personally Identifiable Information, such as
        your e-mail address, name, company name, address, or telephone number.
        In order to access certain ELITechGroup sites or certain private areas
        within such sites, you will be asked to sign in with a username and
        password combination, which we refer to as your credentials, but your
        credentials will not constitute or be made up of any Personally
        Identifiable Information. You can choose not to provide your
        credentials, but then you might not be able to take advantage of many of
        our features.
      </p>
      <p>
        ELITechGroup may collect information about your interaction with
        ELITechGroup sites and services in order to improve the value of our
        site. We receive and store certain types of information whenever you
        interact with us. For example, like many websites, we use
        &quot;cookies&quot; and we obtain certain types of information when your
        Web browser accesses this website. Cookies are alphanumeric identifiers
        that we transfer to your computer&apos;s hard drive through your Web
        browser to enable our systems to recognize your browser and to
        facilitate the use of the site during a site visit, but we will not
        collect or retain any additional personal information via cookies and
        all session cookies will be destroyed after closing the browser window.
        The Help menu on the menu bar of most browsers will tell you how to
        prevent your browser from accepting new cookies, how to have the browser
        notify you when you receive a new cookie and how to disable cookies
        altogether. Additionally, you can disable or delete similar data used by
        browser add-ons, such as Flash cookies, by changing the add-on&apos;s
        settings or visiting the website of its manufacturer.
      </p>
      <p>
        We also use website analytics tools on our site to retrieve information
        from your browser, including the site you came from, the search
        engine(s) and the keywords you used to find our site, the pages you view
        within our site, your browser add-ons, and your browser&apos;s width and
        height. Additionally, we collect certain standard information that your
        browser sends to every website you visit, such as your IP address,
        browser type and language, access times and referring Web site
        addresses. Our logs and user statistics are not Personally Identifiable
        Information, however, and we make no attempt to link logs and user
        statistics with individuals that view our website. We use these logs for
        sales and marketing tracking as well as system performance monitoring.
        These logs are reviewed for the overall, aggregate trends they reveal
        about our customers, not for the behaviors of individual users. Only
        authorized staff have access to our website logs and user statistics.
      </p>

      <h3>Disclosures of and the International Transfer of Your Information</h3>
      <p>
        We may provide information about you that does not allow you to be
        identified, or contacted by, third parties (&ldquo;Aggregate
        Information&rdquo;). For example, we might inform third parties
        regarding the number of users of our site and the activities they
        conduct while on our site. We also might share information regarding the
        demographics of our members. Depending on the circumstances, we may or
        may not charge for this information. Since ELITechGroup operates
        globally, this may mean that we could transfer your Aggregate
        Information to other countries, including countries outside the European
        Economic Area (the &ldquo;EEA&rdquo;).
      </p>

      <h3>Communication Preferences</h3>
      <p>
        You can stop the delivery of future promotional e-mail from ELITechGroup
        sites and services by following the specific instructions in the e-mail
        you receive.
      </p>

      <h3>
        Collection and Use of Children&apos;s Personally Identifiable
        Information
      </h3>
      <p>
        ELITechGroup sites and services are intended for general audiences and
        do not collect any Personally Identifiable Information from children. We
        encourage you to talk with your children about communicating with
        strangers and disclosing personal information online.
      </p>

      <h3>Changes to This Privacy Statement</h3>
      <p>
        We will occasionally update this privacy statement to reflect changes in
        our services and customer feedback. When we post changes to this
        Statement, we will revise the &quot;last updated&quot; date at the top
        of this statement. If there are material changes to this statement or in
        how ELITechGroup will collect or use your Personally Identifiable
        Information, we will notify you either by prominently posting a notice
        of such changes prior to implementing the change or by directly sending
        you a notification. We may change this privacy statement at any time by
        posting revisions to our website. Your use of this website constitutes
        acceptance of the provisions of this privacy statement and your
        continued usage after such changes are posted constitutes acceptance of
        each revised privacy statement. If you do not agree to the terms of this
        privacy statement or any revised privacy statement, please exit the
        website immediately.
      </p>

      <h3>Contacting Us: Your right to object</h3>
      <p>
        If you have any questions relating to this privacy statement, please
        contact us in any of the following ways:
      </p>
      <p>
        <b>By Email:</b>
        <br />
        <a href='mailto:info@elitechgroup.com'>info@elitechgroup.com</a>
      </p>
      <p>
        <b>By Mail: </b>
        <br />
        Privacy Matters
        <br />
        ELITechGroup
        <br />
        370 West 1700 South
        <br />
        Logan, UT 84321
      </p>
    </div>
  )
}
