import { CartesianScaleOptions, FontSpec, ScriptableContext } from 'chart.js'
import { LineAnnotationOptions } from 'chartjs-plugin-annotation'
import { COLORS } from 'js/utils/constants'
import { XYCoordinate } from '../chart-utils'
import { CalculatedErrorBarChartData as EBCData } from './models'

/* ----- Constants ----- */
const X_AXIS_DEVICE = 1
const X_AXIS_GROUP = 2

/* ----- Local functions ----- */
const getCoordinates = (data: EBCData): XYCoordinate[] => {
  return [
    { x: X_AXIS_GROUP, y: data.groupData.sdP2 },
    { x: X_AXIS_GROUP, y: data.groupData.avg },
    { x: X_AXIS_GROUP, y: data.groupData.sdM2 },
    { x: X_AXIS_DEVICE, y: data.deviceData.sdP2 },
    { x: X_AXIS_DEVICE, y: data.deviceData.avg },
    { x: X_AXIS_DEVICE, y: data.deviceData.sdM2 },
  ]
}

const getPointColor = (
  ctx: ScriptableContext<'scatter'>,
  data: EBCData
): string => {
  const { x, y } = ctx.parsed
  if (!x || !y) return COLORS.dark
  const col = x == X_AXIS_DEVICE ? 'deviceData' : 'groupData'
  if (y > data[col].sdP1) return COLORS.danger
  if (y < data[col].sdM1) return COLORS.secondary
  return COLORS.primary
}

const getErrorBarLineAnnotation = (data, xVal): LineAnnotationOptions =>
  ({
    type: 'box',
    drawTime: 'beforeDatasetsDraw',
    backgroundColor: COLORS.dark,
    id: `yErrorLine_${xVal === X_AXIS_DEVICE ? 'device' : 'group'}`,
    xMax: xVal,
    xMin: xVal,
    yMax: data.sdP2,
    yMin: data.sdM2,
  } as LineAnnotationOptions)

const getDeviceLineAnnotation = (data: EBCData): LineAnnotationOptions =>
  getErrorBarLineAnnotation(data.deviceData, X_AXIS_DEVICE)

const getGroupLineAnnotation = (data: EBCData): LineAnnotationOptions =>
  getErrorBarLineAnnotation(data.groupData, X_AXIS_GROUP)

const getXScaleOptions = (): CartesianScaleOptions => {
  const xScale = { ticks: {} } as CartesianScaleOptions
  xScale.min = 0
  xScale.max = 3
  xScale.ticks.callback = function (tickValue): string {
    if (tickValue == X_AXIS_DEVICE) return 'Your Lab'
    if (tickValue == X_AXIS_GROUP) return 'Group'
    return ''
  }
  xScale.ticks.autoSkip = true
  xScale.ticks.autoSkipPadding = 15
  xScale.ticks.maxRotation = 0
  xScale.ticks.font = { size: 14 } as FontSpec

  return xScale
}

const Utils = {
  X_AXIS_DEVICE,
  X_AXIS_GROUP,
  getCoordinates,
  getPointColor,
  getDeviceLineAnnotation,
  getGroupLineAnnotation,
  getXScaleOptions,
}

export default Utils
