import { DeviceEntry } from 'js/models'
import { WorkingTimeOptions } from 'js/utils/constants'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'

import api, { BaseQueryParams, FormErrorArray, Paginated } from './api'

export interface DeviceEntryParams extends BaseQueryParams {
  lot: string | null
  lot__group: number | null
  lot__lot_number: string | null
  lot__lot_number__icontains: string | null
  device: number | null
  device__serial_number: string | null
  device__serial_number__icontains: string | null
  device__device_type: string | null
  date: string | null
  month: number | null
  year: number | null
  phone_number: string | null
  model: string | null
  address: string | null
  level_name: string | null
  organization: string | null
  organizations: string | null
  site: string | null
  sites: string | null
  date_after: string | null
  date_before: string | null
}

export interface UploadDeviceEntryParams {
  date: string
  time?: string | null
  value: number
  temperatureCorrected?: boolean | null
  workingTime?: WorkingTimeOptions | null
  lot: number
  device: number
}

/*=============================================
=                     GET                     =
=============================================*/
export const listDeviceEntries = (
  params?: Partial<DeviceEntryParams>
): Promise<Paginated<DeviceEntry>> => {
  return api
    .get('/entries/', { params: params })
    .then(r => makeCamelCase(r.data))
}

/*=============================================
=                    POST                     =
=============================================*/
export const uploadBulkEntry = (
  data: UploadDeviceEntryParams[]
): Promise<void | FormErrorArray<DeviceEntry>> => {
  return api
    .post('/entries/bulk/', makeSnakeCase(data))
    .then(() => Promise.resolve())
    .catch(e => {
      e.formErrors = makeCamelCase(e.response.data)
      return Promise.reject(e)
    })
}

/*=============================================
=                    PATCH                    =
=============================================*/

/*=============================================
=                    DELETE                   =
=============================================*/
export const deleteEntry = (id: number): Promise<Response | unknown> => {
  return api
    .delete(`/entries/${id}/`)
    .then(r => makeCamelCase(r.data))
    .catch(e => Promise.reject(e))
}
