import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { useLocation } from 'react-router-dom'

import Loader from 'js/components/Loader'
import { Control, Device, LotGroup, Organization } from 'js/models'
import { MAX_REQUEST_SIZE } from 'js/services/api'
import { getControlsByLots, listControls } from 'js/services/controls'
import { listDevices } from 'js/services/device'
import { listLotGroups } from 'js/services/lotGroup'
// prettier-ignore
import { getOrganization, getSelfOrganization, listOrganizations } from 'js/services/organization'
import { WorkingTimeOptions } from 'js/utils/constants'
import Paths from 'js/utils/paths'
import VQCPermissions from 'js/utils/permissions'

import NoLotGroupsWarning from './Alerts'
import { UploadOptions } from './SubmitData'

export default function SelectUploadOptions(props: {
  setUploadOptions: (options: UploadOptions | null) => void
}): React.ReactElement {
  const [organization, setOrganization] = useState<Organization | null>()
  const [lotGroup, setLotGroup] = useState<LotGroup | null>()
  const [device, setDevice] = useState<Device | null>()
  const [workingTime, setWorkingTime] = useState<WorkingTimeOptions>(
    WorkingTimeOptions.fifteen
  )
  const [tempCorrection, setTempCorrection] = useState(true)

  const [deviceList, setDeviceList] = useState<Device[]>()
  const [lotGroupList, setLotGroupList] = useState<LotGroup[]>()
  const [organizationList, setOrganizationList] = useState<Organization[]>()
  const [availableDevices, setAvailableDevices] = useState<Device[]>([])
  const [availableControls, setAvailableControls] = useState<Control[]>()

  const organizationInput = useRef(null)
  const lotGroupInput = useRef<HTMLSelectElement>(null)
  const deviceInput = useRef<HTMLSelectElement>(null)

  const canEditDeactivatedDevices = VQCPermissions.isSuperAdmin()

  // Optionally pass in organization through URL query params
  const urlQuery = new URLSearchParams(useLocation().search)

  // Initialize organization selection
  useEffect(() => {
    listOrganizations({ count: MAX_REQUEST_SIZE }).then(r =>
      setOrganizationList(r.results)
    )
    if (urlQuery.has('organization'))
      getOrganization(parseInt(urlQuery.get('organization') as string)).then(
        r => setOrganization(r)
      )
    else
      getSelfOrganization()
        .then(r => setOrganization(r))
        .catch(() => setOrganization(null))
  }, [])

  // On organization change
  useEffect(() => {
    setLotGroup(null)
    if (lotGroupInput.current) lotGroupInput.current.value = 'default'
    if (organization) {
      listDevices({
        organization: organization.id,
        count: MAX_REQUEST_SIZE,
      }).then(r => setDeviceList(r.results))
    }
  }, [organization])

  useEffect(() => {
    if (deviceList) {
      const testableControls = new Set()

      // List all controls that can be tested by the user's intruments
      listControls({ count: MAX_REQUEST_SIZE }).then(r => {
        r.results.forEach(control => {
          if (
            deviceList.some(device =>
              control.deviceTypes.includes(device.deviceType)
            )
          )
            testableControls.add(control.id)
        })

        // Find only lot groups that can be tested by user's available controls
        listLotGroups({ active: true }).then(r =>
          setLotGroupList(
            r.results.filter(lotGroup =>
              lotGroup.lots.some(lot => testableControls.has(lot.control))
            )
          )
        )
      })
    }
  }, [deviceList])

  // Available devices are ones that are compatible with the control for the selected lot group
  useEffect(() => {
    if (availableControls && deviceList && lotGroup) {
      let tempAvailDev = deviceList.filter(device =>
        availableControls
          .find(control => control.id == lotGroup.lots[0]?.control)
          ?.deviceTypes.includes(device.deviceType)
      )
      if (!canEditDeactivatedDevices) {
        tempAvailDev = tempAvailDev.filter(d => d.active)
      }
      setAvailableDevices(tempAvailDev)
    }
  }, [availableControls, deviceList])

  useEffect(() => {
    const lotGroupQuery = urlQuery.get('lot-group') ?? ''
    if (
      lotGroupQuery &&
      lotGroupList?.some(lg => lg.id === parseInt(lotGroupQuery))
    ) {
      lotGroupInput.current && (lotGroupInput.current.value = lotGroupQuery)
      setLotGroup(
        lotGroupList.find(lotGroup => lotGroup.id == parseInt(lotGroupQuery))
      )
    }
  }, [lotGroupList])

  useEffect(() => {
    const deviceQuery = urlQuery.get('device') ?? ''
    if (
      deviceQuery &&
      availableDevices.some(d => d.id === parseInt(deviceQuery))
    ) {
      deviceInput.current && (deviceInput.current.value = deviceQuery)
      setDevice(
        availableDevices.find(device => device.id === parseInt(deviceQuery))
      )
    }
  }, [availableDevices])

  useEffect(() => {
    setDevice(null)
    if (deviceInput.current) deviceInput.current.value = 'default'
    if (lotGroup)
      getControlsByLots(lotGroup.lots).then(r => setAvailableControls(r))
  }, [lotGroup])

  const showTimeTemperature = (): boolean => lotGroup?.testName == 'ESR'

  const lotGroupSelctor = (): React.ReactElement => {
    if (!lotGroupList) return <Loader />

    if (lotGroupList.length === 0)
      return <NoLotGroupsWarning hasDevices={deviceList?.length !== 0} />

    return (
      <Form.Group>
        <Form.Label>Lot Group</Form.Label>
        <Form.Control
          as='select'
          onChange={selectLotGroup}
          defaultValue='default'
          ref={lotGroupInput}
        >
          <option disabled value='default'>
            {' -- Select a Lot Group -- '}
          </option>
          {lotGroupList.map(lotGroup => (
            <option value={lotGroup.id} key={lotGroup.id}>
              {lotGroup.testName}&nbsp;–&nbsp;
              {lotGroup.lots.map(lot => lot.lotNumber).join(', ') ||
                'No lots available'}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    )
  }

  useEffect(() => {
    if (organization && lotGroup && device && availableControls)
      props.setUploadOptions({
        organization: organization,
        lotGroup: lotGroup,
        controls: availableControls,
        device: device,
        workingTime: workingTime,
        temperatureCorrection: tempCorrection,
      })
    else props.setUploadOptions(null)
  }, [
    organization,
    lotGroup,
    availableControls,
    device,
    workingTime,
    tempCorrection,
  ])

  if (
    !organizationList ||
    (organization && !deviceList) ||
    organization === undefined
  )
    return <Loader />

  const selectOrganization = (event): void => {
    setOrganization(
      organizationList.find(
        organization => organization.id == event.target.value
      )
    )
  }

  const selectLotGroup = (event): void => {
    setAvailableControls(undefined)
    lotGroupList &&
      setLotGroup(
        lotGroupList.find(lotGroup => lotGroup.id == event.target.value)
      )
  }

  const selectDevice = (event): void => {
    setDevice(availableDevices.find(device => device.id == event.target.value))
  }

  return (
    <>
      <Form.Group>
        <Form.Label>Organization</Form.Label>
        <Form.Control
          as='select'
          onChange={selectOrganization}
          defaultValue={organization?.id ?? 'default'}
          ref={organizationInput}
        >
          <option disabled value='default'>
            {' -- Select an Organization -- '}
          </option>
          {organizationList.map(organization => (
            <option value={organization.id} key={organization.id}>
              {organization.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {organization && lotGroupSelctor()}

      {lotGroup && (
        <>
          <Form.Group>
            <Form.Label>
              Device&nbsp;
              <span>
                <a
                  href={Paths.getAdminOrganizationDetailLink(organization?.id)}
                  target='_blank'
                  rel='noreferrer'
                >
                  (Manage Devices)
                </a>
              </span>
            </Form.Label>
            {availableDevices.length > 0 ? (
              <Form.Control
                as='select'
                disabled={!lotGroup}
                defaultValue='default'
                onChange={selectDevice}
                ref={deviceInput}
              >
                <option disabled value='default'>
                  {' -- Select a Device -- '}
                </option>
                {availableDevices.map(device => (
                  <option value={device.id} key={device.id}>
                    {device.label} ({device.model})
                  </option>
                ))}
              </Form.Control>
            ) : (
              <p>
                There are no devices available for data submission for this lot
                group.
              </p>
            )}
          </Form.Group>

          {device && showTimeTemperature() && (
            <>
              <Form.Group>
                <Form.Label>Working Time</Form.Label>
                <Form.Control
                  as='select'
                  onChange={(e): void =>
                    setWorkingTime(e.target.value as WorkingTimeOptions)
                  }
                  defaultValue={workingTime}
                >
                  {Object.values(WorkingTimeOptions).map(time => (
                    <option value={time} key={time}>
                      {time} min
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Label>Temperature Correction</Form.Label>
                <Form.Control
                  as='select'
                  onChange={(e): void =>
                    setTempCorrection(e.target.value === 'true')
                  }
                  defaultValue={tempCorrection ? 'true' : 'false'}
                >
                  <option value={'true'}>On</option>
                  <option value={'false'}>Off</option>
                </Form.Control>
              </Form.Group>
            </>
          )}
        </>
      )}
    </>
  )
}
