import React, { useContext, useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useParams } from 'react-router-dom'

import FormattedAddress from 'js/components/FormattedAddress'
import ForamttedEmail from 'js/components/FormattedEmail'
import FormattedPhone from 'js/components/FormattedPhone'
import EditOrganizationModal from 'js/components/forms/EditOrganizationModal'
import InfoPiece from 'js/components/InfoPiece'
import Loader from 'js/components/Loader'
import OrganizationCodeReset from 'js/components/OrganizationCodeReset'
import AdminDeviceTable from 'js/components/tables/AdminDeviceTable'
import AdminSiteTable from 'js/components/tables/AdminSiteTable'
import AdminUserTable from 'js/components/tables/AdminUserTable'
import ControlRunsTable from 'js/components/tables/ControlRunsTable'

import { Organization } from 'js/models'
import { listSites } from 'js/services/site'
// prettier-ignore
import { getOrganization, partialUpdateOrganization } from 'js/services/organization'
// prettier-ignore
import { ApprovedStatus, ELITechAddress, ELITechEmail, ELITechPhone } from 'js/utils/constants'
import { SelfUser } from 'js/utils/helpers'
import VQCPermissions from 'js/utils/permissions'

import * as Alerts from './Alerts'
import * as Modals from './Modals'

export default function AdminOrganizationDetail(): React.ReactElement {
  const [organization, setOrganization] = useState<Organization>()
  const { pk } = useParams<{ pk?: string }>()
  const [showOrgEdit, setShowOrgEdit] = useState(false)
  const [showDenyAccess, setShowDenyAccess] = useState(false)
  const [showApproveAccess, setShowApproveAccess] = useState(false)
  const [numSites, setNumSites] = useState<number>()

  const selfUser = useContext(SelfUser)
  const canEdit = VQCPermissions.canEditCustomerData(selfUser)
  const canApprove = VQCPermissions.canApproveOrganization(selfUser)
  const isElitechAdmin = VQCPermissions.isElitechAdmin(selfUser)

  const handleShowOrgEdit = (): void => setShowOrgEdit(true)
  const handleCloseOrgEdit = (): void => setShowOrgEdit(false)

  const handleShowDenyAccess = (): void => setShowDenyAccess(true)
  const handleCloseDenyAccess = (): void => setShowDenyAccess(false)

  const handleShowApproveAccess = (): void => setShowApproveAccess(true)
  const handleCloseApproveAccess = (): void => setShowApproveAccess(false)

  useEffect(() => {
    refreshOrganization()
  }, [])

  useEffect(() => {
    if (organization)
      listSites({ organization: organization.id }).then(r =>
        setNumSites(r.itemTotal)
      )
  }, [organization])

  const refreshOrganization = (): void => {
    getOrganization(parseInt(pk as string)).then(r => setOrganization(r))
  }

  if (isNaN(parseInt(pk as string))) {
    return (
      <>
        <h1>Organization Settings</h1>
        <p>
          Your user does not belong to an organization. Please contact ELITech
          VQC support if you need additional help.
        </p>
        <p>
          <InfoPiece label='Email'>
            <ForamttedEmail email={ELITechEmail} />
          </InfoPiece>
          <InfoPiece label='Phone'>
            <FormattedPhone phone={ELITechPhone} clickable />
          </InfoPiece>
          <InfoPiece label='Mailing Address'>
            <FormattedAddress data={ELITechAddress} />
          </InfoPiece>
        </p>
      </>
    )
  }

  if (!organization) return <Loader />

  const setOrgStatus = (status: ApprovedStatus): void => {
    partialUpdateOrganization(organization.id, {
      approvedStatus: status,
    }).then(r => {
      setOrganization(r as Organization)
    })
  }

  const renderDevicesTab = (): React.ReactElement => {
    if (numSites === undefined) return <Loader />
    if (numSites === 0)
      return (
        <Alert variant='info'>
          <p>
            You must create at least one site before registering devices. Click
            on the
            <i>
              <b> Sites </b>
            </i>
            tab above to get started.
          </p>
        </Alert>
      )
    return (
      <AdminDeviceTable
        listParams={{ organization: organization.id }}
        refreshParent={refreshOrganization}
        numSites={numSites}
      />
    )
  }

  return (
    <>
      <h1>{organization.name}</h1>
      {canApprove &&
        Alerts.approveDenyAlert(
          organization.approvedStatus == ApprovedStatus.pending,
          setOrgStatus
        )}

      <Tabs
        defaultActiveKey='contact'
        id='organization-settings-nav'
        className='mt-3'
      >
        <Tab eventKey='contact' title='Contact'>
          <InfoPiece label='Organization Name'>{organization.name}</InfoPiece>
          <InfoPiece label='Address'>
            <FormattedAddress data={organization} />
          </InfoPiece>
          <InfoPiece label='Phone'>
            <FormattedPhone phone={organization.phoneNumber} clickable />
          </InfoPiece>
          <InfoPiece label='Status'>{organization.approvedStatus}</InfoPiece>
          {canEdit && <Button onClick={handleShowOrgEdit}>Edit</Button>}
          {canApprove && (
            <>
              {organization.approvedStatus === ApprovedStatus.approved && (
                <Button
                  className='ms-2'
                  variant='danger'
                  onClick={handleShowDenyAccess}
                >
                  Deny Access
                </Button>
              )}
              {organization.approvedStatus === ApprovedStatus.denied && (
                <Button
                  className='ms-2'
                  variant='warning'
                  onClick={handleShowApproveAccess}
                >
                  Approve Access
                </Button>
              )}
            </>
          )}
        </Tab>

        <Tab eventKey='users' title='Users'>
          <AdminUserTable listParams={{ organization: organization.id }} />
          {canEdit && <OrganizationCodeReset organization={organization} />}
        </Tab>

        <Tab eventKey='sites' title='Sites'>
          <AdminSiteTable
            listParams={{ organization: organization.id }}
            refreshParent={refreshOrganization}
          />
        </Tab>

        <Tab eventKey='devices' title='Devices'>
          {renderDevicesTab()}
        </Tab>
        {isElitechAdmin && (
          <Tab eventKey='control-runs' title='Control Runs'>
            <ControlRunsTable listParams={{ organization: organization.id }} />
          </Tab>
        )}
      </Tabs>

      <EditOrganizationModal
        show={showOrgEdit}
        organization={organization}
        handleClose={handleCloseOrgEdit}
        setOrganization={setOrganization}
      />

      {Modals.denyAccessModal(
        organization,
        showDenyAccess,
        handleCloseDenyAccess,
        setOrgStatus
      )}
      {Modals.approveAccessModal(
        organization,
        showApproveAccess,
        handleCloseApproveAccess,
        setOrgStatus
      )}
    </>
  )
}
