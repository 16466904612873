import { LotGroup } from 'js/models'
import { makeCamelCase, makeSnakeCase } from 'js/utils/helpers'

import api, { BaseQueryParams, Paginated } from './api'

export interface ListLotGroupsParams extends BaseQueryParams {
  active?: boolean
  published?: boolean
  expired?: boolean
}

/*=============================================
=                     GET                     =
=============================================*/
export const listLotGroups = (
  params?: ListLotGroupsParams
): Promise<Paginated<LotGroup>> => {
  return api
    .get('/lot-groups/', { params: params })
    .then(r => makeCamelCase(r.data))
}

export const getLotGroup = (id: number): Promise<LotGroup> => {
  return api.get(`/lot-groups/${id}/`).then(r => makeCamelCase(r.data))
}

/*=============================================
=                    POST                     =
=============================================*/

export const createLotGroup = (data: Partial<LotGroup>): Promise<LotGroup> => {
  return api
    .post('/lot-groups/', makeSnakeCase(data))
    .then(r => makeCamelCase(r.data))
}

/*=============================================
=                    PATCH                    =
=============================================*/

export const partialUpdateLotGroup = (
  id: number,
  data: Partial<LotGroup>
): Promise<LotGroup> => {
  return api
    .patch(`/lot-groups/${id}/`, makeSnakeCase(data))
    .then(r => makeCamelCase(r.data))
}
