import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { DeviceSummary } from 'js/models'
import { deleteSummary } from 'js/services/deviceSummary'

export const DeleteSummaryModal = (props: {
  summary: DeviceSummary
  show: boolean
  handleClose: () => void
  remove: (number) => void
}): React.ReactElement => {
  const { summary, show, handleClose, remove } = props

  const handleDelete = (): void => {
    deleteSummary(summary.id)
      .then(() => {
        remove(summary.id)
        handleClose()
      })
      .catch(() => alert('An error occurred while deleting this data'))
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className='bg-danger'>
        <Modal.Title className='text-white'>Delete Control Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>This summary data will be immediately deleted.</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='outline-danger' onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
